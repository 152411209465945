<template>
  <div class="candidates-container">
    <vs-row
      vs-align="flex-end"
      vs-type="flex"
      vs-justify="space-between"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-w="12">
        <FilterSideBar> </FilterSideBar>
        <vs-row
          class="candidates-content"
          vs-align="flex-start"
          vs-type="flex"
          vs-w="12"
        >
          <vs-col
            class="split-container"
            vs-type="flex"
            vs-justify="center"
            vs-w="12"
          >
            <Split direction="horizontal" style="height: 500px;">
              <SplitArea :size="75">
                <CandidatesList />
                <vs-pagination
                  v-model="currentPage"
                  class="pagination-container mt-3"
                  :total="totalCandidatesPages"
                />
              </SplitArea>
              <SplitArea :size="25">
                <CandidateSideBarInfo v-if="selectedCandidate.id !== null" />
              </SplitArea>
            </Split>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vue-fab
      size="big"
      :scroll-auto-hide="false"
      @clickMainBtn="goToNewCandidate"
    />
  </div>
</template>

<script>
import FilterSideBar from '@/modules/candidates/components/list/FilterSideBar'
import CandidatesList from '@/modules/candidates/components/list/CandidatesList'
import CandidateSideBarInfo from '@/modules/candidates/components/list/CandidateSideBarInfo'

import utils from '@/utils/utils'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CandidatesView',
  components: { CandidatesList, FilterSideBar, CandidateSideBarInfo },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    ...mapState('candidates', {
      selectedCandidate: 'selectedCandidate',
      totalCandidatesPages: 'totalCandidatesPages',
      searchParams: 'searchParams',
    }),
  },
  watch: {
    currentPage: function (newPage) {
      let query = `${this.searchParams}&page=${newPage}`
      this.setSkeletonLoading(true)

      this.getCandidatesList(query).then((res) =>
        this.formatCandidatesList(res)
      )
    },
  },
  created() {
    // Default filter, only in the process of contracting
    let query = `candidatefollowup__status__in=${'IP'}`
    this.getCandidatesList(query).then((res) => this.formatCandidatesList(res))
  },
  methods: {
    ...mapActions('candidates', {
      getCandidatesList: 'getCandidatesList',
    }),
    ...mapMutations('candidates', {
      setTotalCandidatesPages: 'setTotalCandidatesPages',
      setCandidatesList: 'setCandidatesList',
      setSkeletonLoading: 'setSkeletonLoading',
    }),
    goToNewCandidate() {
      this.$router
        .push({
          name: 'newCandidate',
        })
        .catch(() => {})
    },
    formatCandidatesList(candidatesList) {
      const pages = Math.ceil(candidatesList.count / candidatesList.pageSize)
      this.setTotalCandidatesPages(pages)
      let candidatesListFormated = candidatesList.results.map((item) => {
        item['age'] = utils.calculateAge(item.dateOfBirth)
        item['id'] = item['id'].toString()
        return item
      })
      this.setCandidatesList(candidatesListFormated)
      this.setSkeletonLoading(false)
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
.split-container .split {
  height: calc(100vh - #{$navbar-height}) !important;

  .split.split-horizontal .list-candidates-container {
    height: calc(100vh - #{$navbar-height} - 85px);
    overflow: auto;
  }
}
.pagination-container .vs-col.vs-pagination--mb {
  justify-content: center !important;
}
</style>
