<template>
  <div class="container mt-3">
    <vs-row>
      <vs-col vs-justify="center" class="px-3" vs-lg="4" vs-xs="12">
        <vs-row>
          <vs-card>
            <div slot="header">
              <h3>{{ user.name }} {{ user.lastName }}</h3>
            </div>
            <div slot="media">
              <template>
                <vs-col
                  v-if="
                    user.userprofile.picture !== undefined &&
                    user.userprofile.picture !== '#' &&
                    user.userprofile.picture !== null
                  "
                  class="mt-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <small class="mx-auto"> Imagen Actual </small>
                  <vs-icon
                    v-if="user.userprofile.role !== 'employee'"
                    icon="edit"
                    color="blue"
                    class="cursor-pointer"
                    @click="
                      () => {
                        showImage = !showImage
                      }
                    "
                  ></vs-icon
                ></vs-col>
                <vs-col vs-type="flex" class="preview-images mb-2">
                  <template
                    v-if="
                      user.userprofile.picture !== undefined &&
                      user.userprofile.picture !== '#' &&
                      user.userprofile.picture !== null
                    "
                  >
                    <img
                      :src="user.userprofile.picture"
                      class="grid-view-img mx-auto"
                    />
                  </template>
                </vs-col>
                <template
                  v-if="
                    user.userprofile.role !== 'employee' &&
                    (showImage ||
                      user.userprofile.picture === undefined ||
                      user.userprofile.picture === '#' ||
                      user.userprofile.picture === null)
                  "
                >
                  <picture-input
                    ref="uploadMainPhoto"
                    class="mt-3"
                    style="width: 50%;"
                    size="7"
                    :crop="false"
                    :min-width="300"
                    :min-height="300"
                    @change="onChangeAvataPicture()"
                  >
                  </picture-input>
                </template>
              </template>
            </div>
            <div class="user-profile-container">
              <vs-input
                v-model="user.userprofile.company.name"
                class="mt-2"
                type="text"
                label="Empresa"
                disabled
              />
              <vs-input
                class="mt-2"
                type="text"
                label="Rol"
                :value="obtenerRol()"
                disabled
              />
              <vs-input
                class="mt-2"
                type="text"
                label="Email"
                :value="user.email"
                disabled
              />
            </div>
          </vs-card>
        </vs-row>
      </vs-col>
      <vs-col vs-justify="left" class="px-3" vs-lg="8" vs-xs="12">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="left" vs-w="12">
            <div class="px-0 full-width">
              <vs-card class="pt-3 mb-5">
                <div slot="header">
                  <h3>Datos personales</h3>
                </div>
                <ValidationObserver
                  ref="observerDatosUser"
                  v-slot="{ invalid }"
                >
                  <div>
                    <vs-row class="mb-1">
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Nombre"
                          rules="required"
                        >
                          <vs-input
                            v-model="userPersonalData.name"
                            label-placeholder="Nombre(s)"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="user.userprofile.role === 'employee'"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Apellido Paterno"
                          rules="required"
                        >
                          <vs-input
                            v-model="userPersonalData.lastName"
                            label-placeholder="Apellido Paterno"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="user.userprofile.role === 'employee'"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <vs-input
                          v-model="userPersonalData.secondLastName"
                          label-placeholder="Apellido Materno"
                          :disabled="user.userprofile.role === 'employee'"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Número celular"
                          rules="required|numeric|min:10|max:10"
                        >
                          <vs-input
                            v-model="userPersonalData.phoneNumber"
                            label-placeholder="Número celular"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div slot="footer">
                    <vs-row class="footer-card" vs-justify="flex-end">
                      <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="center"
                        vs-md="12"
                        vs-lg="6"
                      >
                        <vs-button
                          color="primary"
                          type="gradient"
                          :disabled="invalid"
                          @click="updatePersonalData"
                        >
                          Actualizar datos
                        </vs-button>
                      </vs-col>
                    </vs-row>
                  </div>
                </ValidationObserver>
              </vs-card>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-w="12">
            <div class="px-0 full-width">
              <vs-card class="pt-3 mb-5">
                <div slot="header">
                  <h3>Actualizar contraseña</h3>
                </div>
                <ValidationObserver
                  ref="observerDatosUser"
                  v-slot="{ invalid }"
                >
                  <div>
                    <vs-row class="mb-1">
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Contraseña actual"
                          rules="required"
                        >
                          <vs-input
                            v-model="userPassword.oldPassword"
                            label-placeholder="Contraseña actual"
                            type="password"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Nueva contraseña"
                          rules="required|min:8|max:32|newPassword:@Contraseña actual"
                        >
                          <vs-input
                            v-model="userPassword.newPassword"
                            label-placeholder="Nueva contraseña"
                            type="password"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Confirmación de contraseña"
                          rules="required|confirmed:Nueva contraseña"
                        >
                          <vs-input
                            v-model="userPassword.passwordConfirmation"
                            label-placeholder="Confirme nueva contraseña"
                            type="password"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div slot="footer">
                    <vs-row class="footer-card" vs-justify="flex-end">
                      <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="center"
                        vs-md="12"
                        vs-lg="6"
                      >
                        <vs-button
                          color="primary"
                          type="gradient"
                          :disabled="invalid"
                          @click="_updatePassword"
                        >
                          Actualizar contraseña
                        </vs-button>
                      </vs-col>
                    </vs-row>
                  </div>
                </ValidationObserver>
              </vs-card>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import { extend } from 'vee-validate'
import { roles } from '@/utils/utils'
import PictureInput from '@/components/PictureInput'
import utils from '@/utils/utils'

extend('newPassword', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target
  },
  message: 'La nueva contraseña no puede ser igual a la anterior',
})

export default {
  name: 'MyProfileView',
  components: {
    'picture-input': PictureInput,
  },
  data: function () {
    return {
      showImage: false,
      urlAction: '',
      text: 'Cargar imagen',
      filename: 'picture',
      userPersonalData: {
        name: '',
        lastName: '',
        secondLastName: '',
        phoneNumber: '',
      },
      userPassword: {
        oldpassword: '',
        newPassword: '',
        passwordConfirmation: '',
      },
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
      tokenAccess: 'tokenAccess',
    }),
    headers: function () {
      let headers = {
        Authorization: 'Bearer ' + this.tokenAccess,
      }
      return headers
    },
  },
  watch: {},
  created: function () {
    this.me().then((response) => {
      this.setUser(response)
      this.setCompany(response.userprofile.company)
      this.$acl.change(response.userprofile.role)
      const username = this.user.username
      this.urlAction = `${axios.defaults.baseURL}users/${username}/profile/`
      this.userPersonalData.name = this.user.name
      this.userPersonalData.lastName = this.user.lastName
      this.userPersonalData.secondLastName = this.user.secondLastName
      this.userPersonalData.phoneNumber = this.user.phoneNumber
    })
  },
  methods: {
    ...mapMutations('company', {
      setCompany: 'setCompany',
    }),
    ...mapMutations('auth', {
      setUser: 'setUser',
    }),
    ...mapActions('auth', {
      updatePassword: 'updatePassword',
      logout: 'logout',
      me: 'me',
    }),
    ...mapActions('users', {
      updateUserProfile: 'updateUserProfile',
      updateUser: 'updateUser',
      updateProfilePicture: 'updateProfilePicture',
    }),
    onChangeAvataPicture() {
      if (this.$refs.uploadMainPhoto.image) {
        this.$vs.loading({
          text: 'Actualizando Imagen',
        })
        const file = this.$refs.uploadMainPhoto.file
        let formData = new FormData()
        formData.append('picture', file)
        this.updateProfilePicture({
          formData: formData,
          username: this.user.username,
        })
          .then((res) => {
            this.$vs.loading.close()
            this.setUser(res)
            this.showImage = false
          })
          .catch((error) => {
            this.$vs.loading.close()
            let res = ''
            if (error.response) {
              res = utils.getErrorDetails(error.response.data.errors)
            } else {
              res = error.message
            }
            this.$vs.notify({
              color: 'danger',
              title: 'Ocurrió un problema al crear tu cuenta',
              text: `${res}`,
              position: 'top-right',
            })
          })
      }
    },
    successUpload(event) {
      let responseTarget = event.currentTarget.response
      let response = JSON.parse(responseTarget)
      this.setUser(response)
      this.$vs.notify({
        title: 'Éxito',
        text: 'Avatar actualizado',
        color: 'success',
        position: 'bottom-center',
      })
    },
    updatePersonalData() {
      this.updateUser({
        data: this.userPersonalData,
        username: this.user.username,
      })
        .then((response) => {
          this.$vs.notify({
            title: 'Éxito',
            text: 'Información actualizada',
            color: 'success',
            position: 'bottom-center',
          })
          this.setUser(response)
        })
        .catch(() => {})
    },
    _updatePassword() {
      this.updatePassword(this.userPassword)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito, credenciales actualizadas',
            text: 'Por favor, inicie sesión nuevamente.',
            color: 'success',
            position: 'bottom-center',
          })
          this.logout()
          this.$router
            .push({
              name: 'authLogin',
            })
            .then(() => {
              this.active = false
            })
            .catch(() => {})
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Ops...',
            text: `${err.message}: ${err.response.data.oldPassword[0]}`,
            color: 'danger',
            position: 'bottom-center',
          })
        })
    },
    obtenerRol() {
      var rolName
      if (this.user.userprofile.role === roles.ADMIN) {
        rolName = 'Administrador de la empresa'
      } else if (this.user.userprofile.role === roles.RECRUITER) {
        rolName = 'Reclutador'
      } else {
        rolName = 'Empleado'
      }
      return rolName
    },
  },
}
</script>

<style lang="scss">
.avatar {
  width: 40%;
  border-radius: 50%;
}
.user-profile-container {
  .vs-input--label {
    font-weight: 600 !important;
  }
  .con-img-upload {
    display: flex;
    justify-content: center;
  }
}
</style>
