<template>
  <vs-card class="px-2 pt-3">
    <div slot="header" class="mb-1">
      <h2 class="mb-2">
        Recuperar contraseña
      </h2>
      <p class="small-font">
        Coloca tu nueva contraseña
      </p>
    </div>
    <div class="pt-3">
      <ValidationObserver ref="observernewUser" v-slot="{ invalid }">
        <form ref="form">
          <vs-row vs-w="12">
            <vs-col class="mt-2 px-1 col-inputs-full" vs-type="flex" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nueva contraseña"
                rules="required|min:8|max:32"
              >
                <vs-input
                  v-model="userPassword.password"
                  label-placeholder="Nueva contraseña"
                  type="password"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Confirmación de contraseña"
                rules="required|confirmed:Nueva contraseña"
              >
                <vs-input
                  v-model="userPassword.passwordConfirmation"
                  label-placeholder="Confirme nueva contraseña"
                  type="password"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <vs-button
                class="btn btn-block mt-4"
                color="primary"
                size="large"
                type="filled"
                :disabled="invalid"
                @click="submit"
              >
                Cambiar contraseña
              </vs-button>
            </vs-col>
          </vs-row>
        </form>
      </ValidationObserver>
    </div>
  </vs-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getErrorDetails } from '@/utils/utils'
import { getUrlVars } from '@/plugins/utils'
import jwtDecode from 'jwt-decode'

export default {
  name: 'RegisterForm',
  data: function () {
    return {
      userPassword: {
        password: '',
        passwordConfirmation: '',
        token: '',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
  },
  created() {
    const token = getUrlVars('token')['token']
    const decoded = jwtDecode(token)
    const date = Math.floor(Date.now() / 1000)
    if (date >= decoded.exp) {
      this.$vs.notify({
        color: 'danger',
        title: 'Url caducada',
        text: `Favor de solicitar otro correo`,
        position: 'top-right',
      })
    }
    this.userPassword.token = token
  },
  methods: {
    ...mapActions('auth', {
      recoveryPassword: 'recoveryPassword',
    }),
    async submit() {
      this.recoveryPassword(this.userPassword)
        .then(() => {
          this.$vs.notify({
            title: 'Contraseña actualizada.',
            text: 'Ahora puedes iniciar sesión.',
            color: 'primary',
            position: 'bottom-center',
            icon: 'mail',
          })
          this.$router
            .push({
              name: 'authLogin',
            })
            .then(() => {
              this.active = false
            })
            .catch(() => {})
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            color: 'danger',
            title: 'Ocurrió un problema al recuperar tu cuenta',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
