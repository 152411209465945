<template>
  <vs-navbar class="app-nav" color="dark">
    <div slot="title">
      <vs-navbar-title>
        <vs-row vs-justify="center">
          <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button
              color="dark"
              type="filled"
              icon="menu"
              @click="showSideBar"
            >
              <span class="page-title" v-text="pageTitle" />
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-navbar-title>
    </div>
    <vs-spacer></vs-spacer>
  </vs-navbar>
</template>

<script>
import { eventBus } from '@/plugins/event-bus'

export default {
  name: 'NavBar',
  data: function () {
    return {
      activeItem: 0,
    }
  },
  computed: {
    pageTitle: function () {
      return this.$route.meta.title
    },
  },
  beforeDestroy() {
    eventBus.$off('showSideBar', this.listener)
  },
  methods: {
    showSideBar: () => {
      eventBus.$emit('showSideBar')
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/scss/_variables.scss';
.app-nav {
  color: rgb(255, 255, 255);
  height: $navbar-height;
  position: fixed;
  top: 0;
  left: 0;
  .page-title {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
}
</style>
