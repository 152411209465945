<template>
  <div class="container">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-w="6">
        <CatalogsList />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-w="6">
        <vs-row vs-justify="center">
          <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="10">
            <CatalogsDetail />
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import CatalogsList from '@/modules/catalogs/components/CatalogsList'
import CatalogsDetail from '@/modules/catalogs/components/CatalogsDetail'

export default {
  name: 'CatalogsView',
  components: {
    CatalogsList,
    CatalogsDetail,
  },
}
</script>
