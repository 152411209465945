<template>
  <div class="employee-item mt-1" @click="onSelectCandidate(source.id)">
    <div
      class="item-content"
      :class="{
        'candidate-selected': selectedEmployee.id === source.id,
      }"
    >
      <template v-if="source.photoInfo.photo !== null">
        <img
          class="employeeImg"
          :src="source.photoInfo.photo"
          :alt="source.rfc"
        />
      </template>
      <template v-else
        ><img
          class="employeeImg"
          src="@/assets/images/user-avatar.png"
          :alt="source.rfc"
      /></template>
      <p class="candidate-item-title">
        <strong>
          {{ source.candidateInfo.name }} {{ source.candidateInfo.lastName }}
          {{ source.candidateInfo.secondLastName }}
        </strong>
      </p>
      <p>correo: {{ source.internalEmail }}</p>
      <p>RFC: {{ source.rfc }}</p>
    </div>

    <!-- Registrar Asistencia -->
    <div class="avatar-container">
      <vs-tooltip v-if="source.isActive === true" text="Registrar Asistencia">
        <vs-avatar
          color="dark"
          icon="watch_later"
          @click="openModalRegisterAssistance(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>
    <!-- Vincular Usuario -->
    <div class="avatar-container">
      <vs-tooltip v-if="source.isActive === true" text="Vincular Usuario">
        <vs-avatar color="dark" icon="link" @click="openLinkUser()" />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>
    <!-- Modificar empleado -->
    <div class="avatar-container">
      <vs-tooltip text="Modificar empleado">
        <vs-avatar
          color="dark"
          icon="edit"
          @click="updateEmployee(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>
    <!-- Enviar invitación -->
    <div class="avatar-container">
      <vs-tooltip
        v-if="
          source.isActive === true &&
          (source.userInfo === null || !source.userInfo.isActive)
        "
        text="Enviar invitación"
      >
        <vs-avatar
          color="dark"
          icon="email"
          @click="openModalSendEmailConfirmation(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>
    <!-- Agregar vacaciones -->
    <div class="avatar-container mr-5">
      <vs-tooltip v-if="source.isActive === true" text="Agregar vacaciones">
        <vs-avatar
          color="dark"
          icon="beach_access"
          @click="openModalCreateVacation(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>
    <!-- Baja de empleado -->
    <div class="avatar-container">
      <vs-tooltip v-if="source.isActive === true" text="Baja de empleado">
        <vs-avatar
          color="danger"
          icon="delete"
          @click="dismissalEmployee(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>

    <!-- Prompt vinculacion de usuario -->
    <vs-prompt
      :active.sync="linkUserModal"
      accept-text="Aceptar"
      cancel-text="Cancelar"
      :is-valid="userId !== null"
      title="Vincular Usuario"
      @accept="linkUser"
    >
      <span
        v-if="source.userInfo !== null && source.userInfo !== undefined"
        class="pb-5"
        style="color: red;"
      >
        Ya se ha vinculado el usuario {{ source.userInfo.email }} a este
        empleado
      </span>
      <div>
        <vs-select
          v-model="userId"
          autocomplete
          class="block col-inputs-full mb-3"
          label="Usuario"
          placeholder="Elija el usuario"
          val-icon-danger="error"
        >
          <vs-select-item
            v-for="(item, index) in listUsers"
            :key="index"
            :value="item.id"
            :text="
              item.name +
              ' ' +
              item.lastName +
              ' ' +
              item.secondLastName +
              ' - ' +
              item.email
            "
          />
        </vs-select>
      </div>
    </vs-prompt>

    <!-- Promp para registrar asistencia -->
    <ValidationObserver ref="observerRegistrarAsistencia" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="registerAssistanceModal"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        title="Registrar Asistencia"
        @accept="registerAssistance"
      >
        <vs-row>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Fecha baja"
              rules="required"
            >
              <div
                class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
              >
                <v-date-picker
                  v-model="assistanceData.workingDate"
                  :max-date="new Date()"
                  :attributes="employeeAssistanceAttributes"
                  :masks="masks"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <vs-row vs-align="center">
                      <vs-col vs-w="50%">
                        <vs-input
                          :value="inputValue.start"
                          readonly="true"
                          v-on="inputEvents.start"
                        />
                      </vs-col>
                      <vs-col vs-w="auto" vs-align="center">
                        <svg
                          style="
                            width: 12px;
                            height: 12px;
                            margin-right: 8px;
                            padding-left: 5px;
                            stroke: #6b7280;
                          "
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                          />
                        </svg>
                      </vs-col>
                      <vs-col vs-w="50%">
                        <vs-input
                          :value="inputValue.end"
                          readonly="true"
                          v-on="inputEvents.end"
                        />
                      </vs-col>
                    </vs-row>
                  </template>
                </v-date-picker>
                <span
                  class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                  style="margin-top: -28px;"
                >
                  Fecha
                </span>
              </div>
            </ValidationProvider>
          </vs-col>
          <vs-col
            class="mt-0 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
          >
            <div
              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
            >
              <div
                class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
              >
                <datetime
                  v-model="assistanceData.entryTime"
                  type="time"
                  use12-hour
                  :phrases="{
                    ok: 'Confirmar',
                    cancel: 'Cancelar',
                  }"
                  input-class="vs-inputx vs-input--input normal"
                  auto
                ></datetime>
                <span
                  class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                  style="margin-top: -28px;"
                >
                  Hora Entrada
                </span>
              </div>
            </div>
          </vs-col>
          <vs-col
            class="mt-0 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
          >
            <div
              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
            >
              <div
                class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
              >
                <datetime
                  v-model="assistanceData.exitTime"
                  type="time"
                  use12-hour
                  :phrases="{
                    ok: 'Confirmar',
                    cancel: 'Cancelar',
                  }"
                  input-class="vs-inputx vs-input--input normal"
                  auto
                ></datetime>
                <span
                  class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                  style="margin-top: -28px;"
                >
                  Hora Salida
                </span>
              </div>
            </div>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <span class="ml-2" style="color: rgba(0, 0, 0, 0.4);">
              Justificación
            </span>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Justificación"
              rules="required"
            >
              <vs-textarea
                v-model="assistanceData.justification"
                label-placeholder="Justificación"
                val-icon-danger="error"
                maxlength="1000"
                rows="4"
                :class="{
                  'input-icon-validate-danger': errors.length > 0,
                }"
              >
              </vs-textarea>
              <small style="color: red;">
                {{ errors[0] }}
              </small>
            </ValidationProvider>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <span class="ml-2" style="color: rgba(0, 0, 0, 0.4);">
              Comprobante
            </span>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <div class="large-12 medium-12 small-12 cell cursor-pointer">
              <label for="fileUpload" class="custom-file-upload">
                <span>
                  <vs-icon icon="file_upload"></vs-icon>
                </span>
                <i class="fa fa-cloud-upload"></i> Cargar archivo
              </label>
              <small>
                {{ !!fileProof ? fileProof.name : 'No se eligió archivo' }}
              </small>

              <input
                id="fileUpload"
                ref="fileUpload"
                type="file"
                style="height: 0;"
                @change="handleFileUpload()"
              />
            </div>
          </vs-col>
        </vs-row>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import numeral from 'numeral'
import Vue from 'vue'
const { DateTime } = require('luxon')
import utils from '@/utils/utils'
import moment from 'moment-timezone'

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0')
})

export default {
  name: 'EmployeesItem',
  props: {
    source: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: function () {
    return {
      openDatepickerWorkingDate: false,
      registerAssistanceModal: false,
      linkUserModal: false,
      userId: null,
      listUsers: [],
      assistanceData: {},
      masks: {
        input: 'YYYY-MM-DD',
      },
      fileProof: '',
      employeeAssistanceAttributes: [],
    }
  },
  computed: {
    ...mapState('employee', {
      selectedEmployee: 'selectedEmployee',
      employeesList: 'employeesList',
    }),
    ...mapState('auth', { user: 'user' }),
  },
  methods: {
    ...mapActions('assistance', {
      getRegistryDateAction: 'getRegistryDateAction',
    }),
    ...mapMutations('employee', {
      setSelectedEmployee: 'setSelectedEmployee',
      setPopupVacation: 'setPopupVacation',
      setEmployeeVac: 'setEmployeeVac',
    }),
    ...mapActions('employee', {
      sendEmailAction: 'sendEmailAction',
      getUsersToEmployeeList: 'getUsersToEmployeeListAction',
      associateUser: 'associateUserAction',
      registerAssistanceAction: 'registerAssistanceAction',
      getEmployeeVacationsListAction: 'getEmployeeVacationsListAction',
    }),
    handleFileUpload() {
      this.fileProof = this.$refs.fileUpload.files[0]
    },
    /**
     * Despliega el modal para preguntar si se enviará el email de invitación.
     */
    openModalSendEmailConfirmation() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: `Invitación`,
        acceptText: 'Aceptar',
        cancelText: 'Cancelar',
        text: '¿Desea enviar invitación al usuario?',
        accept: this._sendEmail,
      })
    },
    /**
     * Inicializa los datos del popup para la creación de una asistencia justificada.
     *
     * @param employeeId {Number} Id de Empleado
     */
    async openModalRegisterAssistance(employeeId) {
      this.assistanceData = {}
      this.employeeAssistanceAttributes = []

      let selectedEmployee = this.employeesList.find((employee) => {
        return employee.id === employeeId
      })
      // Carga la lista de asistencias del empleado
      const data = await this.getRegistryDateAction(
        `employee_id=${selectedEmployee.id}&pagination=false`
      )
      let datesWithExitTime = []
      let datesWithoutExitTime = []
      // Con sólo la asistencia de entrada
      data.forEach((item) => {
        const date = moment(item.workingDate, 'YYYY-MM-DD')
          .tz('America/Mexico_City')
          .toDate()
        if (item.exitTime) {
          datesWithExitTime.push(date)
        } else {
          datesWithoutExitTime.push(date)
        }
      })
      const configWithExitTime = {
        bar: 'green',
        dates: datesWithExitTime,
      }
      const configWithoutExitTime = {
        bar: 'orange',
        dates: datesWithoutExitTime,
      }
      this.employeeAssistanceAttributes.push(configWithExitTime)
      this.employeeAssistanceAttributes.push(configWithoutExitTime)

      this.registerAssistanceModal = true
    },
    /**
     * Almacena una asistencia justificada para un empleado.
     *
     */
    async registerAssistance() {
      let exit = DateTime.fromISO(this.assistanceData.exitTime).toFormat('T')
      if (exit === 'Invalid DateTime') {
        exit = null
      }
      let entry = DateTime.fromISO(this.assistanceData.entryTime).toFormat('T')
      if (entry === 'Invalid DateTime') {
        entry = null
      }
      let formData = new FormData()
      const format = 'YYYY-MM-DD'
      const workingDateStart = moment(
        this.assistanceData.workingDate.start
      ).format(format)
      const workingDateEnd = moment(this.assistanceData.workingDate.end).format(
        format
      )
      formData.append('employee', this.source.id)
      formData.append('workingDateStart', workingDateStart)
      formData.append('workingDateEnd', workingDateEnd)
      formData.append('entryTime', entry)
      formData.append('exitTime', exit)
      formData.append('justification', this.assistanceData.justification)
      formData.append('file', this.fileProof)

      this.registerAssistanceAction(formData)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Asistencia Registrada',
          })
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title: 'Error al registrar asistencia',
            text: res,
            position: 'top-right',
            color: 'danger',
          })
        })
    },
    linkUser() {
      const data = {
        employeeId: this.source.id,
        userId: this.userId,
      }
      this.associateUserAction(data)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Usuario Vinculado',
          })
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Error al vincular el usuario',
            text: err,
            position: 'top-right',
            color: 'danger',
          })
        })
    },
    openLinkUser() {
      this.userId = null
      this.linkUserModal = true
      this.getUsersToEmployeeListAction().then((res) => {
        this.listUsers = res
      })
    },
    onSelectCandidate: function (key) {
      let selected = this.employeesList.find((employee) => {
        return employee.id === key
      })
      this.setSelectedEmployee(selected)
    },
    updateEmployee: function (id) {
      this.$router.push({ name: 'updateEmployee', params: { id } })
    },
    _sendEmail() {
      let data = {
        employee_id: this.source.id,
        company_id: this.user.userprofile.company.id,
      }
      this.sendEmailAction(data)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Invitación enviada exitosamente',
          })
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Error al enviar el correo de invitación',
            text: err,
            position: 'top-right',
            color: 'danger',
          })
        })
    },
    dismissalEmployee: function (id) {
      this.$router.push({ name: 'dismissalEmployee', params: { id } })
    },
    /**
     * Inicializa los datos del popup para la creación de vacaciones.
     *
     * @param id {Number} Id de Empleado
     */
    async openModalCreateVacation(id) {
      let selectedEmployee = this.employeesList.find((employee) => {
        return employee.id === id
      })
      await this.getEmployeeVacationsListAction(
        `employee=${selectedEmployee.id}`
      )
      this.setPopupVacation(true)
      this.setEmployeeVac({
        id: selectedEmployee.id,
        name:
          selectedEmployee.candidateInfo.name +
          ' ' +
          selectedEmployee.candidateInfo.lastName,
      })
    },
  },
}
</script>
<style lang="scss">
@import '~@/scss/_variables.scss';

input[type='file'] {
  //visibility: hidden;
}
.custom-file-upload {
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    background-color: $bg-color;
  }
}

.employeeImg {
  width: 9%;
  border-radius: 26%;
  float: left;
  margin-right: 2%;
  margin-top: 0%;
}
.employee-item {
  display: inline-flex;
  flex-direction: row;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 0.95em;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: relative !important;

  &:hover {
    cursor: pointer;
    background: #fff;
    z-index: 1;
    transform: translateY(-4px);
  }

  .candidate-selected {
    width: 100%;
    background: rgba($color: $primary, $alpha: 0.1);
  }
  > .item-content {
    padding: 0.5em 1em;
    width: 100%;
  }

  .font-card {
    font-size: 0.85em;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .con-vs-chip {
    margin: 0 2px 0 0 !important;
    padding: 0 2px !important;
    .text-chip.vs-chip--text {
      font-size: 0.95em;
      margin: 0 10px;
      padding: 0;
    }
  }

  ul.card-schools-list {
    list-style: none;
    font-size: 0.85em;
  }

  li::before {
    content: '-';
    color: rgba($color: $primary, $alpha: 0.85);
    margin-left: -1em;
    display: inline-block;
    width: 1em;
  }
  .calendar__nav-icon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    stroke: #6b7280;
  }
}
</style>
