import Vue from 'vue'

Vue.filter('format_price', function (value) {
  let val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  let arr = value.split(' ')
  let capitalizedArray = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
})

Vue.filter('filename', function (value) {
  let url = value.split('?AWS')[0]
  return url.substring(url.lastIndexOf('/') + 1)
})
