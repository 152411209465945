<template>
  <div class="candidate-info-tabs">
    <!-- Evaluaciones -->
    <vs-card class="pt-3 px-1 mb-5 mt-4">
      <div slot="header">
        <h2>
          Evaluaciones
        </h2>
      </div>
      <div>
        <vs-row class="new-candidate-container mb-4">
          <vs-col class="mt-2 mb-3 px-1 col-inputs-full" vs-w="12">
            <p>
              Por favor, evalue al candidatos según se solicite.
            </p>
          </vs-col>
          <vs-col class="mt-2" vs-lg="6" vs-sm="12">
            <p class="text-bold">Calificación del examen</p>
            <star-rating
              v-model="newCandidate.candidateRatingAdd.examResult"
              class="mt-3"
              :max-rating="10"
              :star-size="25"
              :increment="0.5"
              text-class="rating-custom-text"
              active-color="#2962ff"
            ></star-rating>
          </vs-col>
          <vs-col class="mt-3" vs-lg="6" vs-sm="12">
            <p class="text-bold">Calificación de Actitud</p>
            <star-rating
              v-model="newCandidate.candidateRatingAdd.attitudeRating"
              class="mt-3"
              :increment="0.5"
              :star-size="40"
              text-class="rating-custom-text"
              active-color="#2962ff"
            ></star-rating>
          </vs-col>
          <vs-col class="mt-3" vs-lg="6" vs-sm="12">
            <p class="text-bold">Calificación de Aptitud</p>
            <star-rating
              v-model="newCandidate.candidateRatingAdd.aptitudeRating"
              class="mt-3"
              :increment="0.5"
              :star-size="40"
              text-class="rating-custom-text"
              active-color="#2962ff"
            ></star-rating>
          </vs-col>
          <vs-col class="mt-3" vs-lg="6" vs-sm="12">
            <p class="text-bold">Calificación global</p>
            <small>
              Ingrese una calificación que generalice las características del
              candidato
            </small>
            <star-rating
              v-model="newCandidate.candidateRatingAdd.globalRating"
              class="mt-3"
              :increment="0.5"
              :star-size="40"
              text-class="rating-custom-text"
            ></star-rating>
          </vs-col>
        </vs-row>
      </div>
      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button
            color="primary"
            icon="turned_in_not"
            @click="onClickSaveRatings()"
          >
            Guardar evaluaciones
          </vs-button>
        </vs-row>
      </div>
    </vs-card>

    <!-- Documentos -->
    <vs-card
      v-if="newCandidateFormsProgress.docs.ratings"
      class="pt-3 px-1 mb-5 mt-4"
    >
      <div slot="header">
        <h2>
          Documentos
        </h2>
      </div>
      <div>
        <vs-row class="new-candidate-container mb-4">
          <vs-col class="mt-2 px-1 col-inputs-full" vs-w="12">
            <p>
              Elija los documentos que desee cargar. Los archivos deben estar
              en<strong> formato PDF. </strong>
            </p>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
          >
            <div class="centerx">
              <vs-upload
                ref="uploadCV"
                action="#"
                accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                :limit="1"
                :show-upload-button="false"
                text="Cargar CV"
              />
            </div>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
          >
            <div class="centerx">
              <vs-upload
                ref="uploadExam"
                action="#"
                accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                :limit="1"
                :show-upload-button="false"
                text="Cargar Examen Técnico"
              />
            </div>
          </vs-col>
        </vs-row>
      </div>
      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button
            color="primary"
            icon="turned_in_not"
            @click="onClickSaveDocuments()"
          >
            Guardar Documentos
          </vs-button>
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DocumentsForm',
  props: {
    newCandidate: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapState('candidates', {
      newCandidateFormsProgress: 'newCandidateFormsProgress',
    }),
  },
  methods: {
    ...mapMutations('candidates', {
      setCandidateDocs: 'setCandidateDocs',
      setNewCandidateRatings: 'setNewCandidateRatings',
      setNewCandidateProgress: 'setNewCandidateProgress',
    }),
    onClickSaveDocuments() {
      let formData = new FormData()

      // Valida el CV
      let cvFileLength = this.$refs['uploadCV']['filesx'].length
      let examFileLength = this.$refs['uploadExam']['filesx'].length

      let cvFile = this.$refs['uploadCV']['filesx'][cvFileLength - 1]
      let examFile = this.$refs['uploadExam']['filesx'][examFileLength - 1]

      if (cvFile !== undefined) {
        cvFile = cvFile.remove ? null : cvFile
      }

      // Valida el Examenen

      if (examFile !== undefined) {
        examFile = examFile.remove ? null : examFile
      }

      if (!(cvFile === null || cvFile === undefined)) {
        formData.append('cv', cvFile)
      }
      if (!(examFile === null || examFile === undefined)) {
        formData.append('exam', examFile)
      }

      this.setCandidateDocs(formData)
      this.$vs.notify({
        title: 'Documentos almacenados',
        color: 'success',
        position: 'bottom-center',
      })
    },
    onClickSaveRatings() {
      this.setNewCandidateRatings(this.newCandidate)
      this.setNewCandidateProgress({
        section: 'docs',
        card: 'ratings',
        status: true,
      })
      this.$vs.notify({
        title: 'Calificaciones almacenadas',
        color: 'success',
        position: 'bottom-center',
      })
    },
  },
}
</script>
