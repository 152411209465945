import axios from 'axios'

export async function getRegistryDateAction({ commit }, params = null) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando asistencias previas',
    },
    { root: true }
  )
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/asistencias/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        if (response.data.results) {
          resolve(response.data.results)
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newAssistanceAction({ commit }, formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`asistencias/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Realiza petición de validación de rostro de candidato
 */
// eslint-disable-next-line no-unused-vars
export async function validateEmployeePhotoAction({ commit }, formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`asistencias/validar-rostro/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateAssistanceAction({ commit }, { id, formData }) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`asistencias/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getAssistanceListAction({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/asistencias/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getVacationListAction({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/vacaciones/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getExcelVacationAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/vacaciones/excel-vacation/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function editAssistanceAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/asistencias/admin-assistance-edit/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
