<template>
  <div class="candidate-item mt-1" @click="onSelectCandidate(source.id)">
    <div class="avatar-container">
      <vs-tooltip text="Modificar candidato">
        <vs-avatar
          color="dark"
          icon="edit"
          @click="updateCandidate(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
      <vs-tooltip
        v-if="source.employee === null || source.employee == undefined"
        text="Generar Empleado"
      >
        <vs-avatar
          color="success"
          icon="badge"
          @click="createEmployee(source.id)"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
      <vs-tooltip v-else class="tooltip-employee" text="Empleado">
        <img
          class="logoEmployees"
          src="@/assets/images/fyg-logo.png"
          :alt="source.rfc"
        />
        <span>&nbsp;</span>
      </vs-tooltip>
    </div>
    <div
      class="item-content"
      :class="{
        'candidate-selected': selectedCandidate.id === source.id,
      }"
    >
      <p class="candidate-item-title">
        <strong>
          {{ source.name }} {{ source.lastName }} {{ source.secondLastName }},
          {{ source.gender === 'M' ? 'Hombre' : 'Mujer' }},
          {{ source.age }} años
        </strong>
      </p>
      <vs-row class="pt-1">
        <vs-col vs-sm="12" vs-lg="8">
          <p class="font-card">
            <strong>Vacante a la que postula:</strong>
            {{ source.jobVacancy.name }},
          </p>
          <p class="font-card">
            <strong>Estatus de la contratación:</strong>
            <template v-if="source.candidateFollowUp.status === 'IP'">
              En progreso
            </template>
            <template v-if="source.candidateFollowUp.status === 'HI'">
              Contratado
            </template>
            <template v-if="source.candidateFollowUp.status === 'RE'">
              Rechazado
            </template>
            <template v-if="source.candidateFollowUp.status === 'DA'">
              Abandonó la postulación
            </template>
          </p>
          <ul class="ml-4 card-schools-list">
            <li
              v-for="(school, schoolIndex) in source.scholarship"
              :key="schoolIndex"
            >
              {{ school.career.name }}, {{ school.school.name }}
            </li>
          </ul>
        </vs-col>
        <vs-col vs-sm="12" vs-lg="4">
          <p class="flex-end font-card">Salario deseado:</p>
          <p class="flex-end font-card">
            <strong>
              $ {{ source.minimumSalaryExpectation | formatNumber }} - $
              {{ source.maximumSalaryExpectation | formatNumber }}
            </strong>
          </p>
          <small class="flex-end">Creado el {{ source.createdAt }}</small>
        </vs-col>
      </vs-row>
      <template v-for="job in source.jobExperience">
        <vs-chip :key="job.id" color="primary" class="mt-2"
          >{{ job.job.name }}, {{ job.years }} años
        </vs-chip>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import numeral from 'numeral'
import Vue from 'vue'

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0')
})

export default {
  name: 'CandidateItem',
  props: {
    source: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapState('candidates', {
      selectedCandidate: 'selectedCandidate',
      candidatesList: 'candidatesList',
    }),
  },
  methods: {
    ...mapMutations('candidates', {
      setSelectedCandidate: 'setSelectedCandidate',
    }),
    onSelectCandidate: function (key) {
      let selected = this.candidatesList.find((candidate) => {
        return candidate.id === key
      })
      this.setSelectedCandidate(selected)
    },
    updateCandidate: function (id) {
      this.$router.push({ name: 'updateCandidate', params: { id } })
    },
    updateEmployee: function (id) {
      this.$router.push({ name: 'updateEmployee', params: { id } })
    },
    createEmployee: function (id) {
      this.$router.push({ name: 'newEmployee', params: { id } })
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';

.candidate-item {
  display: inline-flex;
  flex-direction: row;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 0.95em;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: relative !important;

  &:hover {
    cursor: pointer;
    background: #fff;
    z-index: 1;
    transform: translateY(-4px);
  }

  .candidate-selected {
    width: 100%;
    background: rgba($color: $primary, $alpha: 0.1);
  }
  > .item-content {
    padding: 0.5em 1em;
    width: 100%;
  }

  .avatar-container {
    position: absolute !important;
    right: 0;
    top: -0.75em;
    display: flex;
    .con-vs-tooltip {
      z-index: 9999;
      .con-vs-avatar {
        &:hover {
          opacity: 0.75 !important;
        }
      }
    }
  }

  .font-card {
    font-size: 0.85em;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .con-vs-chip {
    margin: 0 2px 0 0 !important;
    padding: 0 2px !important;
    .text-chip.vs-chip--text {
      font-size: 0.95em;
      margin: 0 10px;
      padding: 0;
    }
  }

  ul.card-schools-list {
    list-style: none;
    font-size: 0.85em;
  }

  li::before {
    content: '-';
    color: rgba($color: $primary, $alpha: 0.85);
    margin-left: -1em;
    display: inline-block;
    width: 1em;
  }
  .logoEmployees {
    width: 100%;
    border-radius: 50%;
  }
  .tooltip-employee {
    width: 42px;
    margin-top: 8%;
  }
}
</style>
