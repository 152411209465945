import CompanyView from '@/modules/company/views/MyCompanyView'

export default [
  {
    path: '/empresa',
    name: 'Company',
    meta: {
      title: 'Administración de la empresa',
      rule: 'admin',
    },
    component: CompanyView,
  },
]
