<template>
  <div class="vacations-container">
    <div class="vacations-container--filter-sidebar">
      <FilterSideBar ref="filterSideBar" />
    </div>
    <div class="px-3 mt-3 vacations-container--content container-fluid">
      <vs-card>
        <h2 class="my-3 px-2">Vacaciones</h2>
        <vs-table
          :max-items="25"
          :data="vacations"
          no-data-text="No hay resultados"
        >
          <template slot="thead">
            <vs-th sort-key="employee">Empleado</vs-th>
            <vs-th sort-key="initialDate">Fecha Inicio</vs-th>
            <vs-th sort-key="finalDate">Fecha Fin</vs-th>
            <vs-th sort-key="isActive">Estatus</vs-th>
            <vs-th sort-key="observations">Observaciones</vs-th>
            <vs-th>Acciones</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
              <vs-td :data="tr.employee">
                {{ tr.employeeInfo.candidateInfo.name }}
                {{ tr.employeeInfo.candidateInfo.lastName }}
                {{ tr.employeeInfo.candidateInfo.secondLastName }}
              </vs-td>
              <vs-td :data="tr.initialDate">
                {{ tr.initialDate }}
              </vs-td>
              <vs-td :data="tr.finalDate">
                {{ tr.finalDate }}
              </vs-td>
              <vs-td :data="tr.isActive">
                {{
                  tr.isActive === true
                    ? 'Vacaciones válidas'
                    : 'Vacaciones canceladas'
                }}
              </vs-td>
              <vs-td :data="tr.observations">
                {{ tr.observations }}
              </vs-td>
              <vs-td class="justify-content-left">
                <vs-tooltip v-if="tr.isActive" text="Modificar vacaciones">
                  <vs-avatar
                    color="dark"
                    icon="edit"
                    @click="openModalEditVacation(tr)"
                  />
                  <span>&nbsp;</span>
                </vs-tooltip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-col vs-type="flex" vs-justify="center" vs-w="12">
              <vs-pagination
                v-if="vacations.length > 0"
                v-model="currentPage"
                class="pagination-container mt-2 py-1 mb-5"
                :total="totalPages"
                :max="6"
              />
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </div>
    <vs-popup :title="'Editar vacaciones'" :active.sync="popupVacation">
      <vs-row vs-justify="space-around">
        <p style="font-size: x-large;">
          Vacaciones del empleado
          <span v-if="!!updateVacationsModel.employeeInfo">
            {{ updateVacationsModel.employeeInfo.candidateInfo.name }}
            {{ updateVacationsModel.employeeInfo.candidateInfo.lastName }}
          </span>
        </p>
        <vs-col class="mt-2" vs-type="flex" vs-justify="space-around" vs-w="12">
          <v-date-picker
            v-model="vacationsDateModel"
            :attributes="employeeCalendarVacationsAttributes"
            :masks="masks"
            is-range
          >
            <template v-slot="{ inputValue, inputEvents }">
              <vs-row vs-align="center">
                <vs-col vs-w="50%">
                  <vs-input
                    :value="inputValue.start"
                    readonly="true"
                    v-on="inputEvents.start"
                  />
                </vs-col>
                <vs-col vs-w="auto" vs-align="center">
                  <svg
                    style="
                      width: 12px;
                      height: 12px;
                      margin-right: 8px;
                      padding-left: 5px;
                      stroke: #6b7280;
                    "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </vs-col>
                <vs-col vs-w="50%">
                  <vs-input
                    :value="inputValue.end"
                    readonly="true"
                    v-on="inputEvents.end"
                  />
                </vs-col>
              </vs-row>
            </template>
          </v-date-picker>
        </vs-col>
        <vs-col class="mt-3" vs-align="center" vs-w="12">
          <vs-textarea
            v-model="updateVacationsModel.observations"
            :disabled="!updateVacationsModel.isActive"
            size="large"
            type="text"
            label="Observaciones"
          />
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-button
          :disabled="!!!updateVacationsModel.observations"
          color="danger"
          @click="openCancelVacationsPrompt"
        >
          Cancelar vacaciones
        </vs-button>
        <vs-button class="ml-3" color="primary" @click="updateEmployeeVacation">
          Actualizar vacaciones
        </vs-button>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FilterSideBar from '@/modules/assistance/components/FilterSideBarVacations'
import utils from '@/utils/utils'
import { eventBus } from '@/plugins/event-bus'

const _ = require('lodash')

export default {
  components: { FilterSideBar },
  data: function () {
    return {
      vacations: [],
      totalPages: 1,
      currentPage: 1,
      vacationsDateValue: {},
      vacationsDateModel: {},
      disabledDates: [],
      updateVacationsModel: {
        observations: 'a',
      },
    }
  },
  computed: {
    ...mapState('assistance', { vacationSearchParams: 'vacationSearchParams' }),
    ...mapState('employee', {
      employeeVac: 'employeeVac',
      employeeVacList: 'employeeVacList',
      popupVacation: 'popupVacation',
    }),
    popupVacation: {
      get() {
        return this.$store.state.employee.popupVacation
      },
      set(val) {
        this.setPopupVacation(val)
      },
    },
  },
  watch: {
    currentPage(newPage) {
      let query = `${this.vacationSearchParams}&page=${newPage}`
      this.loadData(query)
    },
    vacationSearchParams() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        let query = `${this.vacationSearchParams}&page=${this.currentPage}`
        this.loadData(query)
      }
    },
    employeeVac() {
      this.updateVacationsModel = _.cloneDeep(this.employeeVac)
    },
    employeeVacList() {
      this.disabledDates = []
      Array.from(this.employeeVacList).forEach((item) => {
        const itemArrI = item['initialDate'].split('-')
        const itemArrF = item['finalDate'].split('-')
        const idd = itemArrI[2]
        const imm = itemArrI[1] - 1
        const iyyyy = itemArrI[0]
        const fdd = itemArrF[2]
        const fmm = itemArrF[1] - 1
        const fyyyy = itemArrF[0]

        if (item.id === this.employeeVac.id) {
          this.vacationsDateModel = {
            start: new Date(iyyyy, imm, idd),
            end: new Date(fyyyy, fmm, fdd),
          }
          return
        }

        this.disabledDates.push({
          start: new Date(iyyyy, imm, idd),
          end: new Date(fyyyy, fmm, fdd),
        })
      })
    },
  },
  created() {
    let diaS = this.getActualDay()
    const initialParams = `Idate=${diaS}&Fdate=${diaS}&page=1`
    this.loadData(initialParams)
    eventBus.$on('loadData', this.loadData)
  },
  beforeDestroy() {
    eventBus.$off('searchVacations', this.listener)
  },
  methods: {
    ...mapMutations('employee', {
      setEmployeeVac: 'setEmployeeVac',
      setPopupVacation: 'setPopupVacation',
    }),
    ...mapActions('assistance', {
      getVacationListAction: 'getVacationListAction',
    }),
    ...mapActions('employee', {
      getEmployeeVacationsListAction: 'getEmployeeVacationsListAction',
      updateVacationAction: 'updateVacationAction',
    }),
    loadData(query) {
      this.getVacationListAction(query).then((res) => {
        this.vacations = res.results
        this.totalPages = Math.ceil(res['count'] / res['pageSize'])
      })
    },
    /**
     * Regresa el día actual en formato "yyyy-mm-dd"
     *
     * @returns {string} Fecha actual
     */
    getActualDay() {
      return utils.getActualDate()
    },
    /**
     * Inicializa los datos del popup para la edición de vacaciones
     * @param selectedItem
     */
    async openModalEditVacation(selectedItem) {
      this.setEmployeeVac(selectedItem)
      await this.getEmployeeVacationsListAction(
        `employee=${selectedItem.employeeInfo.id}`
      )
      this.setPopupVacation(true)
    },
    openCancelVacationsPrompt() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: `Confirmar cancelación`,
        text: `¿Está seguro que desea cancelar estas vacaciones?`,
        acceptText: 'Aceptar',
        cancelText: 'Cancelar',
        accept: this.deactivateEmployeeVacation,
      })
    },
    updateEmployeeVacation() {
      let data = {
        initialDate: this.vacationsDateModel.start.toISOString().split('T')[0],
        finalDate: this.vacationsDateModel.end.toISOString().split('T')[0],
        observations: this.updateVacationsModel.observations,
      }
      this.updateVacationAction({ id: this.employeeVac.id, data })
        .then(() => {
          this.setPopupVacation(false)
          this.vacationsDateModel = {}
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Vacaciones actualizadas exitosamente',
          })
          this.searchVacations()
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Error al actualizar las vacaciones',
            text: err.response.data['errors'][0]['message'],
            position: 'top-right',
            color: 'danger',
          })
        })
    },
    searchVacations() {
      eventBus.$emit('searchVacations')
    },
    deactivateEmployeeVacation() {
      this.updateVacationsModel.isActive = false
      let data = {
        observations: this.updateVacationsModel.observations,
        isActive: this.updateVacationsModel.isActive,
      }
      this.updateVacationAction({ id: this.updateVacationsModel.id, data })
        .then(() => {
          this.setPopupVacation(false)
          this.vacationsDateModel = {}
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Vacaciones canceladas.',
          })
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Error al cancelar las vacaciones.',
            text: err.response.data['errors'][0]['message'],
            position: 'top-right',
            color: 'danger',
          })
        })
    },
  },
}
</script>

<style lang="scss">
.vacations-container {
  display: flex;
}
</style>
