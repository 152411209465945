<template>
  <vs-row
    class="edit-candidate-container container"
    type="flex"
    vs-justify="center"
  >
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <vs-tabs v-if="candidateSuccess">
        <!--Información general -->
        <vs-tab label="Información General">
          <InformacionGeneralForm
            ref="informacionGeneralFormComponent"
            :update-candidate="updateCandidate"
          />
        </vs-tab>
        <!-- Estudios -->
        <vs-tab label="Estudios">
          <EstudiosForm :update-candidate="updateCandidate" />
        </vs-tab>
        <!-- Experiencia personal -->
        <vs-tab label="Experiencia Profesional">
          <ExperienciaProfesionalForm :update-candidate="updateCandidate" />
        </vs-tab>
        <!-- Personalidad -->
        <vs-tab label="Personalidad">
          <PersonalidadForm :update-candidate="updateCandidate" />
        </vs-tab>
        <!-- Documentos y Evaluación -->
        <vs-tab label="Documentos y Evaluación">
          <DocumentsForm :update-candidate="updateCandidate" />
        </vs-tab>
      </vs-tabs>
    </vs-col>
    <vue-fab
      size="big"
      icon="done"
      main-btn-color="#388e3c"
      :scroll-auto-hide="false"
      @clickMainBtn="onClickUpdateCandidate"
    >
    </vue-fab>
  </vs-row>
</template>

<script>
import InformacionGeneralForm from '@/modules/candidates/components/update/InformacionGeneralForm'
import EstudiosForm from '@/modules/candidates/components/update/EstudiosForm'
import ExperienciaProfesionalForm from '@/modules/candidates/components/update/ExperienciaProfesionalForm'
import PersonalidadForm from '@/modules/candidates/components/update/PersonalidadForm'
import DocumentsForm from '@/modules/candidates/components/update/DocumentsForm'

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'UpdateCandidateView',
  components: {
    InformacionGeneralForm,
    EstudiosForm,
    ExperienciaProfesionalForm,
    PersonalidadForm,
    DocumentsForm,
  },
  data: function () {
    return {
      inputError: false,
      candidateSuccess: false,
    }
  },
  computed: {
    ...mapState('candidates', {
      updateCandidate: 'updateCandidate',
      candidateDocs: 'candidateDocs',
    }),
    ...mapGetters('candidates', {
      finalUpdateCandidate: 'finalUpdateCandidate',
    }),
  },
  mounted() {
    if (
      document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0] != undefined
    ) {
      let element = document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0]
      element.parentNode.removeChild(element)
    }
    this.getCandidateById(this.$route.params.id)
      .then((res) => {
        this.setUpdateCandidate(res)
        this.candidateSuccess = true
      })
      .catch(() => {
        this.$vs.notify({
          title: 'Opps...',
          text: 'Candidato no encontrado',
        })
        this.$router.push({ name: 'candidatesList' })
      })
  },
  methods: {
    ...mapActions('candidates', {
      updateCandidateAction: 'updateCandidateAction',
      saveCandidateDocs: 'saveCandidateDocs',
      getCandidatesList: 'getCandidatesList',
      getCandidateById: 'getCandidateById',
    }),
    ...mapMutations('candidates', {
      clearUpdateCandidateData: 'clearUpdateCandidateData',
      setUpdateCandidate: 'setUpdateCandidate',
    }),
    onClickUpdateCandidate() {
      this.$vs.loading({
        text: 'Actualizando candidato...',
      })
      this.updateCandidateAction({
        data: this.finalUpdateCandidate,
        id: this.$route.params.id,
      })
        .then((res) => {
          this.$vs.loading.close()
          // noinspection JSUnresolvedFunction
          this.$vs.loading({
            text: 'Almacenando archivo ...',
          })
          this.saveCandidateDocs({
            formData: this.candidateDocs,
            id: res['candidateDocument']['id'],
          }).then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Éxito.',
              text: 'Documentos almacenados',
            })
            this.goToCandidatesPage()
          })
        })
        .catch((error) => {
          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title:
              'Revise los campos señalados, guárdelos y reintente nuevamente.',
            text: `${res}`,
            color: 'danger',
            fixed: true,
            position: 'top-right',
          })
        })
    },
    goToCandidatesPage() {
      this.clearUpdateCandidateData()
      this.$router.push({
        name: 'candidatesList',
      })
    },
    resetObserverValidations() {
      this.$refs.informacionGeneralFormComponent._resetObserverValidations()
    },
  },
}
</script>

<style lang="scss">
.edit-candidate-container {
  margin: 0 auto;
}
.block {
  width: 100% !important;
}
.candidate-cards-container {
  overflow-y: auto;
}
.vs-input-number .vs-input-number--input {
  width: auto !important;
}
.vs-tabs--content {
  min-height: 700px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
