import CatalogsView from '@/modules/catalogs/views/CatalogsView'

export default [
  {
    path: '/catalogos',
    name: 'catalogs',
    meta: {
      title: 'Catálogos',
      rule: 'recruiter',
    },
    component: CatalogsView,
  },
]
