<template>
  <vs-row vs-justify="center" class="pt-0 px-1 candidate-card-container">
    <vs-col type="flex" vs-justify="center" vs-w="12">
      <!-- Encabezado -->
      <vs-card class="candidate-card pt-1">
        <h2>
          {{ selectedCandidate.name }} {{ selectedCandidate.lastName }}
          {{ selectedCandidate.secondLastName }}
        </h2>
        <h4>
          {{ selectedCandidate.address.postalCode.municipality }},
          {{ selectedCandidate.address.postalCode.estate }}
        </h4>
        <p class="small-font mt-1">
          Fecha Disponible para entrar:
          <span class="text-bold">
            {{ selectedCandidate.availabilityToStart }}
          </span>
        </p>
        <p class="small-font mt-1">
          <span>Estatus de la contratación:</span>
          <span class="text-bold">
            <template
              v-if="selectedCandidate.candidateFollowUp.status === 'IP'"
            >
              En progreso
            </template>
            <template
              v-if="selectedCandidate.candidateFollowUp.status === 'HI'"
            >
              Contratado
            </template>
            <template
              v-if="selectedCandidate.candidateFollowUp.status === 'RE'"
            >
              Rechazado
            </template>
            <template
              v-if="selectedCandidate.candidateFollowUp.status === 'DA'"
            >
              Abandonó la postulación
            </template>
          </span>
        </p>
        <p
          v-if="selectedCandidate.candidateFollowUp.observations !== ''"
          class="mt-1 small-font"
        >
          <span>Comentarios: </span>
          <span class="text-bold">{{
            selectedCandidate.candidateFollowUp.observations
          }}</span>
        </p>
      </vs-card>
      <!-- Calificaciones del candidato -->
      <vs-card
        v-if="selectedCandidate.candidateRating"
        class="candidate-card p-1"
      >
        <div class="candidate-card-info-container">
          <h4 class="vs-header-list-primary">
            Calificaciones del candidato
          </h4>
          <vs-row>
            <vs-col vs-row="12" class="mt-2">
              <p class="text-bold">Calificación examen técnico</p>
              <star-rating
                v-model="selectedCandidate.candidateRating.examResult"
                active-color="#2962ff"
                :max-rating="10"
                :star-size="30"
                :read-only="true"
                :increment="0.01"
                text-class="rating-custom-text"
              >
              </star-rating>
            </vs-col>
            <vs-col vs-row="12" class="mt-2">
              <p class="text-bold">Calificación global</p>
              <small>
                Calificación que generaliza las características del candidato
              </small>
              <star-rating
                v-model="selectedCandidate.candidateRating.globalRating"
                :increment="0.5"
                :max-rating="5"
                :star-size="35"
                :read-only="true"
                text-class="rating-custom-text"
              >
              </star-rating>
              <vs-col vs-row="12" class="mt-2">
                <p class="text-bold">Calificación de actitud</p>
                <star-rating
                  v-model="selectedCandidate.candidateRating.aptitudeRating"
                  active-color="#2962ff"
                  :increment="0.5"
                  :max-rating="5"
                  :star-size="35"
                  :read-only="true"
                  text-class="rating-custom-text"
                >
                </star-rating>
              </vs-col>
              <vs-col vs-row="12" class="mt-2">
                <p class="text-bold">Calificación de aptitud</p>
                <star-rating
                  v-model="selectedCandidate.candidateRating.attitudeRating"
                  active-color="#2962ff"
                  :increment="0.5"
                  :max-rating="5"
                  :star-size="35"
                  :read-only="true"
                  text-class="rating-custom-text"
                >
                </star-rating>
              </vs-col>
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
      <!-- Empleos -->
      <vs-card class="candidate-card p-1">
        <div class="candidate-card-info-container">
          <vs-list>
            <vs-list-header title="Trabajos anteriores" color="primary" />
            <vs-list-item
              v-for="job in selectedCandidate.jobExperience"
              :key="`job-${job.id}`"
              :title="`${job.job.name}`"
              :subtitle="`${job.company}: ${job.startDate} - ${
                job.endDate ? job.endDate : 'Actualidad'
              }`"
            />
          </vs-list>
        </div>
      </vs-card>
      <!-- Cualidades -->
      <vs-card class="candidate-card p-1">
        <div class="candidate-card-info-container">
          <vs-list>
            <vs-list-header title="Cualidades" color="primary" />
            <vs-list-item>
              <vs-row>
                <vs-col
                  v-for="quality in selectedCandidate.quality"
                  :key="`quality-${quality.id}`"
                  vs-row="12"
                >
                  <p v-text="quality.quality"></p>
                </vs-col>
              </vs-row>
            </vs-list-item>
            <vs-list-header title="Áreas de oportunidad" color="primary" />
            <vs-list-item>
              <vs-row>
                <vs-col
                  v-for="opportunity in selectedCandidate.opportunityArea"
                  :key="`opportunity-${opportunity.id}`"
                  vs-row="12"
                  type="flex"
                  vs-align="flex-start"
                >
                  <p v-text="opportunity.opportunity"></p>
                </vs-col>
              </vs-row>
            </vs-list-item>
            <vs-list-header title="Percepciones externas" color="primary" />
            <vs-list-item>
              <vs-row>
                <vs-col
                  v-for="perception in selectedCandidate.externalPerceptions"
                  :key="`perception-${perception.id}`"
                  vs-row="12"
                  type="flex"
                  vs-align="flex-start"
                >
                  <p v-text="perception.perception"></p>
                </vs-col>
              </vs-row>
            </vs-list-item>
          </vs-list>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CandidateInfo',
  computed: {
    ...mapState('candidates', {
      selectedCandidate: 'selectedCandidate',
    }),
  },
}
</script>

<style lang="scss">
.candidate-card-info-container {
  max-height: 90vh;
  overflow-y: auto;
  .vs-list--slot {
    margin-left: initial !important;
  }
}
</style>
