<template>
  <div class="pt-3 pb-3 px-3 full-height">
    <vs-row vs-justify="center" class="mt-1">
      <vs-col vs-justify="center" vs-align="center" vs-xs="12" vs-lg="3">
        <FilterSideBar />
      </vs-col>
      <vs-col
        class="pr-4 px-5"
        vs-justify="center"
        vs-align="center"
        vs-xs="12"
        vs-lg="9"
      >
        <vs-card class="pt-1 px-1 ml-3 mr-4">
          <h2>Asistencia</h2>
          <vs-table
            :max-items="25"
            :data="assistance"
            no-data-text="No hay resultados"
          >
            <template slot="thead">
              <vs-th sort-key="workingDate">Fecha</vs-th>
              <vs-th sort-key="entryTime"> Entrada </vs-th>
              <vs-th sort-key="exiTime"> Salida </vs-th>
              <vs-th sort-key="justification"> Justificación </vs-th>
              <vs-th sort-key=""></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
                <vs-td :data="tr.workingDate">
                  {{ tr.workingDate }}
                </vs-td>
                <vs-td :data="tr.entryTime">
                  <vs-row>
                    <span style="vertical-align: middle;" class="my-auto">{{
                      tr.entryTime
                    }}</span>
                    <img
                      v-if="tr.entryPicture"
                      class="employeeImg mx-auto"
                      :src="tr.entryPicture"
                    />
                  </vs-row>
                </vs-td>
                <vs-td :data="tr.exitTime">
                  <vs-row>
                    <span style="vertical-align: middle;" class="my-auto">{{
                      tr.exitTime
                    }}</span>
                    <img
                      v-if="tr.exitPicture"
                      class="employeeImg mx-auto"
                      :src="tr.exitPicture"
                    />
                  </vs-row>
                </vs-td>
                <vs-td :data="tr.justification">
                  {{ tr.justification }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-col vs-type="flex" vs-justify="center" vs-w="12">
                <vs-pagination
                  v-if="assistance.length > 0"
                  v-model="currentPage"
                  class="pagination-container mt-2 py-1 mb-5"
                  :total="totalPages"
                  :max="6"
                />
              </vs-col>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-popup :active.sync="showImage" :title="popupTitle">
      <img
        class="assistancePicture"
        :src="selectedImage"
        :alt="selectedImage"
      />
    </vs-popup>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import FilterSideBar from '@/modules/assistance/components/FilterSideBarEmployee'
import utils from '@/utils/utils'
export default {
  components: { FilterSideBar },
  data: function () {
    return {
      selectedImage: '',
      showImage: false,
      popupTitle: '',
      assistance: [],
      totalPages: 1,
      currentPage: 1,
      editAssistance: {},
    }
  },
  computed: {
    ...mapState('assistance', { searchParams: 'searchParams' }),
  },
  watch: {
    currentPage: function (newPage) {
      let query = `${this.searchParams}&page=${newPage}`
      this.loadData(query)
    },
    searchParams: function () {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        let query = `${this.searchParams}&page=${this.currentPage}`
        this.loadData(query)
      }
    },
  },
  created() {
    let diaS = this.getDay()
    const initialParams = `Idate=${diaS}&Fdate=${this.addOneDay(diaS)}&page=1`
    this.loadData(initialParams)
  },
  methods: {
    ...mapActions('assistance', {
      getAssistanceListAction: 'getAssistanceListAction',
      editAssistanceAction: 'editAssistanceAction',
    }),
    loadData(query) {
      this.getAssistanceListAction(query + '&employeeView=true').then((res) => {
        this.assistance = res.results
        this.totalPages = Math.ceil(res['count'] / res['pageSize'])
      })
    },
    _BeforeToday(date) {
      return utils.BeforeToday(date)
    },
    getDay() {
      return utils.getActualDate()
    },
    addOneDay(dayf) {
      let dia = new Date(dayf)
      dia.setDate(dia.getDate() + 2)
      let diaS = dia.getFullYear() + '-'
      if (dia.getMonth() > 8) {
        diaS = diaS + (dia.getMonth() + 1)
      } else {
        diaS = diaS + '0' + (dia.getMonth() + 1)
      }
      if (dia.getDate() > 9) {
        diaS = diaS + '-' + dia.getDate()
      } else {
        diaS = diaS + '-0' + dia.getDate()
      }
      return diaS
    },
    popupImage(data, option) {
      if (option === 'Enter') {
        this.selectedImage = data.entryPicture
        this.popupTitle =
          'Foto Entrada ' +
          data.employeeInfo.candidateInfo.name +
          ' ' +
          data.employeeInfo.candidateInfo.lastName +
          ' ' +
          data.employeeInfo.candidateInfo.secondLastName +
          ' ' +
          data.workingDate
      } else {
        this.selectedImage = data.exitPicture
        this.popupTitle =
          'Foto Salida ' +
          data.employeeInfo.candidateInfo.name +
          ' ' +
          data.employeeInfo.candidateInfo.lastName +
          ' ' +
          data.employeeInfo.candidateInfo.secondLastName +
          ' ' +
          data.workingDate
      }
      this.showImage = true
    },
  },
}
</script>
<style lang="scss">
.assistancePicture {
  width: 100%;
  max-width: 600px;
  object-fit: contain;
}
.employeeImg {
  width: 100%;
  max-width: 100px;
  object-fit: contain;
  border-radius: 26%;
}
</style>
