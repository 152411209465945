import NewEmployeeView from '@/modules/employee/views/NewEmployeeView'
import UpdateEmployeeView from '@/modules/employee/views/UpdateEmployeeView'
import EmployeeView from '@/modules/employee/views/EmployeeView'
import DismissalEmployeeView from '@/modules/employee/views/DismissalEmployeeView'

export default [
  {
    path: '/empleados/',
    name: 'employeeList',
    meta: {
      title: 'Lista de Empleados',
      rule: 'recruiter',
    },
    component: EmployeeView,
  },
  {
    path: '/empleados/nuevo/:id',
    name: 'newEmployee',
    meta: {
      title: 'Nuevo Empleado',
      rule: 'recruiter',
    },
    component: NewEmployeeView,
  },
  {
    path: '/empleados/modifica/:id',
    name: 'updateEmployee',
    meta: {
      title: 'Modificación Empleado',
      rule: 'recruiter',
    },
    component: UpdateEmployeeView,
  },
  {
    path: '/empleados/baja/:id',
    name: 'dismissalEmployee',
    meta: {
      title: 'Baja Empleado',
      rule: 'recruiter',
    },
    component: DismissalEmployeeView,
  },
]
