var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 mx-5"},[_c('vs-row',[_c('vs-col',{staticClass:"pr-3",attrs:{"vs-justify":"center","vs-lg":"3","vs-xs":"12"}},[_c('vs-row',[_c('vs-col',{attrs:{"vs-w":"10"}},[_c('vs-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',[_vm._v(_vm._s(_vm.company.name))])]),_c('div',{attrs:{"slot":"media"},slot:"media"},[[(
                    _vm.company.picture !== undefined &&
                    _vm.company.picture !== '#' &&
                    _vm.company.picture !== null
                  )?_c('vs-col',{staticClass:"mt-2",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('small',{staticClass:"mx-auto"},[_vm._v(" Imagen Actual ")]),_c('vs-icon',{staticClass:"cursor-pointer",attrs:{"icon":"edit","color":"blue"},on:{"click":() => {
                        _vm.showImage = !_vm.showImage
                      }}})],1):_vm._e(),_c('vs-col',{staticClass:"preview-images mb-2",attrs:{"vs-type":"flex"}},[(
                      _vm.company.picture !== undefined &&
                      _vm.company.picture !== '#' &&
                      _vm.company.picture !== null
                    )?[_c('img',{staticClass:"grid-view-img mx-auto",attrs:{"src":_vm.company.picture}})]:_vm._e()],2),(
                    _vm.showImage ||
                    _vm.company.picture === undefined ||
                    _vm.company.picture === '#' ||
                    _vm.company.picture === null
                  )?[_c('picture-input',{key:_vm.keyImage,ref:"uploadMainPhoto",staticClass:"mt-3",staticStyle:{"width":"80%"},attrs:{"size":"7","crop":false,"min-width":300,"min-height":300},on:{"change":function($event){return _vm.onChangeImgOne()}}})]:_vm._e()]],2),_c('div',{staticClass:"user-profile-container"},[_c('vs-input',{staticClass:"mt-2",attrs:{"type":"text","label":"Empresa"},model:{value:(_vm.companyData.name),callback:function ($$v) {_vm.$set(_vm.companyData, "name", $$v)},expression:"companyData.name"}}),_c('vs-row',{attrs:{"vs-justify":"flex-end"}},[_c('vs-button',{staticClass:"mt-3 btn-block",attrs:{"color":"primary","type":"gradient"},on:{"click":_vm._updateCompany}},[_vm._v("Actualizar datos de la empresa")])],1)],1)])],1)],1)],1),_c('vs-col',{staticClass:"px-3",attrs:{"vs-justify":"left","vs-lg":"9","vs-xs":"12"}},[_c('VueGoodTable',{attrs:{"columns":_vm.columns,"rows":_vm.usersList,"line-numbers":true,"max-height":"65vh","pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'top',
          perPageDropdown: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          rowsPerPageLabel: 'Registros por Página',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        }}})],1)],1),_c('vue-fab',{attrs:{"size":"big"},on:{"clickMainBtn":function($event){_vm.activePromptInviteUser = true}}}),_c('vs-prompt',{attrs:{"active":_vm.activePromptInviteUser,"title":"Invitar usuario","accept-text":"Enviar invitación","cancel-text":"Cancelar"},on:{"update:active":function($event){_vm.activePromptInviteUser=$event},"accept":function($event){return _vm.sendEmail()}}},[_c('div',{staticClass:"input mt-2"},[_c('vs-input',{attrs:{"icon":"email","label-placeholder":"Email para enviar la invitación","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"input mt-3 justify-content-left"},[_c('vs-radio',{attrs:{"vs-name":"rol","vs-value":"isRecruiter"},model:{value:(_vm.rolRadio),callback:function ($$v) {_vm.rolRadio=$$v},expression:"rolRadio"}},[_vm._v(" Reclutador ")]),_c('vs-radio',{staticClass:"ml-4",attrs:{"vs-name":"rol","vs-value":"isCompanyAdmin"},model:{value:(_vm.rolRadio),callback:function ($$v) {_vm.rolRadio=$$v},expression:"rolRadio"}},[_vm._v(" Administrador ")])],1)]),_c('vs-prompt',{attrs:{"color":"primary","active":_vm.dialog,"title":"Recortar Imagen","accept-text":"Aceptar","cancel-text":"Cerrar"},on:{"update:active":function($event){_vm.dialog=$event},"accept":_vm.saveImageOne,"close":_vm.cancelImage,"cancel":_vm.cancelImage}},[_c('div',{staticClass:"modal-venta"},[_c('vueCropper',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedFile),expression:"selectedFile"}],ref:"cropperPhoto",attrs:{"src":_vm.selectedFile,"crop-box-resizable":true,"aspect-ratio":1,"alt":"Source Image"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }