<template>
  <vs-row
    class="update-candidate-container container-fluid"
    type="flex"
    vs-justify="center"
  >
    <vs-tabs alignment="center">
      <!--Información general -->
      <vs-tab label="Información General">
        <vs-col vs-justify="center" class="px-1" vs-lg="3" vs-sm="4" vs-xs="12">
          <div class="candidate-info-tabs mt-4">
            <div class="candidate-cards-container">
              <vs-card class="px-3">
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-align="center"
                    vs-xs="12"
                    vs-sm="12"
                    vs-lg="12"
                  >
                    <vs-row>
                      <vs-col>
                        <h2>
                          <p class="mt-1 mb-7 text-bold" vs-align="center">
                            Foto Empleado
                          </p>
                        </h2>
                      </vs-col>
                      <vs-col
                        v-if="
                          image !== undefined && image !== '#' && image !== null
                        "
                        class="mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <small class="mx-auto"> Imagen Actual </small>
                        <vs-icon
                          icon="edit"
                          color="blue"
                          class="cursor-pointer"
                          @click="
                            () => {
                              showImage = !showImage
                            }
                          "
                        ></vs-icon>
                      </vs-col>
                      <vs-col vs-type="flex" class="preview-images mb-2">
                        <template
                          v-if="
                            image !== undefined &&
                            image !== '#' &&
                            image !== null
                          "
                        >
                          <img
                            :src="cropedImage"
                            class="grid-view-img mx-auto"
                          />
                        </template>
                      </vs-col>
                      <template
                        v-if="
                          showImage ||
                          image === undefined ||
                          image === '#' ||
                          image === null
                        "
                      >
                        <picture-input
                          :key="keyImage"
                          ref="uploadMainPhoto"
                          class="mt-3"
                          size="7"
                          :crop="false"
                          :min-width="300"
                          :min-height="300"
                          @change="onChangeImgOne()"
                        >
                        </picture-input>
                      </template>
                      <!-- <vs-col vs-type="flex" vs-align="center">
                        <picture-input
                          ref="uploadMainPhoto"
                          size="5"
                          :min-width="300"
                          :min-height="300"
                          :crop="false"
                          :prefill="image"
                          @change="onChangeImgOne()"
                        >
                        </picture-input>
                      </vs-col> -->
                    </vs-row>
                  </vs-col>
                </vs-row>
              </vs-card>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="9"
          vs-xs="12"
        >
          <div class="candidate-info-tabs mt-4">
            <vs-alert
              v-for="(err, key) in getUpdateEmployeeErrors.generalInformation
                .employeeData"
              :key="key"
              class="mt-1"
              color="danger"
              icon="new_releases"
            >
              <span>{{ `${err.name}: ${err.msg}` }}</span>
            </vs-alert>
            <div class="candidate-cards-container px-3">
              <!-- Datos personales-->
              <ValidationObserver
                ref="observerDatosCandidato"
                v-slot="{ invalid }"
              >
                <vs-card class="pt-3 px-1">
                  <div slot="header">
                    <h2>Datos del empleado</h2>
                  </div>
                  <div>
                    <vs-row class="update-candidate-container mb-4">
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="4"
                      >
                        <vs-input
                          v-model="updateEmployee.name"
                          label-placeholder="Nombre(s)"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="4"
                      >
                        <vs-input
                          v-model="updateEmployee.lastName"
                          label-placeholder="Apellido Paterno"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="4"
                      >
                        <vs-input
                          v-model="updateEmployee.secondLastName"
                          label-placeholder="Apellido Materno"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="CURP"
                          rules="required|max:18|min:18|curp"
                        >
                          <vs-input
                            v-model="updateEmployee.curp"
                            label-placeholder="CURP"
                            maxlength="18"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="RFC"
                          rules="required|max:13|min:13|rfc"
                        >
                          <vs-input
                            v-model="updateEmployee.rfc"
                            label-placeholder="RFC"
                            val-icon-danger="error"
                            maxlength="13"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="NSS"
                          rules="required|max:11|min:11"
                        >
                          <vs-input
                            v-model="updateEmployee.nss"
                            label-placeholder="NSS"
                            val-icon-danger="error"
                            maxlength="11"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('employeeData')"
                      >
                        Guardar datos empleado
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-xs="12"
        >
          <div class="candidate-info-tabs mt-4">
            <div class="candidate-cards-container px-3">
              <!-- Prestaciones -->
              <ValidationObserver
                ref="observerPrestaciones"
                v-slot="{ invalid }"
              >
                <vs-card class="pt-3 px-1">
                  <div slot="header">
                    <h2>Datos empleo</h2>
                  </div>
                  <div>
                    <vs-row>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="nómina"
                          rules="required|numeric"
                        >
                          <vs-input
                            v-model="updateEmployee.payrollNumber"
                            label-placeholder="Número de Nómina"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Puesto"
                          rules="required"
                        >
                          <vs-col
                            class="col-inputs-full mt-2"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="10"
                          >
                            <vs-select
                              v-model="updateEmployee.payrollPosition"
                              autocomplete
                              class="block col-inputs-full"
                              label="Puesto"
                              placeholder="Elija puesto"
                              val-icon-danger="error"
                              :danger="!!errors[0]"
                              :danger-text="errors[0]"
                              :disabled="!updateEmployee.isActive"
                            >
                              <vs-select-item
                                v-for="(item, index) in payrollPosition"
                                :key="index"
                                :value="item.id"
                                :text="item.name"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col
                            class="col-inputs-full mt-3 pt-1"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                          >
                            <vs-icon
                              icon="add"
                              color="success"
                              class="cursor-pointer mt-3"
                              @click="newCatalog('payrollPosition')"
                            ></vs-icon>
                          </vs-col>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Correo interno"
                          rules="required|email"
                        >
                          <vs-input
                            v-model="updateEmployee.internalEmail"
                            label-placeholder="Correo interno"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Sueldo diario nómina"
                          rules="required|decimal|min_value:0"
                        >
                          <vs-input
                            v-model="updateEmployee.nominalDailySalary"
                            label-placeholder="Sueldo Diario Nómina"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Sueldo mixto neto mensual"
                          rules="required|decimal|min_value:0"
                        >
                          <vs-input
                            v-model="updateEmployee.monthlyNetMixedSalary"
                            label-placeholder="Sueldo Mixto Neto Mensual"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider name="fechaAlta">
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <date-picker
                                v-model="updateEmployee.startDate"
                                class="full-width"
                                placeholder="Fecha alta"
                                value-type="format"
                                format="YYYY-MM-DD"
                                :open.sync="openStartDatepicker"
                                :disabled="!updateEmployee.isActive"
                              >
                                <template v-slot:footer>
                                  <button
                                    class="mx-btn mx-btn-text mx-2"
                                    @click="openStartDatepicker = false"
                                  >
                                    Listo
                                  </button>
                                </template>
                              </date-picker>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Fecha alta
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col>
                      <!--vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider name="fechaBaja">
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <date-picker
                                v-model="updateEmployee.endDate"
                                class="full-width"
                                placeholder="Fecha baja"
                                value-type="format"
                                format="YYYY-MM-DD"
                                :open.sync="openDatepicker"
                                :disabled="!updateEmployee.isActive"
                              >
                                <template v-slot:footer>
                                  <button
                                    class="mx-btn mx-btn-text mx-2"
                                    @click="openDatepicker = false"
                                  >
                                    Listo
                                  </button>
                                </template>
                              </date-picker>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Fecha baja
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col-->
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      ></vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('job')"
                      >
                        Guardar empleo
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>

              <ValidationObserver
                ref="observerPrestaciones"
                v-slot="{ invalid }"
              >
                <vs-card class="pt-3 px-1">
                  <div slot="header">
                    <h2>Asignación</h2>
                  </div>
                  <div>
                    <vs-row>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="area"
                          rules="required"
                        >
                          <vs-col
                            class="col-inputs-full mt-2"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="10"
                          >
                            <vs-select
                              v-model="updateEmployee.area"
                              autocomplete
                              class="block col-inputs-full"
                              label="Área"
                              placeholder="Elija área en la que labora"
                              val-icon-danger="error"
                              :danger="!!errors[0]"
                              :danger-text="errors[0]"
                              :disabled="!updateEmployee.isActive"
                            >
                              <vs-select-item
                                v-for="(item, index) in areaCatalog"
                                :key="index"
                                :value="item.id"
                                :text="item.name"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col
                            class="col-inputs-full mt-3 pt-1"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                          >
                            <vs-icon
                              icon="add"
                              color="success"
                              class="cursor-pointer mt-3"
                              @click="newCatalog('area')"
                            ></vs-icon>
                          </vs-col>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-3 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          name="Hora Entrada"
                          rules="required"
                        >
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <datetime
                                v-model="updateEmployee.entryTime"
                                type="time"
                                use12-hour
                                :phrases="{
                                  ok: 'Confirmar',
                                  cancel: 'Cancelar',
                                }"
                                input-class="vs-inputx vs-input--input normal"
                                auto
                                :disabled="!updateEmployee.isActive"
                              ></datetime>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Hora Entrada
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-3 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider name="Hora Salida" rules="required">
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <datetime
                                v-model="updateEmployee.exitTime"
                                type="time"
                                use12-hour
                                :phrases="{
                                  ok: 'Confirmar',
                                  cancel: 'Cancelar',
                                }"
                                input-class="vs-inputx vs-input--input normal"
                                auto
                                :disabled="!updateEmployee.isActive"
                              ></datetime>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Hora Salida
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('assigment')"
                      >
                        Guardar asignación
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>

              <!-- Contacto -->
              <ValidationObserver ref="observerContacto" v-slot="{ invalid }">
                <vs-card :ref="contactosData" class="pt-3 px-1">
                  <div slot="header">
                    <h2>Contactos</h2>
                  </div>
                  <div>
                    <h3 class="mt-3">Teléfonos de contacto</h3>
                    <vs-row
                      v-for="contact in updateEmployee.contacts_add"
                      :key="contact.idTemp"
                      class="update-candidate-container mb-4"
                    >
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Nombre"
                          rules="required"
                        >
                          <vs-input
                            v-model="contact.name"
                            label="Nombre"
                            label-placeholder="Nombre"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Teléfono de contacto"
                          rules="required|numeric|min:10|max:10"
                        >
                          <vs-input
                            v-model="contact.phoneNumber"
                            label="Teléfono"
                            label-placeholder="Teléfono de contacto"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="parentesco"
                          rules="required"
                        >
                          <vs-col
                            class="col-inputs-full mt-2"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="10"
                          >
                            <vs-select
                              v-model="contact.relationship"
                              autocomplete
                              class="block col-inputs-full"
                              label="Parentesco"
                              placeholder="Elija parentesco"
                              val-icon-danger="error"
                              :danger="!!errors[0]"
                              :danger-text="errors[0]"
                              :disabled="!updateEmployee.isActive"
                            >
                              <vs-select-item
                                v-for="(item, index) in relationship"
                                :key="index"
                                :value="item.id"
                                :text="item.name"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col
                            class="col-inputs-full mt-3 pt-1"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                          >
                            <vs-icon
                              icon="add"
                              color="success"
                              class="cursor-pointer mt-3"
                              @click="newCatalog('relationship')"
                            ></vs-icon>
                          </vs-col>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-4 px-1"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="2"
                      >
                        <vs-button
                          radius
                          color="success"
                          type="flat"
                          icon="add"
                          :disabled="!updateEmployee.isActive"
                          @click.prevent="addContact()"
                        />
                        <vs-button
                          v-if="updateEmployee.contacts_add.length > 1"
                          radius
                          color="danger"
                          type="flat"
                          icon="remove"
                          :disabled="!updateEmployee.isActive"
                          @click.prevent="removeContact(contact.idTemp)"
                        />
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('contact')"
                      >
                        Guardar contactos
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>
            </div>
          </div>
        </vs-col>
      </vs-tab>
      <vs-tab label="Documentos">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-xs="12"
        >
          <vs-card class="pt-3">
            <div slot="header">
              <h2>Documentos</h2>
            </div>
            <vs-row class="ml-1">
              <vs-col vs-type="flex" vs-align="center" vs-w="12">
                <vs-row>
                  <vs-col
                    v-for="(doc, key) in updateEmployee.documents"
                    :key="key"
                    vs-w="3"
                    class="px-2"
                  >
                    <vs-col>
                      <p class="mt-1 mb-7 text-bold" vs-align="center">
                        {{ doc.name }}
                      </p>
                    </vs-col>
                    <vs-col
                      class="px-1 col-inputs-full flex-column"
                      style="min-height: 50px;"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="flex-start"
                      vs-w="12"
                    >
                      <span class="justify-content-center">
                        Archivo Actual:
                        <a
                          v-if="doc.document"
                          :href="doc.document"
                          target="_blank"
                          class="font-semibold overflow"
                        >
                          {{ doc.document | filename }}
                        </a>
                      </span>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="center">
                      <vs-upload
                        v-if="updateEmployee.isActive"
                        :ref="doc.type"
                        accept="application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :limit="1"
                        :show-upload-button="false"
                        text="Adjuntar Documento"
                        @change="uploadDoc(doc, false)"
                      />
                    </vs-col>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
        <vs-col>
          <vs-card class="pt-3">
            <div slot="header">
              <vs-row>
                <h2>Cursos y Capacitaciones</h2>
                <vs-button
                  v-if="updateEmployee.isActive"
                  radius
                  color="success"
                  type="flat"
                  icon="add"
                  @click.prevent="addCourse()"
                />
              </vs-row>
            </div>
            <vs-row :ref="idCourse" class="ml-1">
              <vs-col vs-type="flex" vs-align="center" vs-w="12">
                <vs-row>
                  <vs-col
                    v-for="(course, key) in courses"
                    :key="key"
                    vs-w="3"
                    class="px-2"
                  >
                    <vs-col>
                      <vs-row>
                        <p class="mt-1 mb-7 text-bold" vs-align="center">
                          Curso o Capacitacion
                        </p>
                      </vs-row>
                    </vs-col>
                    <vs-col
                      class="px-1 col-inputs-full flex-column"
                      style="min-height: 50px;"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="flex-start"
                      vs-w="12"
                    >
                      <span class="justify-content-center">
                        Archivo Actual:
                        <a
                          v-if="course.document"
                          :href="course.document"
                          target="_blank"
                          class="font-semibold overflow"
                        >
                          {{ course.document | filename }}
                        </a>
                      </span>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="center">
                      <vs-upload
                        v-if="updateEmployee.isActive"
                        :ref="'Course' + course.idTemp"
                        accept="application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :limit="1"
                        :show-upload-button="false"
                        text="Adjuntar Documento"
                        @change="uploadDoc(course, true)"
                      />
                    </vs-col>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-tab>
      <div>
        <vs-tab label="Baja">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-xs="12"
          >
            <vs-card class="pt-3">
              <div slot="header">
                <h2>Datos baja</h2>
              </div>
              <vs-row class="ml-1">
                <vs-col vs-type="flex" vs-align="center" vs-w="12">
                  <vs-row>
                    <vs-col
                      class="mt-2 px-1 col-inputs-full"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="6"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Fecha baja"
                        rules="required"
                      >
                        <div
                          class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          :danger="!!errors[0]"
                          :danger-text="errors[0]"
                        >
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <date-picker
                              v-model="updateEmployee.endDate"
                              class="full-width"
                              placeholder="Fecha baja"
                              value-type="format"
                              format="YYYY-MM-DD"
                              :disabled="!updateEmployee.isActive"
                              :open.sync="openDatepicker"
                            >
                              <template v-slot:footer>
                                <button
                                  class="mx-btn mx-btn-text mx-2"
                                  @click="openDatepicker = false"
                                >
                                  Listo
                                </button>
                              </template>
                            </date-picker>
                            <span
                              class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                              style="margin-top: -28px;"
                            >
                              Fecha baja
                            </span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </vs-col>
                    <vs-col
                      class="mt-2 px-1 col-inputs-full"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="6"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Motivo renuncia"
                        rules="required"
                      >
                        <vs-col
                          class="col-inputs-full mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-w="10"
                        >
                          <vs-select
                            v-model="updateEmployee.reasonForWithdrawal"
                            autocomplete
                            class="block col-inputs-full"
                            label="Motivo renuncia"
                            placeholder="Elija motivo renuncia"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                            :disabled="!updateEmployee.isActive"
                          >
                            <vs-select-item
                              v-for="(item,
                              indexReason) in reasonForWithdrawalCatalog"
                              :key="indexReason"
                              :value="item.id"
                              :text="item.reason"
                            />
                          </vs-select>
                        </vs-col>
                        <vs-col
                          class="col-inputs-full mt-3 pt-1"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-w="2"
                        >
                          <vs-icon
                            icon="add"
                            color="success"
                            class="cursor-pointer mt-3"
                            @click="newCatalog('reasonForWithdrawal')"
                          ></vs-icon>
                        </vs-col>
                      </ValidationProvider>
                    </vs-col>
                    <vs-col
                      class="mt-2 px-1 col-inputs-full"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="12"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Motivo"
                        rules="required"
                      >
                        <vs-textarea
                          v-model="updateEmployee.reasonEnd"
                          label-placeholder="Motivo"
                          val-icon-danger="error"
                          maxlength="1000"
                          rows="4"
                          :class="{
                            'input-icon-validate-danger': errors.length > 0,
                          }"
                          :disabled="!updateEmployee.isActive"
                        >
                        </vs-textarea>
                      </ValidationProvider>
                    </vs-col>
                    <vs-col
                      class="mt-2 px-1 col-inputs-full"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="4"
                    >
                    </vs-col>
                    <vs-col
                      class="mt-2 px-1 col-inputs-full"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="4"
                    >
                      <span class="justify-content-center">
                        Archivo Actual:
                        <a
                          v-if="fileEnd"
                          :href="fileEnd"
                          target="_blank"
                          class="font-semibold overflow"
                        >
                          {{ fileEnd | filename }}
                        </a>
                      </span>
                    </vs-col>
                    <vs-col
                      class="mt-2 px-1 col-inputs-full"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="4"
                    >
                    </vs-col>
                  </vs-row>
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-tab>
      </div>
    </vs-tabs>
    <vue-fab
      size="big"
      icon="save"
      main-btn-color="#388e3c"
      :scroll-auto-hide="false"
      @clickMainBtn="onClickUpdateEmployee"
    >
    </vue-fab>
    <vs-prompt
      color="primary"
      :active.sync="dialog"
      title="Recortar Imagen"
      accept-text="Aceptar"
      cancel-text="Cerrar"
      @accept="saveImageOne"
      @close="cancelImage"
      @cancel="cancelImage"
    >
      <div class="modal-venta">
        <vueCropper
          v-show="selectedFile"
          ref="cropperPhoto"
          :src="selectedFile"
          :crop-box-resizable="true"
          :aspect-ratio="1"
          alt="Source Image"
        ></vueCropper>
      </div>
    </vs-prompt>
    <ValidationObserver ref="observerNewCatalog" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="newCatalogPopUp"
        :title="title"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        @accept="saveNewCatalog"
      >
        <div>
          <vs-row>
            <vs-col class="mx-1 mt-1" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
                tag="div"
                class="mt-2"
              >
                <vs-input
                  v-model="nameNewCatalog"
                  label-placeholder="Nombre"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </vs-row>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

const { DateTime } = require('luxon')
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import PictureInput from '@/components/PictureInput'
import VueCropper from 'vue-cropperjs'

//import Compressor from 'compressorjs'
import utils from '@/utils/utils'
import { extend } from 'vee-validate'

const _ = require('lodash')
import { employeeDocuments } from '@/models/employeeDocuments'

extend('decimal', {
  validate(value) {
    let regexp = /^\d+(\.\d{1,2})?$/
    return regexp.test(value)
  },
  message: 'Debe ser un valor decimal',
})
extend('curp', {
  validate(value) {
    let regexp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
    return regexp.test(value)
  },
  message: 'Debe ser un curp valido',
})
extend('rfc', {
  validate(value) {
    let regexp = /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    return regexp.test(value)
  },
  message: 'Debe ser un rfc valido',
})
export default {
  name: 'UpdateEmployeeView',
  components: {
    'date-picker': DatePicker,
    'picture-input': PictureInput,
    vueCropper: VueCropper,
  },
  data: function () {
    return {
      idEmployee: 0,
      isLoading: false,
      openDatepicker: false,
      openStartDatepicker: false,
      payrollPosition: [],
      areaCatalog: [],
      relationship: [],
      perfilImgData: {
        id: null,
        photo: null,
        photoCompress: null,
      },
      nombre: 'x',
      appat: 'y',
      apmat: 'z',
      updateEmployeeFormsProgress: {
        employeeData: false,
        job: false,
        assignment: false,
        contact: false,
      },
      contactosData: 0,
      contactAddId: 0,
      updateEmployeeCon: {
        contacts_add: [
          {
            relationship: null,
            name: null,
            phoneNumber: null,
          },
        ],
      },
      dialog: false,
      selectedFile: '',
      image: null,
      idImage: null,
      courses: [],
      idCourse: 0,
      cropedImage: null,
      showImage: false,
      keyImage: 0,
      fileEnd: '',
      newCatalogPopUp: false,
      title: '',
      typeNewCatalog: '',
      nameNewCatalog: '',
    }
  },
  computed: {
    ...mapState('employee', {
      updateEmployee: 'updateEmployee',
      newEmployeeFormsProgress: 'newEmployeeFormsProgress',
    }),
    ...mapState('company', { company: 'company' }),
    ...mapGetters('employee', {
      finalUpdateEmployee: 'finalUpdateEmployee',
      getUpdateEmployeeErrors: 'getUpdateEmployeeErrors',
    }),
  },
  watch: {
    isLoading: function () {
      if (this.isLoading) {
        this.$vs.loading({
          type: 'radius',
        })
      } else {
        this.$vs.loading.close()
      }
    },
  },
  created() {
    this.getAreaCatalog('is_active=True').then((response) => {
      this.areaCatalog = response
    })
    this.getPayrollPositionCatalog('is_active=True').then((response) => {
      this.payrollPosition = response
    })
    this.getRelationshipCatalog('is_active=True').then((response) => {
      this.relationship = response
    })
    this.getReasonForWithdrawalCatalog('is_active=True').then((response) => {
      this.reasonForWithdrawalCatalog = response
    })
  },
  mounted() {
    if (
      document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0] != undefined
    ) {
      let element = document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0]
      element.parentNode.removeChild(element)
    }
    this.idEmployee = this.$route.params.id
    this.getEmployeeByIdAction(this.idEmployee)
      .then((res) => {
        this.setUpdateEmployee(res)
        this.image = res.photoInfo.photo
        this.cropedImage = res.photoInfo.photo
        this.idImage = res.photoInfo.id
        if (
          this.updateEmployee.contacts_add != null &&
          this.updateEmployee.contacts_add != undefined
        ) {
          this.contactAddId = this.updateEmployee.contacts_add.length
          if (this.updateEmployee.contacts_add.length > 0) {
            for (
              let step = 0;
              step < this.updateEmployee.contacts_add.length;
              step++
            ) {
              this.updateEmployee.contacts_add[step].idTemp = step
            }
          }
        }
        this.initializeDoc()
      })
      .catch((err) => {
        console.error(err)
        this.$vs.notify({
          title: 'Opps...',
          text: 'Empleado no encontrado',
        })
        this.$router.push({ name: 'employeeList' })
      })
  },
  methods: {
    ...mapActions('catalogs', {
      getAreaCatalog: 'getAreaCatalog',
      getPayrollPositionCatalog: 'getPayrollPositionCatalog',
      getRelationshipCatalog: 'getRelationshipCatalog',
      getReasonForWithdrawalCatalog: 'getReasonForWithdrawalCatalog',
      newItemCatalog: 'newItemCatalog',
    }),
    ...mapActions('candidates', {
      getCandidateById: 'getCandidateById',
    }),
    ...mapActions('employee', {
      updateEmployeeAction: 'updateEmployeeAction',
      getEmployeeByIdAction: 'getEmployeeByIdAction',
      createEmployeePhotosAction: 'createEmployeePhotosAction',
      saveDocumentAction: 'saveDocumentAction',
    }),
    ...mapMutations('employee', {
      clearUpdateEmployeeData: 'clearUpdateEmployeeData',
      setUpdateEmployee: 'setUpdateEmployee',
    }),
    initializeDoc() {
      const documents = _.cloneDeep(employeeDocuments)
      this.updateEmployee.documents.forEach((loadDoc) => {
        if (loadDoc.documentType === 'CURSOS') {
          loadDoc.type = loadDoc.documentType
          loadDoc.idTemp = this.idCourse
          this.idCourse = this.idCourse + 1
          this.courses.push(loadDoc)
        } else {
          if (loadDoc.documentType === 'BAJA') {
            this.fileEnd = loadDoc.document
          } else {
            documents.forEach((docItem) => {
              if (loadDoc.documentType === docItem.type) {
                loadDoc.name = docItem.name
                loadDoc.order = docItem.order
                loadDoc.type = docItem.type
              }
            })
          }
        }
      })
      this.updateEmployee.documents = this.updateEmployee.documents.filter(
        (x) => {
          return x.documentType !== 'CURSOS'
        }
      )
      documents.forEach((docItem) => {
        this.updateEmployee.documents.forEach((load) => {
          if (load.documentType === docItem.type) {
            docItem.document = load.document
            docItem.id = load.id
          }
        })
      })
      this.updateEmployee.documents = documents
    },
    uploadDoc(doc, isCourse) {
      let docRef = ''
      if (isCourse) {
        docRef = 'Course' + doc.idTemp
      } else {
        docRef = doc.type
      }
      let formData = new FormData()
      let arrayLength = this.$refs[docRef][0].filesx.length - 1
      let file = this.$refs[docRef][0].filesx[arrayLength]
      formData.append('document', file, file.name)
      formData.append('documentType', doc.type)
      this.$vs.loading({
        text: 'Almacenando archivo ...',
      })
      this.saveDocumentAction(formData).then((res) => {
        this.$vs.loading.close()
        if (isCourse) {
          this.courses.forEach((item) => {
            if (item.id === doc.id) {
              item.id = res.id
              item.document = res.document
            }
          })
        } else {
          this.updateEmployee.documents.forEach((document) => {
            if (document.type === doc.type) {
              document.id = res.id
              document.document = res.document
            }
          })
        }
      })
    },
    addCourse() {
      this.courses.push({
        type: 'CURSOS',
        document: null,
        idTemp: this.idCourse,
      })
      this.idCourse = this.idCourse + 1
    },
    goToEmployeesPage() {
      this.clearUpdateEmployeeData()
      this.$router.push({
        name: 'employeeList',
      })
    },
    onClickUpdateEmployee() {
      if (!this.updateEmployee.isActive) {
        this.$vs.notify({
          title:
            'No se pueden modificar los datos de un empleado dado de baja.',
          color: 'danger',
          fixed: true,
          position: 'top-right',
        })
        this.isLoading = false
        this.$vs.loading.close()
        return
      }

      this.isLoading = true
      this.updateEmployee.isActive = this.updateEmployee.lowDate == null
      this.updateEmployee.photo = this.idImage
      this.updateEmployee.entryTime = DateTime.fromISO(
        this.updateEmployee.entryTime
      ).toFormat('T')
      this.updateEmployee.exitTime = DateTime.fromISO(
        this.updateEmployee.exitTime
      ).toFormat('T')
      let documentsIds = []
      this.updateEmployee.documents.forEach((doc) => {
        if (doc.id != null) {
          documentsIds.push(doc.id)
        }
      })
      this.courses.forEach((item) => {
        if (item.id != null) {
          documentsIds.push(item.id)
        }
      })
      let contactsValid = true
      this.updateEmployee.contacts_add.forEach((contact) => {
        if (contact.name === '' || contact.name.length <= 2) {
          contactsValid = false
        }
        if (contact.phoneNumber === '' || contact.phoneNumber.length <= 9) {
          contactsValid = false
        }
        if (contact.relationship === '' || contact.relationship.length <= 0) {
          contactsValid = false
        }
      })
      if (!contactsValid) {
        this.isLoading = false
        this.$vs.notify({
          title:
            'Revise los campos señalados, guárdelos y reintente nuevamente.',
          text: `Revise los datos de contacto`,
          color: 'danger',
          fixed: true,
          position: 'top-right',
        })
        return
      }
      this.updateEmployee.isActive = this.updateEmployee.endDate == null
      this.updateEmployee.documentsAdd = documentsIds
      this.$vs.loading({
        text: 'Actualizando empleado...',
      })
      this.updateEmployeeAction({
        data: this.updateEmployee,
        id: this.idEmployee,
      })
        .then(() => {
          this.isLoading = false
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Empleado Actualizado Exitosamente',
          })
          this.$vs.loading.close()
          this.goToEmployeesPage()
        })
        .catch((error) => {
          this.isLoading = false
          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title:
              'Revise los campos señalados, guárdelos y reintente nuevamente.',
            text: `${res}`,
            color: 'danger',
            fixed: true,
            position: 'top-right',
          })
        })
    },
    onChangeImgOne() {
      if (this.$refs.uploadMainPhoto.image) {
        const file = this.$refs.uploadMainPhoto.file
        this.mime_type = file.type
        this.dialog = true
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.selectedFile = event.target.result
            this.$refs.cropperPhoto.replace(this.selectedFile)
            const data = {
              width: 400,
              height: 400,
            }
            this.$refs.cropperPhoto.setCropBoxData(data)
          }
          reader.readAsDataURL(file)
        } else {
          alert('Sorry, FileReader API not supported')
        }
      }
    },
    cancelImage() {
      this.keyImage++
    },
    saveImageOne() {
      let formData = new FormData()
      let self = this
      this.cropedImage = this.$refs.cropperPhoto.getCroppedCanvas().toDataURL()
      this.$refs.cropperPhoto.getCroppedCanvas().toBlob(
        (blob) => {
          let file = new File([blob], 'photo.webp')
          formData.append('photo', file, 'photo.webp')
          self.image = new File([blob], 'temp.webp')
          self
            .createEmployeePhotosAction(formData)
            .then((res) => {
              self.idImage = res.id
              self.showImage = false
            })
            .catch((err) => {
              self.$vs.notify({
                title: 'Error al cargar la imagen',
                text: 'Asegurese de adjuntar una imagen válida.',
                position: 'top-right',
                color: 'danger',
              })
              throw new Error(err.message)
            })
        },
        'image/webp',
        0.8
      )
    },
    onClickSaveAndContinue() {},
    addContact() {
      this.updateEmployee.contacts_add.push({
        idTemp: this.contactAddId,
        name: '',
        relationship: '',
        phone: '',
      })
      this.contactAddId = this.contactAddId + 1
      this.contactosData = this.contactosData + 1
    },
    removeContact(key) {
      this.updateEmployee.contacts_add = this.updateEmployee.contacts_add.filter(
        (x) => {
          return x.idTemp !== key
        }
      )
      this.contactosData = this.contactosData + 1
    },
    validaMostrarBaja() {
      if (!this.updateEmployee.isActive) {
        return true
      } else {
        false
      }
    },
    newCatalog(type) {
      if (type == 'payrollPosition') {
        this.title = 'Nuevo puesto'
      }
      if (type == 'area') {
        this.title = 'Nueva area'
      }
      if (type == 'relationship') {
        this.title = 'Nuevo parentesco'
      }
      if (type == 'reasonForWithdrawal') {
        this.title = 'Nueva razón de renuncia'
      }
      this.nameNewCatalog = ''
      this.newCatalogPopUp = true
      this.typeNewCatalog = type
    },
    saveNewCatalog() {
      if (this.typeNewCatalog == 'payrollPosition') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'payroll-position',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getPayrollPositionCatalog().then((response) => {
              this.$vs.loading.close()
              this.payrollPosition = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'area') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'area',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getAreaCatalog().then((response) => {
              this.$vs.loading.close()
              this.areaCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'relationship') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'relationship',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getRelationshipCatalog().then((response) => {
              this.$vs.loading.close()
              this.relationship = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'reasonForWithdrawal') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'withdrawal-reason',
          data: {
            reason: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getReasonForWithdrawalCatalog().then((response) => {
              this.$vs.loading.close()
              this.reasonForWithdrawalCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
.mx-datepicker-main {
  z-index: 10002;
}

.update-candidate-container {
  margin: 0 auto;
}

.block {
  width: 100% !important;
}

.candidate-cards-container {
  overflow-y: auto;
}

.vs-input-number .vs-input-number--input {
  width: auto !important;
}

.vs-tabs--content {
  min-height: 700px;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-view-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  border-radius: 5%;
  object-fit: contain;
}

.preview-images {
  max-height: 150px;
  width: 300px;
  background-size: contain;
}
</style>
