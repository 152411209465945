<template>
  <div class="sidebar-candidates-container">
    <vs-sidebar
      static-position
      default-index="1"
      color="primary"
      :hidden-background="true"
    >
      <div slot="header" class="header-sidebar">
        <h3 class="mb-2">
          Filtrar Empleados
        </h3>

        <template v-if="company.picture === '#' || company.picture === null">
          <img class="logo" src="@/assets/images/company.png" alt="avatar1" />
        </template>
        <img v-else class="logo" :src="company.picture" alt="avatar2" />
      </div>

      <vs-row class="px-3">
        <vs-col vs-w="12">
          <vs-row class="px-3 mb-3">
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                v-model="searchParams"
                class="input-search"
                label="Nombre y/o Apellidos"
                @keypress.enter="searchEmployees"
              />
            </vs-col>
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                v-model="payroll"
                type="number"
                class="input-search"
                label="No Nomina"
                @keypress.enter="searchEmployees"
              />
            </vs-col>
            <!-- Estatus  -->
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-3"
            >
              <strong>Filtrar por Estatus</strong>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-1"
            >
              <vs-radio
                v-model="status"
                class="mr-2"
                vs-name="status"
                vs-value="act"
              >
                Activo
              </vs-radio>
              <vs-radio
                v-model="status"
                class="mr-2"
                vs-name="status"
                vs-value="ina"
              >
                Inactivo
              </vs-radio>
              <vs-radio v-model="status" vs-name="status" vs-value="tod">
                Todos
              </vs-radio>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <div slot="footer" class="footer-sidebar">
        <vs-row class="px-1 mb-3">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              class="pl-2 pr-3 ml-1 btn-block"
              size="small"
              color="primary"
              type="border"
              icon="search"
              :icon-after="false"
              @click="searchEmployees"
              >Buscar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'FilterSideBar',
  data: function () {
    return {
      searchParams: '',
      status: 'act',
      payroll: '',
    }
  },
  computed: { ...mapState('company', { company: 'company' }) },
  created() {},
  methods: {
    ...mapActions('employee', {
      getEmployeesListAction: 'getEmployeesListAction',
    }),
    ...mapMutations('employee', {
      setTotalEmployeePages: 'setTotalEmployeePages',
      setEmployeesList: 'setEmployeesList',
      setSkeletonLoading: 'setSkeletonLoading',
      setSearchParams: 'setSearchParams',
    }),
    formatEmployeeList(employeesList) {
      const pages = Math.ceil(employeesList.count / employeesList.pageSize)
      this.setTotalEmployeePages(pages)
      let employeesListFormated = employeesList.results.map((item) => {
        item['id'] = item['id'].toString()
        return item
      })
      this.setEmployeesList(employeesListFormated)
      this.setSkeletonLoading(false)
    },
    searchEmployees() {
      this.setSkeletonLoading(true)

      let query = `search=${this.searchParams}`

      if (this.status == 'act') {
        query += '&is_active=True'
      } else if (this.status == 'ina') {
        query += '&is_active=False'
      }

      if (this.payroll !== '') {
        query += `&payroll_number=${this.payroll}`
      }

      this.setSearchParams(query)

      this.getEmployeesListAction(query)
        .then((res) => {
          this.formatEmployeeList(res)
        })
        .catch()
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
@import '~@/scss/mixins/_filter-sidebar';

.vs-sidebar {
  max-width: 330px !important;
}
.sidebar-candidates-container {
  overflow: hidden;
  height: calc(100vh - #{$navbar-height});
  position: relative;
  z-index: 0;

  .vs-sidebar.vs-sidebar-parent.vs-sidebar-staticPosition {
    z-index: 10000;
  }
}

.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.vs-sidebar.vs-sidebar-primary.vs-sidebar-parent.vs-sidebar-staticPosition {
  height: 100vh !important;
  @include vs-sidebar--items;
}

.con-vs-slider {
  min-width: 175px !important;

  @include respond-to('small') {
    min-width: 200px !important;
  }
  @include respond-to('medium') {
    min-width: 230px !important;
  }
  @include respond-to('large') {
    min-width: 240px !important;
  }
  @include respond-to('xlarge') {
    min-width: 260px !important;
  }
}
.vs-sidebar {
  background-color: $bg-blue-gray !important;
  .logo {
    @include vs-sidebar--logo;
  }
}

.input-search .vs-con-input .vs-icon.vs-input--icon {
  cursor: pointer !important;
  transition: all ease 0.3s;
  background: $primary;
  color: $bg-blue-gray !important;
  padding: 8px;
  padding-top: 8px;
  margin-top: -8px;
  margin-right: -5px;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background: rgba($primary, 0.7);
  }
}

.vc-popover-content-wrapper[data-v-88de91da] {
  z-index: 999 !important;
}
</style>
