<template>
  <div class="sidebar-candidates-container">
    <vs-sidebar
      static-position
      default-index="1"
      color="primary"
      :hidden-background="true"
    >
      <div slot="header" class="header-sidebar">
        <h3 class="mb-2">Filtrar Asistencia</h3>

        <template v-if="company.picture === '#' || company.picture === null">
          <img class="logo" src="@/assets/images/company.png" alt="avatar1" />
        </template>
        <img v-else class="logo" :src="company.picture" alt="avatar2" />
      </div>

      <vs-row class="px-3">
        <vs-col class="mt-1 col-inputs-full" vs-type="flex" vs-w="12">
          <label class="vs-input--label">Fecha Inicial</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-picker
            v-model="initialDate"
            class="mt-1 full-width"
            placeholder="Fecha Inicial"
            value-type="format"
            format="YYYY-MM-DD"
            :open.sync="openDatepicker"
            :disabled-date="_BeforeToday"
          >
            <template v-slot:footer>
              <button
                class="mx-btn mx-btn-text mx-2"
                @click="openDatepicker = false"
              >
                Listo
              </button>
            </template>
          </date-picker>
        </vs-col>
        <vs-col class="mt-3 col-inputs-full" vs-type="flex" vs-w="12">
          <label class="vs-input--label">Fecha Final</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-picker
            v-model="endDate"
            class="mt-1 mb-3 full-width"
            placeholder="Fecha Final"
            value-type="format"
            format="YYYY-MM-DD"
            :open.sync="openDatepickerFin"
            :disabled-date="_BeforeToday"
          >
            <template v-slot:footer>
              <button
                class="mx-btn mx-btn-text mx-2"
                @click="openDatepickerFin = false"
              >
                Listo
              </button>
            </template>
          </date-picker>
        </vs-col>
      </vs-row>
      <div slot="footer" class="footer-sidebar">
        <vs-row class="px-1 mb-3">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              class="pl-2 pr-3 ml-1 btn-block"
              size="small"
              color="primary"
              type="border"
              icon="search"
              :icon-after="false"
              @click="searchAssitance"
              >Buscar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'FilterSideBar',
  data: function () {
    return {
      initialDate: null,
      openDatepicker: false,
      endDate: null,
      openDatepickerFin: false,
    }
  },
  computed: {
    ...mapState('company', { company: 'company' }),
  },
  created() {
    let diaS = this.getDay()
    this.initialDate = diaS
    this.endDate = diaS
  },
  methods: {
    ...mapMutations('assistance', {
      setSearchParams: 'setSearchParams',
    }),
    _BeforeToday(date) {
      return utils.BeforeToday(date)
    },
    searchAssitance() {
      let searchParams = ''
      if (this.initialDate !== null && this.initialDate !== '') {
        searchParams = searchParams + `&Idate=${this.initialDate}`
        if (this.endDate !== null && this.endDate !== '') {
          searchParams = searchParams + `&Fdate=${this.endDate}`
        } else {
          let finalDate = this.addOneDay(this.initialDate)
          searchParams = searchParams + `&Fdate=${finalDate}`
        }
      }
      this.setSearchParams(searchParams)
    },
    getDay() {
      return utils.getActualDate()
    },
    addOneDay(dayf) {
      let dia = new Date(dayf)
      dia.setDate(dia.getDate() + 2)
      let diaS = dia.getFullYear() + '-'
      if (dia.getMonth() > 8) {
        diaS = diaS + (dia.getMonth() + 1)
      } else {
        diaS = diaS + '0' + (dia.getMonth() + 1)
      }
      if (dia.getDate() > 9) {
        diaS = diaS + '-' + dia.getDate()
      } else {
        diaS = diaS + '-0' + dia.getDate()
      }
      return diaS
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
@import '~@/scss/mixins/_filter-sidebar';

.vs-sidebar {
  max-width: 350px !important;
  .logo {
    @include vs-sidebar--logo;
  }
}

.sidebar-candidates-container {
  overflow: hidden;
  height: calc(100vh - #{$navbar-height});
  position: relative;
  z-index: 0;

  .vs-sidebar.vs-sidebar-parent.vs-sidebar-staticPosition {
    z-index: 10000;
  }
}

.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.vs-sidebar.vs-sidebar-primary.vs-sidebar-parent.vs-sidebar-staticPosition {
  height: 100vh !important;
  @include vs-sidebar--items;
}

.con-vs-slider {
  min-width: 175px !important;

  @include respond-to('small') {
    min-width: 200px !important;
  }
  @include respond-to('medium') {
    min-width: 230px !important;
  }
  @include respond-to('large') {
    min-width: 240px !important;
  }
  @include respond-to('xlarge') {
    min-width: 260px !important;
  }
}
.vs-sidebar {
  background-color: $bg-blue-gray !important;
}

.input-search .vs-con-input .vs-icon.vs-input--icon {
  cursor: pointer !important;
  transition: all ease 0.3s;
  background: $primary;
  color: $bg-blue-gray !important;
  padding: 8px;
  padding-top: 8px;
  margin-top: -8px;
  margin-right: -5px;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background: rgba($primary, 0.7);
  }
}

.vc-popover-content-wrapper[data-v-88de91da] {
  z-index: 999 !important;
}
</style>
