<template>
  <div class="candidate-info-tabs">
    <div class="candidate-cards-container px-3">
      <!-- Datos personales-->
      <ValidationObserver ref="observerDatosCandidato" v-slot="{ invalid }">
        <vs-card class="pt-3 px-1">
          <div slot="header">
            <h2>
              Datos del candidato
            </h2>
          </div>
          <div>
            <vs-row class="new-candidate-container mb-4">
              <vs-alert
                v-for="(err, key) in getNewCandidateErrors.generalInformation
                  .candidateData"
                :key="key"
                class="mt-1"
                color="danger"
                icon="new_releases"
              >
                <span>{{ `${err.name}: ${err.msg}` }}</span>
              </vs-alert>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nombre(s)"
                  rules="required"
                >
                  <vs-input
                    v-model="newCandidate.name"
                    label-placeholder="Nombre(s)"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Apellido Paterno"
                  rules="required"
                >
                  <vs-input
                    v-model="newCandidate.lastName"
                    label-placeholder="Apellido Paterno"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <vs-input
                  v-model="newCandidate.secondLastName"
                  label-placeholder="Apellido Materno"
                  val-icon-danger="error"
                />
              </vs-col>
              <!-- Género -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Género"
                  rules="required"
                >
                  <vs-select
                    v-model="newCandidate.gender"
                    autocomplete
                    class="block col-inputs-full"
                    label="Género"
                    placeholder="Elija un Género"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  >
                    <vs-select-item
                      v-for="(item, index) in genderCatalog"
                      :key="index"
                      :value="item.id"
                      :text="item.name"
                    />
                  </vs-select>
                </ValidationProvider>
              </vs-col>
              <!-- Estatus laboral -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="expMin"
                  name="Expectativa salarial minima"
                  rules="required|numeric|salaryMin:@expMax"
                >
                  <vs-input
                    v-model="newCandidate.minimumSalaryExpectation"
                    icon="remove"
                    label-placeholder="Expectativa salarial mínima"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="expMax"
                  name="Expectativa salarial maxima"
                  rules="required|numeric|salaryMax:@expMin"
                >
                  <vs-input
                    v-model="newCandidate.maximumSalaryExpectation"
                    icon="add"
                    label-placeholder="Expectativa salarial máxima"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Estatus laboral"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="newCandidate.employmentStatusId"
                      autocomplete
                      class="block col-inputs-full"
                      label="Estatus laboral"
                      placeholder="Elija un estatus laboral"
                      val-icon-danger="error"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, index) in employmentStatusCatalog"
                        :key="index"
                        :value="item.id"
                        :text="item.status"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('employmentStatus')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <!-- Puesto al que aplica -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Puesto al que aplica"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="newCandidate.jobVacancyId"
                      autocomplete
                      class="block col-inputs-full"
                      label="Puesto al que aplica"
                      placeholder="Elija un puesto laboral"
                      val-icon-danger="error"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, index) in jobVacancyCatalog"
                        :key="index"
                        :value="item.id"
                        :text="item.name"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('jobVacancy')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <!-- Disponibilidad para iniciar -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Disponibilidad para iniciar"
                  rules="required"
                >
                  <label class="vs-select--label"
                    >Disponibilidad para iniciar</label
                  >
                  <date-picker
                    v-model="newCandidate.availabilityToStart"
                    class="date-size"
                    type="text"
                    :default-value="dateValidate"
                    :min-date="tomorrowDate"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :editable="false"
                    append-to-body
                    input-class="vs-inputx vs-input--input normal hasValue"
                    placeholder="Ingrese una fecha (Obligatorio)"
                    confirm-text="Listo"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                    @dayclick="availabilityToStartDPClicked"
                  >
                  </date-picker>
                </ValidationProvider>
              </vs-col>
              <!-- Fecha de nacimiento -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Fecha de nacimiento"
                  rules="required"
                >
                  <label class="vs-select--label">Fecha de nacimiento</label>
                  <date-picker
                    v-model="newCandidate.dateOfBirth"
                    class="date-size"
                    type="text"
                    :default-value="dateValidate"
                    :max-date="maxBirthDate"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :editable="false"
                    append-to-body
                    input-class="vs-inputx vs-input--input normal hasValue"
                    placeholder="Ingrese una fecha (Obligatorio)"
                    confirm-text="Listo"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                    @dayclick="dateOfBirthDPClicked"
                  >
                  </date-picker>
                </ValidationProvider>
              </vs-col>
              <!-- Nacionalidad -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nacionalidad"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="newCandidate.nationalityId"
                      autocomplete
                      class="block col-inputs-full"
                      label="Nacionalidad"
                      placeholder="Elija una nacionalidad"
                      val-icon-danger="error"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, index) in nationalityCatalog"
                        :key="index"
                        :value="item.id"
                        :text="item.name"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('nationality')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <!-- Medio de reclutamiento -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Medio de reclutamiento"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="newCandidate.recruitingMediumId"
                      autocomplete
                      class="block col-inputs-full"
                      label="Medio de reclutamiento"
                      placeholder="Elija un medio de reclutamiento"
                      val-icon-danger="error"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, index) in recruitingMediumCatalog"
                        :key="index"
                        :value="item.id"
                        :text="item.name"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('recruitingMedium')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-button
                color="primary"
                icon="turned_in_not"
                :disabled="invalid"
                @click="
                  onClickSaveAndContinue('generalInformation', 'candidateData')
                "
              >
                Guardar datos personales
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </ValidationObserver>

      <!-- Prestaciones -->
      <ValidationObserver ref="observerPrestaciones" v-slot="{ invalid }">
        <vs-card
          v-if="newCandidateFormsProgress.generalInformation.candidateData"
          class="pt-3 px-1"
        >
          <div slot="header">
            <h2>
              Prestaciones
            </h2>
          </div>
          <div>
            <vs-row class="new-candidate-container mb-4">
              <vs-col
                v-for="item in benefitsCatalog"
                :key="item.id"
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              >
                <label class="px-3">{{ item.name }}</label>
                <vs-switch
                  v-model="item.status"
                  color="success"
                  vs-icon="done"
                  @change="onChangeBenefit"
                  @blur="onChangeBenefit"
                />
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-button
                color="primary"
                icon="turned_in_not"
                :disabled="invalid"
                @click="
                  onClickSaveAndContinue('generalInformation', 'benefits')
                "
              >
                Guardar prestaciones
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </ValidationObserver>

      <!-- Contacto -->
      <ValidationObserver ref="observerContacto" v-slot="{ invalid }">
        <vs-card
          v-if="newCandidateFormsProgress.generalInformation.benefits"
          class="pt-3 px-1"
        >
          <div slot="header">
            <h2>
              Contacto
            </h2>
          </div>
          <div>
            <h3 class="mt-3">Correos de contacto</h3>
            <vs-row
              v-for="emailContact in newCandidate.emailsContactAdd"
              :key="emailContact.id"
              class="new-candidate-container mb-4"
            >
              <vs-alert
                v-for="(err, key) in getNewCandidateErrors.generalInformation
                  .contact"
                :key="key"
                class="mt-1"
                color="danger"
                icon="new_releases"
              >
                <span>{{ `${err.name}: ${err.msg}` }}</span>
              </vs-alert>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="10"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Email de contacto"
                  rules="required|email"
                >
                  <vs-input
                    v-model="emailContact.email"
                    label="Correo"
                    label-placeholder="Correo de contacto"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-4 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
              >
                <vs-button
                  radius
                  color="success"
                  type="flat"
                  icon="add"
                  @click.prevent="addEmailContacto"
                />
                <vs-button
                  radius
                  :disabled="
                    newCandidate.emailsContactAdd[0].id === emailContact.id
                  "
                  color="danger"
                  type="flat"
                  icon="remove"
                  @click.prevent="removerEmailContacto(emailContact.id)"
                />
              </vs-col>
            </vs-row>
            <h3 class="mt-3">Teléfonos de contacto</h3>
            <vs-row
              v-for="phoneContact in newCandidate.phonesContactAdd"
              :key="phoneContact.id"
              class="new-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="10"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Teléfono de contacto"
                  rules="required|numeric|min:10|max:10"
                >
                  <vs-input
                    v-model="phoneContact.phone"
                    label="Correo"
                    label-placeholder="Teléfono de contacto"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-4 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
              >
                <vs-button
                  radius
                  color="success"
                  type="flat"
                  icon="add"
                  @click.prevent="addTelefonoContacto"
                />
                <vs-button
                  radius
                  :disabled="
                    newCandidate.phonesContactAdd[0].id === phoneContact.id
                  "
                  color="danger"
                  type="flat"
                  icon="remove"
                  @click.prevent="removerTelefonoContacto(phoneContact.id)"
                />
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-button
                color="primary"
                icon="turned_in_not"
                :disabled="invalid"
                @click="onClickSaveAndContinue('generalInformation', 'contact')"
              >
                Guardar contacto
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </ValidationObserver>

      <!-- Dirección -->
      <ValidationObserver ref="observerDireccion" v-slot="{ invalid }">
        <vs-card
          v-if="newCandidateFormsProgress.generalInformation.contact"
          class="pt-3 px-1"
        >
          <div slot="header">
            <h2>
              Dirección
            </h2>
          </div>
          <div>
            <vs-row class="new-candidate-container mb-4">
              <vs-alert
                v-for="(err, key) in getNewCandidateErrors.generalInformation
                  .address"
                :key="key"
                color="danger"
                icon="new_releases"
              >
                <span>{{ err }}</span>
              </vs-alert>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="8"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Calle"
                  rules="required"
                >
                  <vs-input
                    v-model="newCandidate.addressAdd.street"
                    label-placeholder="Calle y número"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <!-- Código postall -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Código Postal"
                  rules="required|numeric|max:5|min:5"
                >
                  <vs-input
                    v-model="newCandidate.addressAdd.postalCodeStr"
                    label-placeholder="Código Postal"
                    type="number"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                    @keyup="onKeyUpPostalCode"
                    @blur="onBlurPostalCode"
                  />
                </ValidationProvider>
              </vs-col>
              <!-- Colonia -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Colonia"
                  rules="required"
                >
                  <vs-select
                    v-model="selectedPostalCode.settlement"
                    autocomplete
                    class="block col-inputs-full"
                    label="Colonia"
                    placeholder="Elija una colonia"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  >
                    <vs-select-item
                      v-for="(item, index) in postalCodesCatalog"
                      :key="index"
                      :value="item.id"
                      :text="item.settlement"
                    />
                  </vs-select>
                </ValidationProvider>
              </vs-col>
              <!-- Municipio -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Municipio"
                  rules="required"
                >
                  <vs-input
                    v-model="selectedPostalCode.municipality"
                    label-placeholder="Alcaldía / Municipio"
                    disabled
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <!-- Ciudad -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Ciudad"
                  rules="required"
                >
                  <vs-input
                    v-model="selectedPostalCode.city"
                    label-placeholder="Ciudad"
                    disabled
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <!-- Estado -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Estado"
                  rules="required"
                >
                  <vs-input
                    v-model="selectedPostalCode.estate"
                    label-placeholder="Estado"
                    disabled
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-button
                color="primary"
                icon="turned_in_not"
                :disabled="invalid"
                @click="onClickSaveAndContinue('generalInformation', 'address')"
              >
                Guardar dirección
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </ValidationObserver>

      <!-- Comentarios -->
      <vs-card
        v-if="newCandidateFormsProgress.generalInformation.address"
        class="pt-3 px-1"
      >
        <div slot="header">
          <h2>
            Comentarios
          </h2>
        </div>
        <div>
          <vs-row class="new-candidate-container mb-4">
            <vs-col
              class="mt-2 px-1"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-textarea
                v-model="newCandidate.generalInformationComments"
                label="Comentarios u Observaciones de esta sección"
              />
            </vs-col>
          </vs-row>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button
              color="primary"
              icon="turned_in_not"
              @click="onClickSaveAndContinue('generalInformation', 'comments')"
            >
              Guardar comentarios
            </vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div>
    <ValidationObserver ref="observerNewCatalog" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="newCatalogPopUp"
        :title="title"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        @accept="saveNewCatalog"
      >
        <div>
          <vs-row>
            <vs-col class="mx-1 mt-1" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
                tag="div"
                class="mt-2"
              >
                <vs-input
                  v-model="nameNewCatalog"
                  label-placeholder="Nombre"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { extend } from 'vee-validate'
import utils from '@/utils/utils'
import moment from 'moment'
const _ = require('lodash')

extend('salaryMax', {
  params: ['expMin'],
  validate(value, values) {
    return value >= parseInt(values['expMin'])
  },
  message: 'Debe ser mayor o igual que la expectativa salarial mínima ',
})
extend('salaryMin', {
  params: ['expMax'],
  validate(value, values) {
    return value <= parseInt(values['expMax'])
  },
  message: 'Debe ser menor o igual que la expectativa salarial máxima ',
})

export default {
  name: 'NewCandidateForm',
  props: {
    newCandidate: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: function () {
    return {
      employmentStatusCatalog: [],
      jobVacancyCatalog: [],
      recruitingMediumCatalog: [],
      nationalityCatalog: [],
      benefitsCatalog: [],
      postalCodesCatalog: [],
      dateValidate: '',
      genderCatalog: [
        { name: 'Masculino', id: 'M' },
        { name: 'Femenino', id: 'F' },
      ],
      selectedPostalCode: {
        id: '0',
        postalCode: '',
        settlement: '',
        municipality: '',
        city: '',
        estate: '',
      },
      tomorrowDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      maxBirthDate: new Date(
        new Date().getTime() - 24 * 60 * 60 * 1000 * 365 * 16
      ),
      newCatalogPopUp: false,
      title: '',
      typeNewCatalog: '',
      nameNewCatalog: '',
    }
  },
  computed: {
    ...mapState('candidates', {
      newCandidateFormsProgress: 'newCandidateFormsProgress',
    }),
    ...mapState('company', { company: 'company' }),
    ...mapGetters('candidates', {
      getNewCandidateErrors: 'getNewCandidateErrors',
    }),
  },
  mounted: function () {
    this.getPostalCodesCatalog(this.newCandidate.addressAdd.postalCodeStr)
      .then((res) => {
        this.postalCodesCatalog = res
        this.selectedPostalCode = _.clone(
          this.newCandidate.addressAdd.selectedPostalCode
        )
      })
      .catch(() => {})
  },
  created() {
    this.getEmploymentStatusCatalog('is_active=True').then((response) => {
      this.employmentStatusCatalog = response
    })
    this.getJobVacancyCatalog('is_active=True').then((response) => {
      this.jobVacancyCatalog = response
    })
    this.getRecruitingMediumCatalog('is_active=True').then((response) => {
      this.recruitingMediumCatalog = response
    })
    this.getNationalityCatalog('is_active=True').then((response) => {
      this.nationalityCatalog = response
    })
    this.getBenefitCatalog('is_active=True').then((response) => {
      this.benefitsCatalog = response.map((item) => {
        if (this.newCandidate.benefitsId.indexOf(item.id) !== -1) {
          item['status'] = true
        } else {
          item['status'] = false
        }
        return item
      })
    })
    this.dateValidateCalendar()
  },
  methods: {
    ...mapActions('catalogs', {
      getEmploymentStatusCatalog: 'getEmploymentStatusCatalog',
      getJobVacancyCatalog: 'getJobVacancyCatalog',
      getRecruitingMediumCatalog: 'getRecruitingMediumCatalog',
      getNationalityCatalog: 'getNationalityCatalog',
      getBenefitCatalog: 'getBenefitCatalog',
      getPostalCodesCatalog: 'getPostalCodesCatalog',
      newItemCatalog: 'newItemCatalog',
    }),
    ...mapMutations('candidates', {
      setNewCandidatePersonalData: 'setNewCandidatePersonalData',
      setNewCandidateBenefits: 'setNewCandidateBenefits',
      setNewCandidateProgress: 'setNewCandidateProgress',
      setNewCandidateContact: 'setNewCandidateContact',
      setNewCandidateAddress: 'setNewCandidateAddress',
      setNewCandidateGeneralInformationComments:
        'setNewCandidateGeneralInformationComments',
    }),
    onClickSaveAndContinue(section, card) {
      switch (card) {
        case 'candidateData':
          this.setNewCandidatePersonalData(this.newCandidate)
          break
        case 'benefits':
          this.setNewCandidateBenefits(this.newCandidate)
          break
        case 'contact':
          this.setNewCandidateContact(this.newCandidate)
          break
        case 'address':
          this.setNewCandidateAddress({
            newCandidate: this.newCandidate,
            selectedPostalCode: this.selectedPostalCode,
          })
          break
        case 'comments':
          this.setNewCandidateGeneralInformationComments(this.newCandidate)
          break
      }
      this.setNewCandidateProgress({ section, card, status: true })
      this.$vs.notify({
        title: 'Tarjeta actualizada',
        color: 'success',
        position: 'bottom-center',
      })
    },
    addEmailContacto() {
      this.newCandidate.emailsContactAdd.push({
        id: utils.randomId(),
        email: '',
      })
    },
    removerEmailContacto(key) {
      this.newCandidate.emailsContactAdd = this.newCandidate.emailsContactAdd.filter(
        (x) => {
          return x.id !== key
        }
      )
    },
    addTelefonoContacto() {
      this.newCandidate.phonesContactAdd.push({
        id: utils.randomId(),
        phone: '',
      })
    },
    removerTelefonoContacto(key) {
      this.newCandidate.phonesContactAdd = this.newCandidate.phonesContactAdd.filter(
        (x) => {
          return x.id !== key
        }
      )
    },
    availabilityToStartDPClicked(day) {
      this.newCandidate.availabilityToStart = day.id
    },
    dateOfBirthDPClicked(day) {
      this.newCandidate.dateOfBirth = day.id
    },
    onChangeBenefit() {
      this.newCandidate.benefitsId = this.benefitsCatalog
        .filter((item) => item.status)
        .map((item) => item.id)
    },
    _resetObserverValidations() {
      this.$refs.observerDatosCandidato.reset()
    },
    onBlurPostalCode() {
      const postalCode = this.newCandidate.addressAdd.postalCodeStr

      if (postalCode.length < 5) {
        this.selectedPostalCode = {
          id: '0',
          postalCode: '',
          settlement: '',
          municipality: '',
          city: '',
          estate: '',
        }

        this.postalCodesCatalog = this.selectedPostalCode
        this.newCandidate.addressAdd.postalCodeStr = ''
        this.postalCodesCatalog = []

        return false
      }
    },
    onKeyUpPostalCode() {
      const postalCode = this.newCandidate.addressAdd.postalCodeStr
      if (postalCode.length !== 5) {
        return false
      }
      this.getPostalCodesCatalog(postalCode)
        .then((res) => {
          if (res.length === 0) {
            this.postalCodesCatalog = []

            this.selectedPostalCode = {
              id: '0',
              postalCode: '',
              settlement: '',
              municipality: '',
              city: '',
              estate: '',
            }
            this.newCandidate.addressAdd.postalCodeStr = ''
          } else {
            this.postalCodesCatalog = res
            this.selectedPostalCode = _.clone(res[0])
          }
        })
        .catch(() => {})
    },
    newCatalog(type) {
      if (type == 'employmentStatus') {
        this.title = 'Nuevo estatus de empleo'
      }
      if (type == 'jobVacancy') {
        this.title = 'Nueva vacante'
      }
      if (type == 'recruitingMedium') {
        this.title = 'Nuevo medio de reclutamiento'
      }
      if (type == 'nationality') {
        this.title = 'Nueva nacionalidad'
      }
      this.nameNewCatalog = ''
      this.newCatalogPopUp = true
      this.typeNewCatalog = type
    },
    saveNewCatalog() {
      if (this.typeNewCatalog == 'employmentStatus') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'employment-status',
          data: {
            status: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getEmploymentStatusCatalog().then((response) => {
              this.$vs.loading.close()
              this.employmentStatusCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'jobVacancy') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'jobs-vacancy',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getJobVacancyCatalog().then((response) => {
              this.$vs.loading.close()
              this.jobVacancyCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'recruitingMedium') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'recruiting-medium',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getRecruitingMediumCatalog().then((response) => {
              this.$vs.loading.close()
              this.recruitingMediumCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'nationality') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'nationality',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getNationalityCatalog().then((response) => {
              this.$vs.loading.close()
              this.nationalityCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
    },
    dateValidateCalendar() {
      let today = new Date()
      let year = new Date(today).getFullYear()
      let month = new Date(today).getMonth()
      let day = new Date(today).getDate()
      let date = new Date(new Date().setFullYear(year, month, day - 1))
      this.dateValidate = moment(date).format('MM/DD/YYYY')
    },
  },
}
</script>

<style scoped>
.date-size {
  width: 100% !important;
}
</style>
