<template>
  <div>
    <!-- Skeleton loader -->
    <VclList v-if="skeletonLoading" class="mt-4" />
    <!-- New item card -->
    <vs-card v-if="cardNewItemActive" class="mt-4">
      <div slot="header">
        <h3 v-text="titleCard" />
      </div>
      <div>
        <vs-input
          v-model="formNewItem.title"
          label-placeholder="Título"
          @keyup.enter="createItem"
        />
      </div>
      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button
            type="gradient"
            color="success"
            icon="add"
            @click="createItem"
          ></vs-button>
        </vs-row>
      </div>
    </vs-card>
    <!-- List items -->
    <vs-list v-if="cardListActive">
      <vs-list-header :title="titleCard" color="primary" />
      <!-- School Career -->
      <template v-if="typeList === 'schoolCareer'">
        <vs-list-item
          v-for="(career, index) in schoolCareerCatalog"
          :key="career.id"
          :title="`${index + 1}. ${career.name}`"
        >
          <vs-chip
            transparent
            :color="career.isActive ? 'primary' : 'danger'"
            >{{ career.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar carrera', career)"
          />
        </vs-list-item>
      </template>
      <!-- Emplomen Status -->
      <template v-if="typeList === 'employmentStatus'">
        <vs-list-item
          v-for="(employmentStatus, index) in employmentStatusCatalog"
          :key="employmentStatus.id"
          :title="`${index + 1}. ${employmentStatus.status}`"
        >
          <vs-chip
            transparent
            :color="employmentStatus.isActive ? 'primary' : 'danger'"
            >{{ employmentStatus.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="
              openEditItemPrompt('Editar estatus laboral', employmentStatus)
            "
          />
        </vs-list-item>
      </template>
      <!-- Language -->
      <template v-if="typeList === 'language'">
        <vs-list-item
          v-for="(language, index) in languageCatalog"
          :key="language.id"
          :title="`${index + 1}. ${language.name}`"
        >
          <vs-chip
            transparent
            :color="language.isActive ? 'primary' : 'danger'"
            >{{ language.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar idioma', language)"
          />
        </vs-list-item>
      </template>
      <!-- Schools -->
      <template v-if="typeList === 'school'">
        <vs-list-item
          v-for="(school, index) in schoolCatalog"
          :key="school.id"
          :title="`${index + 1}. ${school.name}`"
        >
          <vs-chip
            transparent
            :color="school.isActive ? 'primary' : 'danger'"
            >{{ school.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar escuela', school)"
          />
        </vs-list-item>
      </template>
      <!-- Schools Status -->
      <template v-if="typeList === 'schoolStatus'">
        <vs-list-item
          v-for="(schoolStatus, index) in schoolStatusCatalog"
          :key="schoolStatus.id"
          :title="`${index + 1}. ${schoolStatus.status}`"
        >
          <vs-chip
            transparent
            :color="schoolStatus.isActive ? 'primary' : 'danger'"
            >{{ schoolStatus.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar estatus escolar', schoolStatus)"
          />
        </vs-list-item>
      </template>
      <!-- Recruiting Medium -->
      <template v-if="typeList === 'recruitingMedium'">
        <vs-list-item
          v-for="(recruitingMedium, index) in recruitingMediumCatalog"
          :key="recruitingMedium.id"
          :title="`${index + 1}. ${recruitingMedium.name}`"
        >
          <vs-chip
            transparent
            :color="recruitingMedium.isActive ? 'primary' : 'danger'"
            >{{ recruitingMedium.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="
              openEditItemPrompt(
                'Editar medio de reclutamiento',
                recruitingMedium
              )
            "
          />
        </vs-list-item>
      </template>
      <!-- Nationality -->
      <template v-if="typeList === 'nationality'">
        <vs-list-item
          v-for="(nationality, index) in nationalityCatalog"
          :key="nationality.id"
          :title="`${index + 1}. ${nationality.name}`"
        >
          <vs-chip
            transparent
            :color="nationality.isActive ? 'primary' : 'danger'"
            >{{ nationality.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar nacionalidad', nationality)"
          />
        </vs-list-item>
      </template>
      <!-- Benefit -->
      <template v-if="typeList === 'benefit'">
        <vs-list-item
          v-for="(benefit, index) in benefitCatalog"
          :key="benefit.id"
          :title="`${index + 1}. ${benefit.name}`"
        >
          <vs-chip
            transparent
            :color="benefit.isActive ? 'primary' : 'danger'"
            >{{ benefit.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar prestación', benefit)"
          />
        </vs-list-item>
      </template>
      <!-- Job -->
      <template v-if="typeList === 'job'">
        <vs-list-item
          v-for="(job, index) in jobCatalog"
          :key="job.id"
          :title="`${index + 1}. ${job.name}`"
        >
          <vs-chip transparent :color="job.isActive ? 'primary' : 'danger'">{{
            job.isActive ? 'Activo' : 'Inactivo'
          }}</vs-chip>
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar empleo', job)"
          />
        </vs-list-item>
      </template>
      <!-- Job Vacancy -->
      <template v-if="typeList === 'jobVacancy'">
        <vs-list-item
          v-for="(jobVacancy, index) in jobVacancyCatalog"
          :key="jobVacancy.id"
          :title="`${index + 1}. ${jobVacancy.name}`"
        >
          <vs-chip
            transparent
            :color="jobVacancy.isActive ? 'primary' : 'danger'"
            >{{ jobVacancy.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar vacante laboral', jobVacancy)"
          />
        </vs-list-item>
      </template>
      <!-- Reason For Withdrawal -->
      <template v-if="typeList === 'reasonForWithdrawal'">
        <vs-list-item
          v-for="(reasonForWithdrawal, index) in reasonForWithdrawalCatalog"
          :key="reasonForWithdrawal.id"
          :title="`${index + 1}. ${reasonForWithdrawal.reason}`"
        >
          <vs-chip
            transparent
            :color="reasonForWithdrawal.isActive ? 'primary' : 'danger'"
            >{{ reasonForWithdrawal.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="
              openEditItemPrompt(
                'Editar razón de baja laboral',
                reasonForWithdrawal
              )
            "
          />
        </vs-list-item>
      </template>
      <!-- Area  -->
      <template v-if="typeList === 'area'">
        <vs-list-item
          v-for="(area, index) in areaCatalog"
          :key="area.id"
          :title="`${index + 1}. ${area.name}`"
        >
          <vs-chip transparent :color="area.isActive ? 'primary' : 'danger'">{{
            area.isActive ? 'Activo' : 'Inactivo'
          }}</vs-chip>
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar área de trabajo', area)"
          />
        </vs-list-item>
      </template>
      <!-- PayrollPosition -->
      <template v-if="typeList === 'payrollPosition'">
        <vs-list-item
          v-for="(payrollPosition, index) in payrollPositionCatalog"
          :key="payrollPosition.id"
          :title="`${index + 1}. ${payrollPosition.name}`"
        >
          <vs-chip
            transparent
            :color="payrollPosition.isActive ? 'primary' : 'danger'"
            >{{ payrollPosition.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="
              openEditItemPrompt('Editar puesto de trabajo', payrollPosition)
            "
          />
        </vs-list-item>
      </template>
      <!-- Relationship -->
      <template v-if="typeList === 'relationship'">
        <vs-list-item
          v-for="(relationship, index) in relationshipCatalog"
          :key="relationship.id"
          :title="`${index + 1}. ${relationship.name}`"
        >
          <vs-chip
            transparent
            :color="relationship.isActive ? 'primary' : 'danger'"
            >{{ relationship.isActive ? 'Activo' : 'Inactivo' }}</vs-chip
          >
          <vs-button
            class="ml-3"
            color="primary"
            type="flat"
            icon-after
            icon="edit"
            @click="openEditItemPrompt('Editar parentesco', relationship)"
          />
        </vs-list-item>
      </template>
    </vs-list>

    <!-- Dialogs -->
    <!-- Modal para edición de items -->
    <vs-prompt
      :title="titlePrompt"
      color="primary"
      :is-valid="validName"
      :active.sync="activeopenEditItemPromptPrompt"
      accept-text="Editar"
      cancel-text="Cancelar"
      @accept="submitEditedItem"
    >
      <div>
        <vs-input
          v-model="formopenEditItemPrompt.value"
          class="mt-3 mb-3"
          label-placeholder="Nuevo valor"
        />
        <label>Activo / Inactivo</label>
        <vs-switch
          v-model="formopenEditItemPrompt.isActive"
          class="mt-1"
          color="success"
        >
          <span slot="on">Activo</span>
          <span slot="off">Inactivo</span>
        </vs-switch>
        <vs-alert
          :active="!validName"
          class="mt-3"
          color="warning"
          icon="new_releases"
        >
          Complete los campos
        </vs-alert>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { eventBus } from '@/plugins/event-bus'
import { VclList } from 'vue-content-loading'

export default {
  components: {
    VclList,
  },
  data: function () {
    return {
      skeletonLoading: false,
      activeopenEditItemPromptPrompt: false,
      titlePrompt: '',
      typeList: '',
      schoolCareerCatalog: [],
      employmentStatusCatalog: [],
      languageCatalog: [],
      schoolCatalog: [],
      schoolStatusCatalog: [],
      recruitingMediumCatalog: [],
      nationalityCatalog: [],
      benefitCatalog: [],
      jobCatalog: [],
      jobVacancyCatalog: [],
      reasonForWithdrawalCatalog: [],
      areaCatalog: [],
      payrollPositionCatalog: [],
      relationshipCatalog: [],
      formNewItem: {
        title: '',
      },
      formopenEditItemPrompt: {
        id: '',
        isActive: '',
        value: '',
      },
    }
  },
  computed: {
    ...mapState('catalogs', {
      cardNewItemActive: 'cardNewItemActive',
      cardListActive: 'cardListActive',
      titleCard: 'titleCard',
      typeSubmit: 'typeSubmit',
    }),
    ...mapState('company', { company: 'company' }),
    validName() {
      return this.formopenEditItemPrompt.value.length > 0
    },
  },
  created: function () {
    eventBus.$on('openCareerList', this.openCareerList)
    eventBus.$on('openEmploymentStatusList', this.openEmploymentStatusList)
    eventBus.$on('openLanguageList', this.openLanguageList)
    eventBus.$on('openSchoolList', this.openSchoolList)
    eventBus.$on('openSchoolStatusList', this.openSchoolStatusList)
    eventBus.$on('openRecruitingMediumList', this.openRecruitingMediumList)
    eventBus.$on('openNationalityList', this.openNationalityList)
    eventBus.$on('openBenefitList', this.openBenefitList)
    eventBus.$on('openJobList', this.openJobList)
    eventBus.$on('openJobVacancyList', this.openJobVacancyList)
    eventBus.$on(
      'openReasonForWithdrawalList',
      this.openReasonForWithdrawalList
    )
    eventBus.$on('openAreaList', this.openAreaList)
    eventBus.$on('openPayrollPositionList', this.openPayrollPositionList)
    eventBus.$on('openRelationshipList', this.openRelationshipList)
  },
  methods: {
    ...mapActions('catalogs', {
      newItemCatalog: 'newItemCatalog',
      updateItemCatalog: 'updateItemCatalog',
      getSchoolCareerCatalog: 'getSchoolCareerCatalog',
      getEmploymentStatusCatalog: 'getEmploymentStatusCatalog',
      getLanguagesCatalog: 'getLanguagesCatalog',
      getSchoolCatalog: 'getSchoolCatalog',
      getSchoolStatusCatalog: 'getSchoolStatusCatalog',
      getRecruitingMediumCatalog: 'getRecruitingMediumCatalog',
      getNationalityCatalog: 'getNationalityCatalog',
      getBenefitCatalog: 'getBenefitCatalog',
      getJobCatalog: 'getJobCatalog',
      getJobVacancyCatalog: 'getJobVacancyCatalog',
      getReasonForWithdrawalCatalog: 'getReasonForWithdrawalCatalog',
      getAreaCatalog: 'getAreaCatalog',
      getPayrollPositionCatalog: 'getPayrollPositionCatalog',
      getRelationshipCatalog: 'getRelationshipCatalog',
    }),
    ...mapMutations('catalogs', {
      updateCardListActive: 'updateCardListActive',
      updateCardNewItemActive: 'updateCardNewItemActive',
      updateTitleCard: 'updateTitleCard',
      updateTypeSubmit: 'updateTypeSubmit',
    }),
    finishNewItem() {
      this.updateTitleCard('')
      this.updateCardListActive(false)
      this.updateCardNewItemActive(true)
      this.typeList = ''
      this.formNewItem.title = ''
    },
    /**
     * Envia la petición al servidor para la creación del nuevo item del catálogo correspondiente.
     */
    createItem() {
      this.skeletonLoading = true
      this.updateCardNewItemActive(false)

      switch (this.typeSubmit) {
        case 'schoolCareer':
          this.newItemCatalog({
            type: 'school-career',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openCareerList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openCareerList()
            })
          break
        case 'employmentStatus':
          this.newItemCatalog({
            type: 'employment-status',
            data: { status: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openEmploymentStatusList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openEmploymentStatusList()
            })
          break
        case 'language':
          this.newItemCatalog({
            type: 'languages',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openLanguageList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openLanguageList()
            })
          break
        case 'school':
          this.newItemCatalog({
            type: 'schools',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openSchoolList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openSchoolList()
            })
          break
        case 'schoolStatus':
          this.newItemCatalog({
            type: 'school-status',
            data: { status: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openSchoolStatusList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openSchoolStatusList()
            })
          break
        case 'recruitmentMedium':
          this.newItemCatalog({
            type: 'recruiting-medium',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openRecruitingMediumList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openRecruitingMediumList()
            })
          break
        case 'nationality':
          this.newItemCatalog({
            type: 'nationality',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openNationalityList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openNationalityList()
            })
          break
        case 'benefit':
          this.newItemCatalog({
            type: 'benefits',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openBenefitList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openBenefitList()
            })
          break
        case 'job':
          this.newItemCatalog({
            type: 'jobs',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openJobList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openJobList()
            })
          break
        case 'jobVacancy':
          this.newItemCatalog({
            type: 'jobs-vacancy',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openJobVacancyList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openJobVacancyList()
            })
          break
        case 'withdrawal':
          this.newItemCatalog({
            type: 'withdrawal-reason',
            data: { reason: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openReasonForWithdrawalList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openReasonForWithdrawalList()
            })
          break
        case 'area':
          this.newItemCatalog({
            type: 'area',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openAreaList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openAreaList()
            })
          break
        case 'payrollPosition':
          this.newItemCatalog({
            type: 'payroll-position',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openPayrollPositionList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openPayrollPositionList()
            })
          break
        case 'relationship':
          this.newItemCatalog({
            type: 'relationship',
            data: { name: this.formNewItem.title, company: this.company.id },
          })
            .then(() => {
              this.finishNewItem()
              this.openRelationshipList()
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.finishNewItem()
              this.openRelationshipList()
            })
          break
        default:
          this.skeletonLoading = false
          break
      }
    },
    // Lista de catálogos
    /**
     * Despliega la lista de carreras.
     */
    openCareerList() {
      this.skeletonLoading = true
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)

      this.getSchoolCareerCatalog().then((res) => {
        this.schoolCareerCatalog = res
        this.skeletonLoading = false
        this.updateTitleCard('Lista de carreras')
        this.updateCardListActive(true)
        this.typeList = 'schoolCareer'
      })
    },
    /**
     * Despliega la lista de estatus laborales.
     */
    openEmploymentStatusList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getEmploymentStatusCatalog().then((res) => {
        this.skeletonLoading = false
        this.employmentStatusCatalog = res
        this.typeList = 'employmentStatus'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de estatus laboral')
      })
    },
    /**
     * Despliega la lista de lenguajes.
     */
    openLanguageList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getLanguagesCatalog().then((res) => {
        this.languageCatalog = res
        this.skeletonLoading = false
        this.typeList = 'language'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de idiomas')
      })
    },
    /**
     * Despliega la lista de escuelas.
     */
    openSchoolList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getSchoolCatalog().then((res) => {
        this.schoolCatalog = res
        this.skeletonLoading = false
        this.typeList = 'school'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de escuelas')
      })
    },
    /**
     * Despliegue de estatus escolares.
     */
    openSchoolStatusList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getSchoolStatusCatalog().then((res) => {
        this.schoolStatusCatalog = res
        this.skeletonLoading = false
        this.typeList = 'schoolStatus'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de estatus escolares')
      })
    },
    /**
     * Despliegue de medios de reclutamiento.
     */
    openRecruitingMediumList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getRecruitingMediumCatalog().then((res) => {
        this.recruitingMediumCatalog = res
        this.skeletonLoading = false
        this.typeList = 'recruitingMedium'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de medios de reclutamiento')
      })
    },
    /**
     * Despliega la lista de nacionalidades.
     */
    openNationalityList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getNationalityCatalog().then((res) => {
        this.nationalityCatalog = res
        this.skeletonLoading = false
        this.typeList = 'nationality'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de nacionalidades')
      })
    },
    /**
     * Despliega la lista de prestaciones.
     */
    openBenefitList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getBenefitCatalog().then((res) => {
        this.benefitCatalog = res
        this.skeletonLoading = false
        this.typeList = 'benefit'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de prestaciones')
      })
    },
    /**
     * Despliega la lista de empleos
     */
    openJobList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getJobCatalog().then((res) => {
        this.jobCatalog = res
        this.skeletonLoading = false
        this.typeList = 'job'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de empleos')
      })
    },
    /**
     * Despliega la lista de vacantes.
     */
    openJobVacancyList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getJobVacancyCatalog().then((res) => {
        this.jobVacancyCatalog = res
        this.skeletonLoading = false
        this.typeList = 'jobVacancy'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de vacantes')
      })
    },
    /**
     * Despliega la lista de razones de baja laboral
     */
    openReasonForWithdrawalList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getReasonForWithdrawalCatalog().then((res) => {
        this.reasonForWithdrawalCatalog = res
        this.skeletonLoading = false
        this.typeList = 'reasonForWithdrawal'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de razones de baja laboral')
      })
    },
    /**
     * Despliega la lista de alta
     */
    openAreaList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getAreaCatalog().then((res) => {
        this.areaCatalog = res
        this.skeletonLoading = false
        this.typeList = 'area'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de áreas de trabajo')
      })
    },
    /**
     * Despliega la lista de puestos de trabajo
     */
    openPayrollPositionList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getPayrollPositionCatalog().then((res) => {
        this.payrollPositionCatalog = res
        this.skeletonLoading = false
        this.typeList = 'payrollPosition'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de puestos de trabajo')
      })
    },
    /**
     * Despliega la lista de razones de baja laboral
     */
    openRelationshipList() {
      this.updateCardListActive(false)
      this.updateCardNewItemActive(false)
      this.skeletonLoading = true

      this.getRelationshipCatalog().then((res) => {
        this.relationshipCatalog = res
        this.skeletonLoading = false
        this.typeList = 'relationship'
        this.updateCardListActive(true)
        this.updateTitleCard('Lista de parentescos')
      })
    },
    /**
     * Permite crear el nuevo item para el catalogo correspondiente.
     */
    submitEditedItem() {
      switch (this.typeSubmit) {
        case 'schoolCareer': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'school-career',
            data: updateForm,
          })
            .then(() => {
              this.openCareerList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Carrera modificada exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openCareerList()
            })
          break
        }
        case 'employmentStatus': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            status: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'employment-status',
            data: updateForm,
          })
            .then(() => {
              this.openEmploymentStatusList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Estatus laboral exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openEmploymentStatusList()
            })
          break
        }
        case 'language': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'languages',
            data: updateForm,
          })
            .then(() => {
              this.openLanguageList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Idioma modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openLanguageList()
            })
          break
        }
        case 'school': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'schools',
            data: updateForm,
          })
            .then(() => {
              this.openSchoolList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Escuela modificada exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openSchoolList()
            })
          break
        }
        case 'schoolStatus': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            status: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'school-status',
            data: updateForm,
          })
            .then(() => {
              this.openSchoolStatusList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Estatus escolar modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openSchoolStatusList()
            })
          break
        }
        case 'recruitmentMedium': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'recruiting-medium',
            data: updateForm,
          })
            .then(() => {
              this.openRecruitingMediumList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Medio de reclutamiento modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openRecruitingMediumList()
            })
          break
        }
        case 'nationality': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'nationality',
            data: updateForm,
          })
            .then(() => {
              this.openNationalityList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Nacionalidad modificada exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openNationalityList()
            })
          break
        }
        case 'benefit': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'benefits',
            data: updateForm,
          })
            .then(() => {
              this.openBenefitList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Prestación modificada exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openBenefitList()
            })
          break
        }
        case 'job': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'jobs',
            data: updateForm,
          })
            .then(() => {
              this.openJobList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Empleo modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openJobList()
            })
          break
        }
        case 'jobVacancy': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'jobs-vacancy',
            data: updateForm,
          })
            .then(() => {
              this.openJobVacancyList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Vacante laboral modificada exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openJobVacancyList()
            })
          break
        }
        case 'withdrawal': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            reason: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'withdrawal-reason',
            data: updateForm,
          })
            .then(() => {
              this.openReasonForWithdrawalList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Razón de baja laboral modificada exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openReasonForWithdrawalList()
            })
          break
        }
        case 'area': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'area',
            data: updateForm,
          })
            .then(() => {
              this.openAreaList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'área de trabajo modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openAreaList()
            })
          break
        }
        case 'payrollPosition': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'payroll-position',
            data: updateForm,
          })
            .then(() => {
              this.openPayrollPositionList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Puesto de trabajo modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openPayrollPositionList()
            })
          break
        }
        case 'relationship': {
          let updateForm = {
            id: this.formopenEditItemPrompt.id,
            name: this.formopenEditItemPrompt.value,
            isActive: this.formopenEditItemPrompt.isActive,
          }
          this.updateItemCatalog({
            type: 'relationship',
            data: updateForm,
          })
            .then(() => {
              this.openRelationshipList()
              this.$vs.notify({
                color: 'success',
                title: 'Éxito',
                text: 'Parentesco modificado exitosamente.',
              })
            })
            .catch((err) => {
              this.$vs.notify({
                title: 'Oops... Ocurrió un error:',
                text: err.response.data['errors'][0]['message'],
                color: 'warning',
                position: 'top-right',
                icon: 'warning',
              })
              this.openRelationshipList()
            })
          break
        }
        default:
          this.skeletonLoading = false
          break
      }
    },
    /**
     * Recibe el item que se editará.
     */
    openEditItemPrompt(title, item) {
      this.activeopenEditItemPromptPrompt = true
      this.titlePrompt = title
      this.formopenEditItemPrompt = {
        id: item.id,
        isActive: item.isActive,
        value: item.name ? item.name : item.status ? item.status : item.reason,
      }
    },
  },
}
</script>
