<template>
  <div class="sidebar-candidates-container">
    <vs-sidebar
      static-position
      default-index="1"
      color="primary"
      :hidden-background="true"
    >
      <div slot="header" class="header-sidebar">
        <h3 class="mb-2">
          Filtrar Candidatos
        </h3>

        <template v-if="company.picture === '#' || company.picture === null">
          <img class="logo" src="@/assets/images/company.png" alt="avatar1" />
        </template>
        <img v-else class="logo" :src="company.picture" alt="avatar2" />
      </div>

      <vs-row class="px-3">
        <vs-col vs-w="12">
          <!-- Quick Search -->
          <vs-row class="px-3 mb-3">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                v-model="searchParams"
                class="input-search"
                :icon-after="true"
                icon="search"
                placeholder="Búsqueda Rápida"
                @keypress.enter="searchCandidates"
                @icon-click="searchCandidates"
              />
            </vs-col>
          </vs-row>

          <!-- Gender -->
          <vs-row class="px-1 pr-2 mb-3">
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-3"
            >
              <strong>Filtrar por género</strong>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-1"
            >
              <vs-radio v-model="gender" vs-name="gender" vs-value="M">
                Hombre
              </vs-radio>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-1"
            >
              <vs-radio v-model="gender" vs-name="gender" vs-value="F">
                Mujer
              </vs-radio>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-1"
            >
              <vs-radio v-model="gender" vs-name="gender" vs-value="B">
                Ambos
              </vs-radio>
            </vs-col>

            <!-- Edad -->
            <vs-col
              vs-type="flex"
              vs-w="12"
              vs-justify="flex-start"
              class="mt-3"
            >
              <strong>Filtrar por edad</strong>
            </vs-col>
            <vs-col vs-type="flex" vs-w="5" class="pl-2">
              <vs-slider
                v-model="ageRange"
                class="age-picker"
                :step="1"
                :min="15"
                :max="70"
              />
            </vs-col>
          </vs-row>

          <!-- Rango de sueldo -->
          <vs-sidebar-group title="Rango de sueldo" :open="true" class="pr-1">
            <div class="salary-filter-container mt-1">
              <vs-row>
                <vs-col vs-type="flex" vs-w="6">
                  <vs-input
                    v-model="minimumSalary"
                    class="pr-1"
                    label-placeholder="Sueldo mín."
                    type="number"
                    @keypress.enter="searchCandidates"
                  />
                </vs-col>
                <vs-col vs-type="flex" vs-w="6">
                  <vs-input
                    v-model="maximumSalary"
                    class="pl-1"
                    label-placeholder="Sueldo máx."
                    type="number"
                    @keypress.enter="searchCandidates"
                  />
                </vs-col>
              </vs-row>
            </div>
          </vs-sidebar-group>

          <!-- Fecha de creación -->
          <vs-sidebar-group
            title="Fecha de creación"
            :open="true"
            class="pr-1 mt-3"
          >
            <div class="salary-filter-container mt-1">
              <vs-row>
                <vs-col class="mt-1 col-inputs-full" vs-type="flex" vs-w="12">
                  <label class="vs-input--label">Fecha inicial</label>
                </vs-col>
                <vs-col vs-type="flex" vs-w="12">
                  <date-picker
                    v-model="createdAt.start"
                    class="mt-1 full-width"
                    placeholder="Ingrese la Fecha"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :open.sync="openDatepicker"
                    :disabled-date="_BeforeToday"
                  >
                    <template v-slot:footer>
                      <button
                        class="mx-btn mx-btn-text mx-2"
                        @click="openDatepicker = false"
                      >
                        Listo
                      </button>
                    </template>
                  </date-picker>
                </vs-col>
                <vs-col class="mt-1 col-inputs-full" vs-type="flex" vs-w="12">
                  <label class="vs-input--label">Fecha final</label>
                </vs-col>
                <vs-col vs-type="flex" vs-w="12">
                  <date-picker
                    v-model="createdAt.end"
                    class="mt-1 mb-3 full-width"
                    placeholder="Ingrese la Fecha"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :open.sync="openDatepickerFin"
                    :disabled-date="_BeforeToday"
                  >
                    <template v-slot:footer>
                      <button
                        class="mx-btn mx-btn-text mx-2"
                        @click="openDatepickerFin = false"
                      >
                        Listo
                      </button>
                    </template>
                  </date-picker>
                </vs-col>
              </vs-row>
            </div>
          </vs-sidebar-group>

          <!-- Evaluación -->
          <vs-sidebar-group
            title="Evaluación del candidato"
            :open="true"
            class="pr-1 mt-3"
          >
            <div class="salary-filter-container mt-1">
              <vs-row class="pl-1">
                <!-- Global rating -->
                <vs-col vs-type="flex" vs-w="12" class="my-0 py-0">
                  <small>Calificación global</small>
                </vs-col>
                <vs-col vs-type="flex" vs-w="5">
                  <vs-slider
                    v-model="globalRatingRange"
                    icon="star"
                    :step="0.5"
                    :step-decimals="true"
                    :max="5"
                  />
                </vs-col>
                <!-- Exam result -->
                <vs-col vs-type="flex" vs-w="12" class="mt-3 py-0">
                  <small>Calificación del examen</small>
                </vs-col>
                <vs-col vs-type="flex" vs-w="6">
                  <vs-slider
                    v-model="examResultRange"
                    icon="star"
                    :step="0.5"
                    :step-decimals="true"
                    :max="10"
                  />
                </vs-col>
              </vs-row>
            </div>
          </vs-sidebar-group>

          <!-- Vacantes -->
          <vs-sidebar-group title="Vacantes" :open="true" class="px-1 pr-3">
            <vs-row class="ml-1">
              <vs-col
                v-for="item in jobVacancyCatalog"
                :key="item.id"
                vs-type="flex"
                vs-w="12"
                vs-justify="flex-start"
                class="mt-1"
              >
                <vs-checkbox v-model="jobVacancySelected" :vs-value="item.id">
                  {{ item.name }}
                </vs-checkbox>
              </vs-col>
            </vs-row>
          </vs-sidebar-group>

          <!-- Estatus contratación -->
          <vs-sidebar-group
            title="Estatus contratación"
            :open="true"
            class="px-1 pr-3"
          >
            <vs-row class="ml-1">
              <vs-col
                vs-type="flex"
                vs-w="12"
                vs-justify="flex-start"
                class="mt-1"
              >
                <vs-checkbox
                  v-model="candidateFollowUpSelected"
                  :vs-value="'HI'"
                >
                  Contratado
                </vs-checkbox>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-w="12"
                vs-justify="flex-start"
                class="mt-1"
              >
                <vs-checkbox
                  v-model="candidateFollowUpSelected"
                  :vs-value="'IP'"
                >
                  En proceso
                </vs-checkbox>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-w="12"
                vs-justify="flex-start"
                class="mt-1"
              >
                <vs-checkbox
                  v-model="candidateFollowUpSelected"
                  :vs-value="'RE'"
                >
                  Rechazado
                </vs-checkbox>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-w="12"
                vs-justify="flex-start"
                class="mt-1"
              >
                <vs-checkbox
                  v-model="candidateFollowUpSelected"
                  :vs-value="'DA'"
                >
                  Abandonó la postulación
                </vs-checkbox>
              </vs-col>
            </vs-row>
          </vs-sidebar-group>

          <vs-divider position="left" />
        </vs-col>
      </vs-row>
      <div slot="footer" class="footer-sidebar">
        <vs-row class="px-1 mb-3">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              class="pl-2 pr-3 ml-1 btn-block"
              size="small"
              color="primary"
              type="border"
              icon="search"
              :icon-after="false"
              @click="searchCandidates"
              >Buscar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'FilterSideBar',
  data: function () {
    return {
      openDatepicker: false,
      openDatepickerFin: false,
      searchParams: '',
      minimumSalary: '',
      maximumSalary: '',
      createdAt: {
        start: '',
        end: '',
      },
      globalRatingRange: [0, 5],
      examResultRange: [0, 10],
      ageRange: [16, 70],
      gender: 'B',
      jobVacancyCatalog: [],
      jobVacancySelected: [],
      candidateFollowUpSelected: ['IP'],
    }
  },
  computed: { ...mapState('company', { company: 'company' }) },
  created() {
    this.getJobVacancyCatalog('is_active=True').then((response) => {
      this.jobVacancyCatalog = response
    })
  },
  methods: {
    ...mapActions('candidates', {
      getCandidatesList: 'getCandidatesList',
    }),
    ...mapActions('catalogs', { getJobVacancyCatalog: 'getJobVacancyCatalog' }),
    ...mapMutations('candidates', {
      setCandidatesList: 'setCandidatesList',
      setSkeletonLoading: 'setSkeletonLoading',
      setTotalCandidatesPages: 'setTotalCandidatesPages',
      setSearchParams: 'setSearchParams',
    }),
    _BeforeToday(date) {
      return utils.BeforeToday(date)
    },
    formatCandidatesList(candidatesList) {
      const pages = Math.ceil(candidatesList.count / candidatesList.pageSize)
      let candidatesListFormated = candidatesList.results.map((item) => {
        item['age'] = utils.calculateAge(item.dateOfBirth)
        item['id'] = item['id'].toString()
        return item
      })
      this.setTotalCandidatesPages(pages)
      this.setCandidatesList(candidatesListFormated)
      this.setSkeletonLoading(false)
    },
    getDateFromAge(age) {
      let date = new Date()
      date.setFullYear(date.getFullYear() - age)
      return date.toISOString().split('T')[0]
    },
    searchCandidates() {
      this.setSkeletonLoading(true)

      let query = `search=${this.searchParams}`

      // Created At
      if (this.createdAt.start !== '' && this.createdAt.start !== null) {
        query += `&created_at__gte=${this.createdAt.start}`
      }

      if (
        this.createdAt.end !== '' &&
        this.createdAt.end !== null &&
        this.createdAt.start !== this.createdAt.end
      ) {
        let EDate = this.addOneDay(this.createdAt.end)
        query += `&created_at__lte=${EDate}`
      } else {
        let finalDate = this.addOneDay(this.createdAt.start)
        query += `&Fdate=${finalDate}`
      }

      // Salary range
      if (this.minimumSalary !== '') {
        query += `&minimum_salary_expectation__gte=${this.minimumSalary}`
      }
      if (this.maximumSalary !== '') {
        query += `&maximum_salary_expectation__lte=${this.maximumSalary}`
      }

      // Global rating1
      if (JSON.stringify(this.globalRatingRange) !== JSON.stringify([0, 5])) {
        query += `&candidaterating__global_rating__gte=${this.globalRatingRange[0]}`
        query += `&candidaterating__global_rating__lte=${this.globalRatingRange[1]}`
      }

      // Exam result
      if (JSON.stringify(this.examResultRange) !== JSON.stringify([0, 10])) {
        query += `&candidaterating__exam_result__gte=${this.examResultRange[0]}`
        query += `&candidaterating__exam_result__lte=${this.examResultRange[1]}`
      }

      // JobVacancy
      if (this.jobVacancySelected.length > 0) {
        query += `&job_vacancy__in=${this.jobVacancySelected.join(',')}`
      }

      // Gender
      if (this.gender !== 'B') {
        query += `&gender=${this.gender}`
      }

      // Birth Date
      let minAge = this.getDateFromAge(this.ageRange[0] - 1)
      let maxAge = this.getDateFromAge(this.ageRange[1] + 1)
      query += `&date_of_birth__lte=${minAge}`
      query += `&date_of_birth__gte=${maxAge}`

      // Candidate Follow Up
      query += `&candidatefollowup__status__in=${this.candidateFollowUpSelected.join(
        ','
      )}`

      this.setSearchParams(query)

      this.getCandidatesList(query)
        .then((res) => {
          this.formatCandidatesList(res)
        })
        .catch()
    },
    getDay() {
      return utils.getActualDate()
    },
    addOneDay(dayf) {
      let dia = new Date(dayf)
      dia.setDate(dia.getDate() + 2)
      let diaS = dia.getFullYear() + '-'
      if (dia.getMonth() > 8) {
        diaS = diaS + (dia.getMonth() + 1)
      } else {
        diaS = diaS + '0' + (dia.getMonth() + 1)
      }
      if (dia.getDate() > 9) {
        diaS = diaS + '-' + dia.getDate()
      } else {
        diaS = diaS + '-0' + dia.getDate()
      }
      return diaS
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
@import '~@/scss/mixins/_filter-sidebar';

.vs-sidebar {
  max-width: 330px !important;
}
.sidebar-candidates-container {
  overflow: hidden;
  height: calc(100vh - #{$navbar-height});
  position: relative;
  z-index: 0;

  .vs-sidebar.vs-sidebar-parent.vs-sidebar-staticPosition {
    z-index: 10000;
  }
}

.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.vs-sidebar.vs-sidebar-primary.vs-sidebar-parent.vs-sidebar-staticPosition {
  height: 100vh !important;
  @include vs-sidebar--items;
}

.con-vs-slider {
  min-width: 175px !important;

  @include respond-to('small') {
    min-width: 200px !important;
  }
  @include respond-to('medium') {
    min-width: 230px !important;
  }
  @include respond-to('large') {
    min-width: 240px !important;
  }
  @include respond-to('xlarge') {
    min-width: 260px !important;
  }
}
.vs-sidebar {
  background-color: $bg-blue-gray !important;
  .logo {
    @include vs-sidebar--logo;
  }
}

.input-search .vs-con-input .vs-icon.vs-input--icon {
  cursor: pointer !important;
  transition: all ease 0.3s;
  background: $primary;
  color: $bg-blue-gray !important;
  padding: 8px;
  padding-top: 8px;
  margin-top: -8px;
  margin-right: -5px;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background: rgba($primary, 0.7);
  }
}

.vc-popover-content-wrapper[data-v-88de91da] {
  z-index: 999 !important;
}
</style>
