var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"picture-input input-no-visible",attrs:{"id":"picture-input"}},[(!_vm.supportsUpload)?_c('div',{domProps:{"textContent":_vm._s(_vm.strings.upload)}}):(_vm.supportsPreview)?_c('div',[_c('div',{staticClass:"preview-container",style:({
        maxWidth: _vm.previewWidth + 'px',
        height: _vm.previewHeight + 'px',
        borderRadius: _vm.radius + '%',
      })},[(_vm.spanSize)?[_c('span',[_vm._v("Tamaño de la imagen")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.textImgSize))])]:_vm._e(),_c('canvas',{ref:"previewCanvas",staticClass:"picture-preview",class:_vm.computedClasses,style:({ height: _vm.previewHeight + 'px', zIndex: _vm.zIndex + 1 }),on:{"drag":function($event){$event.stopPropagation();$event.preventDefault();},"dragover":function($event){$event.stopPropagation();$event.preventDefault();},"dragstart":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStart.apply(null, arguments)},"dragenter":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStart.apply(null, arguments)},"dragend":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStop.apply(null, arguments)},"dragleave":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStop.apply(null, arguments)},"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onFileDrop.apply(null, arguments)},"click":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)}}}),(!_vm.imageSelected && !_vm.plain)?_c('div',{staticClass:"picture-inner",style:({
          top: -_vm.previewHeight + 'px',
          marginBottom: -_vm.previewHeight + 'px',
          fontSize: _vm.fontSize,
          borderRadius: _vm.radius + '%',
          zIndex: _vm.zIndex + 2,
        })},[(_vm.supportsDragAndDrop)?_c('span',{staticClass:"picture-inner-text",domProps:{"textContent":_vm._s(_vm.strings.drag)}}):_c('span',{staticClass:"picture-inner-text",domProps:{"textContent":_vm._s(_vm.strings.tap)}})]):_vm._e()],2),(_vm.imageSelected && !_vm.hideChangeButton)?_c('button',{class:_vm.buttonClass,on:{"click":function($event){$event.preventDefault();return _vm.selectImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.strings.change)+" ")]):_vm._e(),(_vm.imageSelected && _vm.removable)?_c('button',{class:_vm.removeButtonClass,on:{"click":function($event){$event.preventDefault();return _vm.removeImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.strings.remove)+" ")]):_vm._e(),(_vm.imageSelected && _vm.toggleAspectRatio && _vm.width !== _vm.height)?_c('button',{class:_vm.aspectButtonClass,on:{"click":function($event){$event.preventDefault();return _vm.rotateImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.strings.aspect)+" ")]):_vm._e()]):_c('div',[(!_vm.imageSelected)?_c('button',{class:_vm.buttonClass,on:{"click":function($event){$event.preventDefault();return _vm.selectImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.strings.select)+" ")]):_c('div',[_c('div',{domProps:{"textContent":_vm._s(_vm.strings.selected)}}),(!_vm.hideChangeButton)?_c('button',{class:_vm.buttonClass,on:{"click":function($event){$event.preventDefault();return _vm.selectImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.strings.change)+" ")]):_vm._e(),(_vm.removable)?_c('button',{class:_vm.removeButtonClass,on:{"click":function($event){$event.preventDefault();return _vm.removeImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.strings.remove)+" ")]):_vm._e()])]),_c('input',{ref:"fileInput",attrs:{"id":_vm.id,"type":"file","name":_vm.name,"accept":_vm.accept},on:{"change":_vm.onFileChange}})])
}
var staticRenderFns = []

export { render, staticRenderFns }