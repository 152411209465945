var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-sidebar',{staticClass:"sidebarx",attrs:{"parent":"body","default-index":"3.1","color":"primary","click-not-close":true,"spacer":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"header-sidebar",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"header-desc"},[_c('vs-button',{attrs:{"icon":"close","color":"primary","type":"flat"},on:{"click":function($event){_vm.active = false}}})],1),(_vm.user.userprofile.picture !== '#')?[_c('vs-avatar',{attrs:{"size":"70px","src":_vm.user.userprofile.picture}})]:_c('vs-avatar',{attrs:{"size":"70px","src":require('@/assets/images/user-avatar.png')}}),_c('h4',[_vm._v(_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.lastName)+" "+_vm._s(_vm.user.secondLastName))]),_c('span',{staticClass:"small-font"},[_vm._v(_vm._s(_vm.user.email))]),_c('small',{staticClass:"small-font mt-1"},[_vm._v(_vm._s(_vm.company.name))])],2),_c('vs-sidebar-item',{attrs:{"index":"1","icon":"account_box"},on:{"click":_vm.goToProfile}},[_vm._v(" Mi Perfil ")]),(
      _vm.user.userprofile.role === _vm.roles.ADMIN ||
      _vm.user.userprofile.role === _vm.roles.RECRUITER
    )?_c('vs-sidebar-item',{attrs:{"index":"2","icon":"library_books"},on:{"click":_vm.goToCatalogs}},[_vm._v(" Catálogos ")]):_vm._e(),(
      _vm.user.userprofile.role === _vm.roles.ADMIN ||
      _vm.user.userprofile.role === _vm.roles.RECRUITER
    )?_c('vs-sidebar-group',{attrs:{"title":"Candidatos","open":true}},[_c('vs-sidebar-item',{attrs:{"index":"3.1","icon":"list"},on:{"click":_vm.goToCandidates}},[_vm._v(" Lista de candidatos ")]),_c('vs-sidebar-item',{attrs:{"index":"3.2","icon":"person_add"},on:{"click":_vm.goToNewCandidate}},[_vm._v(" Agregar Candidato ")])],1):_vm._e(),(
      _vm.user.userprofile.role === _vm.roles.ADMIN ||
      _vm.user.userprofile.role === _vm.roles.RECRUITER
    )?_c('vs-sidebar-group',{attrs:{"title":"Empleados","open":true}},[_c('vs-sidebar-item',{attrs:{"index":"4.1","icon":"list"},on:{"click":_vm.goToEmployees}},[_vm._v(" Lista de empleados ")]),_c('vs-sidebar-item',{attrs:{"index":"4.2","icon":"work_outline"},on:{"click":_vm.goToListAssistance}},[_vm._v(" Lista de asistencia ")]),_c('vs-sidebar-item',{attrs:{"index":"4.3","icon":"beach_access"},on:{"click":_vm.goToListVacations}},[_vm._v(" Lista de vacaciones ")])],1):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('vs-sidebar-group',{attrs:{"title":"Empresa","open":true}},[_c('vs-sidebar-item',{attrs:{"index":"5.1","icon":"business"},on:{"click":_vm.goToCompanyDetail}},[_vm._v(" Reclutadores ")])],1):_vm._e(),(_vm.user.employee != null && _vm.user.employee != undefined)?_c('vs-sidebar-group',{attrs:{"title":"Asistencia","open":true}},[_c('vs-sidebar-item',{staticStyle:{"margin-left":"-16px"},attrs:{"index":"0","icon":"clock"},on:{"click":_vm.goToAssistance}},[_vm._v(" Asistencia ")]),_c('vs-sidebar-item',{attrs:{"index":"0.1","icon":"work_outline"},on:{"click":_vm.goToEmployeeAssistance}},[_vm._v(" Mis asistencias ")])],1):_vm._e(),_c('div',{staticClass:"footer-sidebar",attrs:{"slot":"footer"},slot:"footer"},[_c('vs-button',{attrs:{"icon":"reply","color":"danger","type":"flat"},on:{"click":_vm._logout}},[_vm._v(" Cerrar Sesión ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }