<template>
  <div class="container full-height">
    <vs-row
      class=""
      vs-align="center"
      vs-type="flex"
      vs-justify="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-xs="12"
        vs-sm="10"
        vs-lg="8"
      >
        <RestorePasswordForm v-if="showRestorePasswordForm" />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import RestorePasswordForm from '@/modules/auth/views/recover/RestorePasswordForm'

export default {
  components: {
    RestorePasswordForm,
  },
  data: function () {
    return {
      showRestorePasswordForm: false,
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters['auth/isLogged']
    },
  },
  created() {
    if (this.isLogged) {
      this.$router
        .push({
          name: 'productList',
        })
        .catch(() => {})
    } else {
      this.showRestorePasswordForm = true
    }
  },
}
</script>

<style lang="scss">
.full-height {
  height: 100vh;
}
</style>
