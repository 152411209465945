<template>
  <div class="container">
    <vs-row
      class="full-height"
      vs-align="center"
      vs-type="flex"
      vs-justify="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-xs="12"
        vs-sm="10"
        vs-lg="8"
      >
        <RegisterForm />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import RegisterForm from '@/modules/auth/views/register/RegisterForm'

export default {
  components: {
    RegisterForm,
  },
}
</script>

<style lang="scss">
.full-height {
  min-height: calc(100vh - 58px) !important;
}
</style>
