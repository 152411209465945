<template>
  <vs-row class="candidates-cards-container" vs-w="12">
    <vs-col
      class="cards-btn-container"
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-button
        class="block resumen-pdf-btn"
        color="primary"
        type="line"
        :class="{ 'is-active': activeResumen }"
        @click="clickTab('resumen')"
      >
        Resumen
      </vs-button>
      <vs-button
        class="block documents-pdf-btn"
        color="primary"
        type="line"
        :class="{ 'is-active': activeDocuments }"
        @click="clickTab('documentos')"
      >
        Documentos
      </vs-button>
    </vs-col>
    <vs-col v-if="activeDocuments" vs-type="flex" vs-w="12">
      <CandidateDocumentsPreview />
    </vs-col>
    <vs-col v-if="activeResumen" vs-type="flex" vs-w="12">
      <CandidateInfo />
    </vs-col>
  </vs-row>
</template>

<script>
import CandidateDocumentsPreview from '@/modules/candidates/components/list/CandidateDocumentsPreview'
import CandidateInfoCard from '@/modules/candidates/components/list/CandidateInfoCard'

export default {
  name: 'CandidateSideBarInfo',
  components: { CandidateDocumentsPreview, CandidateInfo: CandidateInfoCard },
  data: function () {
    return {
      activeDocuments: true,
      activeResumen: false,
    }
  },
  methods: {
    clickTab: function (tab) {
      // noinspection SpellCheckingInspection
      switch (tab) {
        case 'documentos':
          this.activeDocuments = true
          this.activeResumen = false
          break
        case 'resumen':
          this.activeDocuments = false
          this.activeResumen = true
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';
.candidates-cards-container {
  display: block !important;
  height: calc(100vh - #{$navbar-height});
  overflow-y: auto;
}
.cards-btn-container {
  .cards-btn-container {
    height: 60px;
  }
  > .vs-button {
    margin: 10px 0px;
  }
}
</style>
