import MyProfileView from '@/modules/users/views/MyProfileView'

export default [
  {
    path: '/perfil',
    name: 'MyProfile',
    meta: {
      title: 'Mi perfil',
      rule: 'employee',
    },
    component: MyProfileView,
  },
]
