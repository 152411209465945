const _ = require('lodash')

export function finalNewEmployee(store) {
  let finalEmployee = _.cloneDeep(store.newEmployee)

  return finalEmployee
}

export function finalUpdateEmployee(store) {
  let finalEmployee = _.cloneDeep(store.updateEmployee)
  return finalEmployee
}

export function getNewEmployeeErrors(store) {
  let finalNewEmployeeErrors = {
    generalInformation: {
      employeeData: [],
      job: [],
      assigment: [],
      contact: [],
    },
  }
  // Datos del candidato
  if (store.newEmployeeErrors.hasOwnProperty('curp')) {
    finalNewEmployeeErrors.generalInformation.employeeData.push({
      name: 'CURP',
      msg: store.newEmployeeErrors.curp,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('rfc')) {
    finalNewEmployeeErrors.generalInformation.candidateData.push({
      name: 'RFC',
      msg: store.newEmployeeErrors.rfc,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('nss')) {
    finalNewEmployeeErrors.generalInformation.candidateData.push({
      name: 'NSS',
      msg: store.newEmployeeErrors.nss,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('admissiondate')) {
    finalNewEmployeeErrors.generalInformation.job.push({
      name: 'Fecha de admisión',
      msg: store.newEmployeeErrors.admissiondate,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('numPayroll')) {
    finalNewEmployeeErrors.generalInformation.job.push({
      name: 'Número nómina',
      msg: store.newEmployeeErrors.numPayroll,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('jobPosition')) {
    finalNewEmployeeErrors.generalInformation.job.push({
      name: 'Posición nómina',
      msg: store.newEmployeeErrors.jobPosition,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('internalMail')) {
    finalNewEmployeeErrors.generalInformation.job.push({
      name: 'Email interno',
      msg: store.newEmployeeErrors.internalMail,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('payrollSalary')) {
    finalNewEmployeeErrors.generalInformation.job.push({
      name: 'Salario nómina',
      msg: store.newEmployeeErrors.payrollSalary,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('mixedMonthlySalary')) {
    finalNewEmployeeErrors.generalInformation.job.push({
      name: 'Salario mixto mensual',
      msg: store.newEmployeeErrors.mixedMonthlySalary,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('workArea')) {
    finalNewEmployeeErrors.generalInformation.assigment.push({
      name: 'Área labora',
      msg: store.newEmployeeErrors.workArea,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('entryTime')) {
    finalNewEmployeeErrors.generalInformation.assigment.push({
      name: 'Hora entrada',
      msg: store.newEmployeeErrors.entryTime,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('departureTime')) {
    finalNewEmployeeErrors.generalInformation.assigment.push({
      name: 'Hora salida',
      msg: store.newEmployeeErrors.departureTime,
    })
  }
  if (store.newEmployeeErrors.hasOwnProperty('phonesContactAdd')) {
    finalNewEmployeeErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.newEmployeeErrors.phonesContactAdd[0],
    })
  }
  return finalNewEmployeeErrors
}

export function getUpdateEmployeeErrors(store) {
  let finalUpdateEmployeeErrors = {
    generalInformation: {
      employeeData: [],
      job: [],
      assigment: [],
      contact: [],
    },
  }
  // Datos del candidato
  if (store.updateEmployeeErrors.hasOwnProperty('curp')) {
    finalUpdateEmployeeErrors.generalInformation.employeeData.push({
      name: 'CURP',
      msg: store.updateEmployeeErrors.curp,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('rfc')) {
    finalUpdateEmployeeErrors.generalInformation.candidateData.push({
      name: 'RFC',
      msg: store.newEmployeeErrors.rfc,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('nss')) {
    finalUpdateEmployeeErrors.generalInformation.candidateData.push({
      name: 'NSS',
      msg: store.updateEmployeeErrors.nss,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('admissiondate')) {
    finalUpdateEmployeeErrors.generalInformation.job.push({
      name: 'Fecha de admisión',
      msg: store.updateEmployeeErrors.admissiondate,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('numPayroll')) {
    finalUpdateEmployeeErrors.generalInformation.job.push({
      name: 'Número nómina',
      msg: store.updateEmployeeErrors.numPayroll,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('jobPosition')) {
    finalUpdateEmployeeErrors.generalInformation.job.push({
      name: 'Posición nómina',
      msg: store.updateEmployeeErrors.jobPosition,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('internalMail')) {
    finalUpdateEmployeeErrors.generalInformation.job.push({
      name: 'Email interno',
      msg: store.updateEmployeeErrors.internalMail,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('payrollSalary')) {
    finalUpdateEmployeeErrors.generalInformation.job.push({
      name: 'Salario nómina',
      msg: store.updateEmployeeErrors.payrollSalary,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('mixedMonthlySalary')) {
    finalUpdateEmployeeErrors.generalInformation.job.push({
      name: 'Salario mixto mensual',
      msg: store.updateEmployeeErrors.mixedMonthlySalary,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('workArea')) {
    finalUpdateEmployeeErrors.generalInformation.assigment.push({
      name: 'Área labora',
      msg: store.updateEmployeeErrors.workArea,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('entryTime')) {
    finalUpdateEmployeeErrors.generalInformation.assigment.push({
      name: 'Hora entrada',
      msg: store.updateEmployeeErrors.entryTime,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('departureTime')) {
    finalUpdateEmployeeErrors.generalInformation.assigment.push({
      name: 'Hora salida',
      msg: store.updateEmployeeErrors.departureTime,
    })
  }
  if (store.updateEmployeeErrors.hasOwnProperty('phonesContactAdd')) {
    finalUpdateEmployeeErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.updateEmployeeErrors.phonesContactAdd[0],
    })
  }
  return finalUpdateEmployeeErrors
}
