<template>
  <vs-row>
    <vs-col
      class="pdf-btn-container"
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-button
        class="block docs-pdf-btn"
        color="warning"
        type="line"
        :class="{ 'is-active': activePdfCV }"
        @click="clickTab('cv')"
      >
        CV
      </vs-button>
      <vs-button
        class="block docs-pdf-btn"
        color="warning"
        type="line"
        :class="{ 'is-active': activePdfResumen }"
        @click="clickTab('summary')"
      >
        Resumen
      </vs-button>
      <vs-button
        class="block docs-pdf-btn"
        color="warning"
        type="line"
        :class="{ 'is-active': activePdfExamen }"
        @click="clickTab('examenTecnico')"
      >
        Examen Técnico
      </vs-button>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <div class="documents-container">
        <div class="docs-tab first-tab">
          <vs-row class="pb-1">
            <vs-col vs-type="flex" vs-w="12" class="px-1">
              <pdf-reader
                v-if="activePdfCV"
                :src="selectedCandidate.candidateDocument.cv"
                type="cv"
              />
              <pdf-reader
                v-if="activePdfResumen"
                :src="selectedCandidate.candidateDocument.summary"
                type="summary"
              />
              <pdf-reader
                v-if="activePdfExamen"
                :src="selectedCandidate.candidateDocument.exam"
                type="exam"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import { mapState } from 'vuex'
import PDFReader from '@/modules/candidates/components/list/PDFReader'

export default {
  name: 'CandidateDocumentsPreview',
  components: {
    'pdf-reader': PDFReader,
  },
  data: function () {
    return {
      activePdfCV: true,
      activePdfResumen: false,
      activePdfExamen: false,
    }
  },
  computed: {
    ...mapState('candidates', { selectedCandidate: 'selectedCandidate' }),
  },
  methods: {
    clickTab: function (tab) {
      switch (tab) {
        case 'cv':
          this.activePdfResumen = false
          this.activePdfExamen = false
          this.activePdfCV = true
          break
        case 'summary':
          this.activePdfCV = false
          this.activePdfExamen = false
          this.activePdfResumen = true
          break
        case 'examenTecnico':
          this.activePdfCV = false
          this.activePdfResumen = false
          this.activePdfExamen = true
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
.documents-container {
  width: 100%;
  max-height: calc(100vh - 190px);
  overflow-y: auto;
}
.vs-button-line.is-active .vs-button-linex {
  width: 100% !important;
}
.vs-component.vs-button.block.docs-pdf-btn.vs-button-primary.vs-button-line.is-active {
  border-color: $primary;
}
.vs-component.vs-button.block.docs-pdf-btn.vs-button-warning.vs-button-line.is-active {
  border-color: $warning;
}
.vs-button-line.is-active .vs-button--icon,
.vs-button-line.is-active .vs-button--text {
  transform: translateY(2px);
}
.pdf-btn-container {
  display: flex;
  flex: 1 1 0;
  height: 60px;
  .vs-button {
    margin: 10px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
