const _ = require('lodash')

export function finalNewAssitance(store) {
  let finalAssitance = _.cloneDeep(store.newAssistance)

  return finalAssitance
}

export function finalUpdateAssistance(store) {
  let finalAssitance = _.cloneDeep(store.newAssistance)
  return finalAssitance
}
