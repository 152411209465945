import { assistancesObject } from '@/models/assistances'
const _ = require('lodash')

export default {
  setUpdateAssistance: (state, assistance) => {
    let updateAssistance = _.cloneDeep(assistance)
    state.updateAssistance = _.cloneDeep(updateAssistance)
  },

  clearNewAssistanceData: (state) => {
    state.newAssistance = _.cloneDeep(assistancesObject)
  },
  clearUpdateEmployeeData: (state) => {
    state.updateAssistance = _.cloneDeep(assistancesObject)
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setVacationSearchParams: (state, vacationSearchParams) => {
    state.vacationSearchParams = vacationSearchParams
  },
}
