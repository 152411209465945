<template>
  <vs-card class="px-2 mt-3 pt-3">
    <div slot="header" class="mb-1">
      <h2 class="mb-2">
        Registro
      </h2>
      <p class="small-font">
        Bienvenido, por favor, registra tus datos para iniciar sesión.
      </p>
    </div>
    <div class="pt-3">
      <ValidationObserver ref="observernewUser" v-slot="{ invalid }">
        <form ref="form">
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                v-model="newUser.email"
                size="large"
                type="email"
                disabled="true"
                label-placeholder="Email"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="col-inputs-full"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Contraseña"
                rules="required|min:8|max:32"
              >
                <vs-input
                  v-model="newUser.password"
                  class="mt-4 pr-1"
                  size="large"
                  type="password"
                  label-placeholder="Contraseña"
                  new-password
                  autocomplete="off"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Confirmación de contraseña"
                rules="required|confirmed:Contraseña"
              >
                <vs-input
                  v-model="newUser.password_confirmation"
                  class="mt-4"
                  size="large"
                  type="password"
                  label-placeholder="Confirmar contraseña"
                  new-password
                  autocomplete="off"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="col-inputs-full"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
              >
                <vs-input
                  v-model="newUser.name"
                  class="mt-4 pr-1"
                  size="large"
                  label-placeholder="Nombre"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="col-inputs-full"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Apellido paterno"
                rules="required"
              >
                <vs-input
                  v-model="newUser.last_name"
                  class="mt-4 pr-1"
                  size="large"
                  label-placeholder="Apellido paterno"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="col-inputs-full"
            >
              <vs-input
                v-model="newUser.second_last_name"
                class="mt-4"
                size="large"
                label-placeholder="Apellido materno"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="col-inputs-full"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Número celular"
                rules="required|numeric|min:10|max:10"
              >
                <vs-input
                  v-model="newUser.phone_number"
                  class="mt-4"
                  size="large"
                  type="phone"
                  label-placeholder="Número celular"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <vs-button
                class="btn btn-block mt-4"
                color="primary"
                size="large"
                type="filled"
                :disabled="invalid"
                @click="submit"
              >
                Registrarme
              </vs-button>
            </vs-col>
          </vs-row>
        </form>
      </ValidationObserver>
    </div>
  </vs-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getUrlVars } from '@/plugins/utils'
import jwtDecode from 'jwt-decode'
import utils from '@/utils/utils'

export default {
  name: 'RegisterForm',
  data: function () {
    return {
      newUser: {
        email: '',
        phone_number: '',
        first_name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        companyId: null,
        isCompanyAdmin: false,
      },
      submitted: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
  },
  created() {
    const token = getUrlVars('token')['token']
    const decoded = jwtDecode(token)
    this.newUser.email = decoded['email']
    this.newUser.companyId = decoded['company']
    this.newUser.isCompanyAdmin = decoded['isCompanyAdmin']
  },
  methods: {
    ...mapActions('auth', { signIn: 'signIn', singUp: 'singUp' }),
    async submit() {
      this.singUp(this.newUser)
        .then(() => {
          this.$vs.notify({
            title: 'Usuario creado.',
            text: 'Por favor, revisa tu correo para activar tu cuenta.',
            fixed: true,
            color: 'primary',
            position: 'bottom-center',
            icon: 'mail',
          })
          this.$router
            .push({
              name: 'authLogin',
            })
            .then(() => {
              this.active = false
            })
            .catch(() => {})
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            color: 'danger',
            title: 'Ocurrió un problema al crear tu cuenta',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
