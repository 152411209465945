<template>
  <div class="candidates-container">
    <vs-row
      vs-align="flex-end"
      vs-type="flex"
      vs-justify="space-between"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-w="12">
        <FilterSideBar> </FilterSideBar>
        <vs-row
          class="candidates-content"
          vs-align="flex-start"
          vs-type="flex"
          vs-w="12"
        >
          <vs-col
            class="split-container"
            vs-type="flex"
            vs-justify="center"
            vs-w="12"
          >
            <EmployeesList />
          </vs-col>
          <vs-pagination
            v-model="currentPage"
            class="pagination-container mt-3"
            :total="totalEmployeePages"
          />
        </vs-row>
      </vs-col>
    </vs-row>
    <vue-fab
      size="big"
      icon="file_download"
      :scroll-auto-hide="false"
      @clickMainBtn="popup = true"
    />
    <vs-popup :title="'Exportar reporte de empleados'" :active.sync="popup">
      <vs-row vs-justify="space-around">
        <p style="font-size: large;">
          Seleccione el rango de fechas para el reporte
        </p>
        <vs-col class="mt-1 col-inputs-full" vs-type="flex" vs-w="12">
          <label class="vs-input--label">Fecha Inicial</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-picker
            v-model="initialDate"
            class="mt-1 full-width"
            placeholder="Fecha Inicial"
            value-type="format"
            format="YYYY-MM-DD"
            :open.sync="openDatepicker"
            :disabled-date="_BeforeToday"
          >
            <template v-slot:footer>
              <button
                class="mx-btn mx-btn-text mx-2"
                @click="openDatepicker = false"
              >
                Listo
              </button>
            </template>
          </date-picker>
        </vs-col>
        <vs-col class="mt-3 col-inputs-full" vs-type="flex" vs-w="12">
          <label class="vs-input--label">Fecha Final</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-picker
            v-model="endDate"
            class="mt-1 mb-3 full-width"
            popup-class="endPicker"
            placeholder="Fecha Final"
            value-type="format"
            format="YYYY-MM-DD"
            :open.sync="openDatepickerFin"
            :disabled-date="_BeforeToday"
          >
            <template v-slot:footer>
              <button
                class="mx-btn mx-btn-text mx-2"
                @click="openDatepickerFin = false"
              >
                Listo
              </button>
            </template>
          </date-picker>
        </vs-col>
        <vs-row class="px-1 mb-3" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
            <vs-button
              class="btn-block"
              size="small"
              color="success"
              type="border"
              icon="file_download"
              :icon-after="false"
              :disabled="disabledExcel"
              @click="generateExcel"
              >Exportar a excel</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import FilterSideBar from '@/modules/employee/components/FilterSideBar'
import EmployeesList from '@/modules/employee/components/EmployeesList'

import { mapState, mapActions, mapMutations } from 'vuex'
import utils from '@/utils/utils'
import * as Excel from 'exceljs'
import * as FileSaver from 'file-saver'

const _ = require('lodash')
export default {
  name: 'EmployeeView',
  components: { EmployeesList, FilterSideBar },
  data() {
    return {
      currentPage: 1,
      disabledExcel: true,
      initialDate: null,
      openDatepicker: false,
      endDate: null,
      openDatepickerFin: false,
      popup: false,
    }
  },
  computed: {
    ...mapState('employee', {
      selectedEmployee: 'selectedEmployee',
      totalEmployeePages: 'totalEmployeePages',
      searchParams: 'searchParams',
    }),
    ...mapState('company', { company: 'company' }),
  },
  watch: {
    currentPage: function (newPage) {
      let query = `${this.searchParams}&page=${newPage}`
      this.setSkeletonLoading(true)

      this.getEmployeesListAction(query).then((res) =>
        this.formatEmployeeList(res)
      )
    },
    initialDate: function (date) {
      if (this.initialDate == null) {
        this.disabledExcel = true
      }
      if (this.endDate == null) {
        this.disabledExcel = true
      } else {
        this.disabledExcel = !this.checkDate(date, this.endDate)
      }
    },
    endDate: function (date) {
      if (this.endDate == null) {
        this.disabledExcel = true
      }
      if (this.initialDate == null) {
        this.disabledExcel = true
      } else {
        this.disabledExcel = !this.checkDate(this.initialDate, date)
      }
    },
  },
  created() {
    // Default filter, only in the process of contracting
    let query = `is_active=True`
    this.getEmployeesListAction(query).then((res) =>
      this.formatEmployeeList(res)
    )
  },
  methods: {
    ...mapActions('employee', {
      getEmployeesListAction: 'getEmployeesListAction',
      getExcelAssistanceAction: 'getExcelAssistanceAction',
    }),
    ...mapMutations('employee', {
      setTotalEmployeePages: 'setTotalEmployeePages',
      setEmployeesList: 'setEmployeesList',
      setSkeletonLoading: 'setSkeletonLoading',
    }),
    _BeforeToday(date) {
      return utils.BeforeToday(date)
    },
    formatEmployeeList(employeesList) {
      const pages = Math.ceil(employeesList.count / employeesList.pageSize)
      this.setTotalEmployeePages(pages)
      let employeesListFormated = employeesList.results.map((item) => {
        item['id'] = item['id'].toString()
        return item
      })
      this.setEmployeesList(employeesListFormated)
      this.setSkeletonLoading(false)
    },
    checkDate(date1, date2) {
      var parts = date1.split('-')
      var currentDate = new Date(parts[0], parts[1] - 1, parts[2])
      parts = date2.split('-')
      var finalDate = new Date(parts[0], parts[1] - 1, parts[2])
      if (currentDate < finalDate) {
        return true
      } else {
        return false
      }
    },
    generateExcel() {
      Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }

      function getDates(startDate, stopDate) {
        var dateArray = new Array()
        var parts = startDate.split('-')
        var currentDate = new Date(parts[0], parts[1] - 1, parts[2])
        parts = stopDate.split('-')
        var finalDate = new Date(parts[0], parts[1] - 1, parts[2])
        while (currentDate <= finalDate) {
          dateArray.push(formatDate(new Date(currentDate)))
          currentDate = currentDate.addDays(1)
        }
        return dateArray
      }

      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      }
      let data = {
        initial_date: this.initialDate,
        final_date: this.endDate,
        company: this.company.id,
      }
      this.getExcelAssistanceAction(data).then((result) => {
        let dates = getDates(this.initialDate, this.endDate)
        let datesTemp = _.cloneDeep(dates)
        dates.unshift('No Nomina', 'Empleado', 'Asistencia')

        let title = 'Reporte de asistencias'

        let workbook = new Excel.Workbook()

        let worksheet = workbook.addWorksheet('Reporte')

        let titleRow = worksheet.addRow([title])

        titleRow.font = { name: 'Calibri', family: 4, size: 16, bold: true }
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.mergeCells('A1:H1')

        worksheet.addRow()
        worksheet.addRow()

        let infoCell = null
        infoCell = worksheet.getCell('C4')
        infoCell.value = 'No contratado'
        infoCell = worksheet.getCell('D4')
        infoCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '808080' },
        }
        infoCell = worksheet.getCell('F4')
        infoCell.value = 'Vacaciones'
        infoCell = worksheet.getCell('G4')
        infoCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8FBC8F' },
        }
        infoCell = worksheet.getCell('I4')
        infoCell.value = 'Ausente'
        infoCell = worksheet.getCell('J4')
        infoCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'BF8282' },
        }

        worksheet.addRow()
        worksheet.addRow()
        worksheet.addRow()
        worksheet.addRow()

        let headerRow = worksheet.addRow(dates)

        let count = 1
        headerRow.eachCell((cell) => {
          if (count == 1 || count == 2) {
            worksheet.getColumn(count).width = 25
          } else {
            worksheet.getColumn(count).width = 15
          }
          count += 1
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF808080' },
            bgColor: { argb: 'FF0000FF' },
          }

          cell.font = {
            name: 'Calibri',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: '00000000' },
          }

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        })
        let cell = null
        let employeeCount = 10
        result.map((employee) => {
          if (employee.vacations != null) {
            employee.vacations.map((vac) => {
              vac.range = getDates(vac.initialDate, vac.finalDate)
            })
          }
          cell = worksheet.getCell('A' + employeeCount)
          cell.value = employee.payroll
          worksheet.mergeCells('A' + employeeCount + ':A' + (employeeCount + 1))
          cell.alignment = { vertical: 'middle', horizontal: 'left' }
          cell = worksheet.getCell('B' + employeeCount)
          cell.value =
            employee.name +
            ' ' +
            employee.lastName +
            ' ' +
            employee.secondLastName
          cell.alignment = { vertical: 'middle', horizontal: 'left' }
          worksheet.mergeCells('B' + employeeCount + ':B' + (employeeCount + 1))
          cell = worksheet.getCell('C' + employeeCount)
          cell.value = 'Entrada'
          cell.alignment = { vertical: 'middle', horizontal: 'left' }
          cell = worksheet.getCell('C' + (employeeCount + 1))
          cell.value = 'Salida'
          cell.alignment = { vertical: 'middle', horizontal: 'left' }
          let ascii = 68
          let ascii1 = 64
          let columnCount1 = true
          let employeeDateCount = 0
          let employeeVacCount = 0
          let employeeVacRangeCount = 0
          datesTemp.map((column) => {
            // Se pone gris ya que aun no es empleado
            if (!this.checkDate(employee.firstDay, column)) {
              if (columnCount1) {
                cell = worksheet.getCell(
                  String.fromCharCode(ascii) + employeeCount
                )
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '808080' },
                }
                cell = worksheet.getCell(
                  String.fromCharCode(ascii) + (employeeCount + 1)
                )
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '808080' },
                }
              } else {
                cell = worksheet.getCell(
                  String.fromCharCode(ascii1) +
                    String.fromCharCode(ascii) +
                    employeeCount
                )
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '808080' },
                }
                cell = worksheet.getCell(
                  String.fromCharCode(ascii1) +
                    String.fromCharCode(ascii) +
                    +(employeeCount + 1)
                )
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '808080' },
                }
              }
            } else {
              // Se pone rojo ya que no tiene asistencias registradas
              if (employee.assistances == null) {
                if (columnCount1) {
                  cell = worksheet.getCell(
                    String.fromCharCode(ascii) + employeeCount
                  )
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'BF8282' },
                  }
                  cell = worksheet.getCell(
                    String.fromCharCode(ascii) + (employeeCount + 1)
                  )
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'BF8282' },
                  }
                } else {
                  cell = worksheet.getCell(
                    String.fromCharCode(ascii1) +
                      String.fromCharCode(ascii) +
                      employeeCount
                  )
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'BF8282' },
                  }
                  cell = worksheet.getCell(
                    String.fromCharCode(ascii1) +
                      String.fromCharCode(ascii) +
                      +(employeeCount + 1)
                  )
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'BF8282' },
                  }
                }
              } else {
                // Se pone la fecha de asitencia si coencide con el dia de la columna
                employee.assistances.sort((a, b) => {
                  if (a.workingDate < b.workingDate) {
                    return -1
                  }
                  return 1
                })
                if (
                  employee.assistances[employeeDateCount].workingDate == column
                ) {
                  if (columnCount1) {
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii) + employeeCount
                    )
                    cell.value =
                      employee.assistances[employeeDateCount].entryTime
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii) + (employeeCount + 1)
                    )
                    cell.value =
                      employee.assistances[employeeDateCount].exitTime
                  } else {
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii1) +
                        String.fromCharCode(ascii) +
                        employeeCount
                    )
                    cell.value =
                      employee.assistances[employeeDateCount].entryTime
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii1) +
                        String.fromCharCode(ascii) +
                        +(employeeCount + 1)
                    )
                    cell.value =
                      employee.assistances[employeeDateCount].exitTime
                  }
                  if (employee.assistances.length > employeeDateCount + 1) {
                    employeeDateCount += 1
                  }
                } else {
                  // se pone rojo si no coencide con el dia de la columna
                  if (columnCount1) {
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii) + employeeCount
                    )
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'BF8282' },
                    }
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii) + (employeeCount + 1)
                    )
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'BF8282' },
                    }
                  } else {
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii1) +
                        String.fromCharCode(ascii) +
                        employeeCount
                    )
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'BF8282' },
                    }
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii1) +
                        String.fromCharCode(ascii) +
                        +(employeeCount + 1)
                    )
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'BF8282' },
                    }
                  }
                }
              }
              // si el empleado tiene vavaciones checa si aplica en la fecha
              if (employee.vacations != null) {
                if (
                  this.checkDate(
                    employee.vacations[employeeVacCount].range[
                      employeeVacRangeCount
                    ],
                    column
                  )
                ) {
                  for (
                    let i = employeeVacRangeCount;
                    i < employee.vacations[employeeVacCount].range.length;
                    i++
                  ) {
                    if (
                      this.checkDate(
                        employee.vacations[employeeVacCount].range[i],
                        column
                      )
                    ) {
                      employeeVacRangeCount += 1
                    }
                  }
                }
                if (
                  employee.vacations[employeeVacCount].range[
                    employeeVacRangeCount
                  ] == column
                ) {
                  employeeVacRangeCount += 1
                  if (columnCount1) {
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii) + employeeCount
                    )
                    cell.value = ''
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '8FBC8F' },
                    }
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii) + (employeeCount + 1)
                    )
                    cell.value = ''
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '8FBC8F' },
                    }
                  } else {
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii1) +
                        String.fromCharCode(ascii) +
                        employeeCount
                    )
                    cell.value = ''
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '8FBC8F' },
                    }
                    cell = worksheet.getCell(
                      String.fromCharCode(ascii1) +
                        String.fromCharCode(ascii) +
                        +(employeeCount + 1)
                    )
                    cell.value = ''
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '8FBC8F' },
                    }
                  }
                }
                if (
                  employee.vacations[employeeVacCount].range.length ==
                  employeeVacRangeCount
                ) {
                  employeeVacRangeCount = 0
                  if (employee.vacations.length > employeeVacCount + 1) {
                    employeeVacCount += 1
                  }
                }
              }
            }
            ascii += 1
            if (ascii > 90) {
              ascii = 65
              columnCount1 = false
              ascii1 += 1
            }
          })
          employeeCount += 2
        })

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          FileSaver.saveAs(
            blob,
            'Reporte de asistencias ' +
              this.initialDate +
              ' al ' +
              this.endDate +
              '.xlsx'
          )
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
.split-container .split {
  height: calc(100vh - #{$navbar-height}) !important;

  .split.split-horizontal .list-candidates-container {
    height: calc(100vh - #{$navbar-height} - 85px);
    overflow: auto;
  }
}
.pagination-container .vs-col.vs-pagination--mb {
  justify-content: center !important;
}
.mx-datepicker-main {
  z-index: 40001 !important;
}
</style>
