<template>
  <vs-card class="px-2 py-3">
    <div slot="header" class="mb-2">
      <h2 class="mb-1">
        Iniciar Sesión
      </h2>
      <p class="small-font">
        Bienvenido de nuevo, por favor inicia sesión.
      </p>
    </div>
    <div class="pb-3">
      <form ref="form">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              v-model="user.email"
              size="large"
              type="email"
              label-placeholder="Email"
              autocomplete="username"
              :danger="emailError"
              :danger-text="emailErrorMsg"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              v-model="user.password"
              class="mt-4"
              size="large"
              label-placeholder="Contraseña"
              type="password"
              autocomplete="current-password"
              :danger="passwordError"
              :danger-text="passwordErrorMsg"
              @keyup.enter="submit"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-align="center" vs-w="12">
            <router-link class="ml-auto mt-3" :to="{ name: 'recoverPassword' }">
              Olvidé mi Contraseña
            </router-link>
          </vs-col>
          <vs-col vs-type="flex" vs-align="center" vs-w="12">
            <vs-button
              class="btn btn-block mt-3"
              color="primary"
              size="large"
              type="filled"
              @click="submit"
            >
              Iniciar Sesión
            </vs-button>
          </vs-col>
        </vs-row>
      </form>
    </div>
  </vs-card>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { roles } from '@/utils/utils'
import utils from '@/utils/utils'
import { getUrlVars } from '@/plugins/utils'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      user: {
        email: '',
        password: '',
      },
      emailError: false,
      emailErrorMsg: null,
      passwordError: false,
      passwordErrorMsg: null,
    }
  },
  computed: {
    ...mapState('auth', {
      error: 'error',
      errorMessages: 'errorMessages',
    }),
    ...mapGetters('auth', ['isLogged']),
  },
  watch: {
    errorMessages: function (errorMessages) {
      if (errorMessages.email) {
        this.emailError = true
        this.emailErrorMsg = errorMessages.email[0]
      } else {
        this.emailError = false
      }
      if (errorMessages.password) {
        this.passwordError = true
        this.passwordErrorMsg = errorMessages.password[0]
      } else {
        this.passwordError = false
      }
      if (errorMessages.detail) {
        this.emailError = true
        this.passwordError = true
      }
    },
  },
  created() {
    if (this.isLogged) {
      this.$router
        .push({
          name: 'candidatesList',
        })
        .catch(() => {})
    }
    // Validates if is an account activation
    const token = getUrlVars('token')['token']
    if (token !== undefined) {
      this.userActivation(token)
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Cuenta activada',
            text: 'Ahora puedes iniciar sesión.',
            position: 'top-right',
          })
          window.history.pushState({}, document.title, '/auth/login')
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title: 'Verifique sus credenciales de inicio',
            text: `${res}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    }
  },
  methods: {
    ...mapActions('auth', {
      getToken: 'getToken',
      userActivation: 'userActivation',
      me: 'me',
    }),
    ...mapMutations('auth', {
      setUser: 'setUser',
    }),
    ...mapMutations('company', {
      setCompany: 'setCompany',
    }),
    async submit() {
      this.getToken(this.user)
        .then(() => {
          this.me().then((response) => {
            this.setUser(response)
            this.setCompany(response.userprofile.company)
            this.$acl.change(response.userprofile.role)
            let ruta
            if (
              response.userprofile.role === roles.ADMIN ||
              response.userprofile.role === roles.RECRUITER
            ) {
              ruta = 'candidatesList'
            } else {
              ruta = 'assitance'
            }
            this.$router
              .push({
                name: ruta,
              })
              .catch()
          })
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title: 'Verifique sus credenciales de inicio',
            text: `${res}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
  },
}
</script>
