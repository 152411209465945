import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './filters/filters'
import 'material-icons/iconfont/material-icons.css'

require('./scss/main.scss')
require('./plugins')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
