<template>
  <div class="container">
    <vs-row
      class="full-height"
      vs-align="center"
      vs-type="flex"
      vs-justify="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-xs="12"
        vs-sm="10"
        vs-lg="8"
      >
        <vs-card class="px-2 mt-3 pt-3">
          <div slot="header" class="mb-1">
            <h2 class="mb-2">Registro</h2>
            <p class="small-font">
              Bienvenido, por favor, registra tu contraseña para iniciar sesión.
            </p>
          </div>
          <div class="pt-3">
            <ValidationObserver ref="observernewUser" v-slot="{ invalid }">
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                  class="col-inputs-full"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Contraseña"
                    rules="required|min:8|max:32"
                  >
                    <vs-input
                      v-model="user.password"
                      class="mt-4 pr-1"
                      size="large"
                      type="password"
                      label-placeholder="Contraseña"
                      new-password
                      autocomplete="off"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Confirmación de contraseña"
                    rules="required|confirmed:Contraseña"
                  >
                    <vs-input
                      v-model="user.verifyPassword"
                      class="mt-4"
                      size="large"
                      type="password"
                      label-placeholder="Confirmar contraseña"
                      new-password
                      autocomplete="off"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </ValidationProvider>
                </vs-col>
                <vs-col vs-type="flex" vs-align="center" vs-w="12">
                  <vs-button
                    class="btn btn-block mt-4"
                    color="primary"
                    size="large"
                    type="filled"
                    :disabled="invalid"
                    @click="submit"
                  >
                    Registrarme
                  </vs-button>
                </vs-col>
              </vs-row>
            </ValidationObserver>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getUrlVars } from '@/plugins/utils'
import jwtDecode from 'jwt-decode'
import utils from '@/utils/utils'

export default {
  name: 'RegisterForm',
  data: function () {
    return {
      user: {},
      submitted: false,
      verifyPassword: '',
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
  },
  created() {
    const token = getUrlVars('token')['token']
    const decoded = jwtDecode(token)
    this.user = {
      user: decoded['user'],
      token: token,
      password: '',
    }
  },
  methods: {
    ...mapActions('auth', { newPassword: 'newPassword' }),
    submit() {
      this.newPassword(this.user)
        .then(() => {
          this.$vs.notify({
            title: 'Usuario creado.',
            text: 'Ya puede iniciar sesión',
            fixed: true,
            color: 'primary',
            position: 'bottom-center',
            icon: 'mail',
          })
          this.$router
            .push({
              name: 'authLogin',
            })
            .then(() => {
              this.active = false
            })
            .catch(() => {})
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            color: 'danger',
            title: 'Ocurrió un problema al crear tu cuenta',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
