export let employeeDocuments = [
  {
    name: 'RFC',
    type: 'RFC',
    order: 1,
    document: null,
  },
  {
    document: null,
    name: 'CURP',
    type: 'CURP',
    order: 2,
  },
  {
    document: null,
    name: 'INE (Anverso)',
    type: 'INE_1',
    order: 3,
  },
  {
    document: null,
    name: 'INE (Reverso)',
    type: 'INE_2',
    order: 4,
  },
  {
    document: null,
    name: 'Comprobante de Estudios',
    type: 'ESTUDIOS',
    order: 5,
  },
  {
    document: null,
    name: 'NSS',
    type: 'NSS',
    order: 6,
  },
  {
    document: null,
    name: 'Acta de Nacimiento',
    type: 'NACIMIENTO',
    order: 7,
  },
  {
    document: null,
    name: 'Comprobante de Domicilio',
    type: 'DOMICILIO',
    order: 8,
  },
  {
    document: null,
    name: 'Cuenta Clabe 1',
    type: 'CLABE_1',
    order: 9,
  },
  {
    document: null,
    name: 'Cuenta Clabe 2',
    type: 'CLABE_2',
    order: 10,
  },
  {
    document: null,
    name: 'Cuenta Clabe 3',
    type: 'CLABE_3',
    order: 11,
  },
  {
    document: null,
    name: 'CV',
    type: 'CV',
    order: 12,
  },
  {
    document: null,
    name: 'Constancia Laboral',
    type: 'LABORAL',
    order: 13,
  },
  {
    document: null,
    name: 'Cédula Profesional',
    type: 'PROFESIONAL',
    order: 14,
  },
]
