<template>
  <div id="app">
    <navbar v-if="isLogged" />
    <main-sidebar v-if="isLogged" />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import MainSideBar from '@/components/MainSideBar'

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    navbar: NavBar,
    'main-sidebar': MainSideBar,
  },
  computed: {
    ...mapState(['activeLoading', 'loadingPageMsg']),
    ...mapGetters('auth', { isLogged: 'isLogged' }),
  },
  watch: {
    activeLoading: function (isLoading) {
      // Don't use Arrow Functions for this case
      if (isLoading) {
        this.$vs.loading({
          text: this.loadingPageMsg,
        })
      } else {
        this.$vs.loading.close()
      }
    },
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  created() {
    if (this.isLogged) {
      this.me().then((response) => {
        this.setUser(response)
        this.setCompany(response.userprofile.company)
        this.$acl.change(response.userprofile.role)
      })
    }
  },
  methods: {
    ...mapActions('auth', {
      me: 'me',
    }),
    ...mapMutations('auth', {
      setUser: 'setUser',
    }),
    ...mapMutations('company', {
      setCompany: 'setCompany',
    }),
  },
}
</script>

<style>
main {
  padding-top: 55px;
}
</style>
