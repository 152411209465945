<template>
  <div class="experiencia-profesional-info-tabs">
    <!-- Experiencia profesional-->
    <vs-card class="pt-3 px-1">
      <div slot="header">
        <h2>
          Experiencia profesional
        </h2>
      </div>
      <ValidationObserver ref="observerDatosCandidato" v-slot="{ invalid }">
        <div>
          <vs-row
            v-for="(job, index) in updateCandidate.jobExperienceAdd"
            :key="job.id"
            class="update-candidate-container mb-4"
          >
            <vs-col class="mt-2 px-1 col-inputs-full" vs-type="flex" vs-w="12">
              <h3>Trabajo {{ index + 1 }}</h3>
            </vs-col>
            <!-- Empresa -->
            <vs-col
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Empresa"
                rules="required"
              >
                <vs-input
                  v-model="job.company"
                  label-placeholder="Empresa"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <!-- Puesto laboral -->
            <vs-col
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Puesto"
                rules="required"
              >
                <vs-col
                  class="col-inputs-full mt-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="10"
                >
                  <vs-select
                    v-model="job.jobAdd"
                    autocomplete
                    class="block col-inputs-full"
                    label="Puesto"
                    placeholder="Elija un puesto laboral"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  >
                    <vs-select-item
                      v-for="(item, indexJob) in jobCatalog"
                      :key="indexJob"
                      :value="item.id"
                      :text="item.name"
                    />
                  </vs-select>
                </vs-col>
                <vs-col
                  class="col-inputs-full mt-3 pt-1"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="2"
                >
                  <vs-icon
                    icon="add"
                    color="success"
                    class="cursor-pointer mt-3"
                    @click="newCatalog('job')"
                  ></vs-icon>
                </vs-col>
              </ValidationProvider>
            </vs-col>
            <!-- Sueldo -->
            <vs-col
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Sueldo"
                rules="required|numeric"
              >
                <vs-input
                  v-model="job.lastSalaryReceived"
                  label-placeholder="Sueldo"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <!-- Periodo de inicio -->
            <vs-col
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Fecha de inicio"
                rules="required"
              >
                <label class="vs-select--label">Fecha de inicio</label>
                <date-picker
                  v-model="job.startDate"
                  class="date-size"
                  type="text"
                  value-type="format"
                  :max-date="new Date()"
                  format="YYYY-MM-DD"
                  :editable="false"
                  append-to-body
                  input-class="vs-inputx vs-input--input normal hasValue"
                  placeholder="Ingrese una fecha (Obligatorio)"
                  confirm-text="Listo"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  @dayclick="jobExperienceStartDateDPClicked"
                  @click="activeJobExperience = job"
                >
                </date-picker>
              </ValidationProvider>
            </vs-col>
            <!-- En curso -->
            <vs-col
              class="mt-2 px-1"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <label class="px-3">En curso</label>
              <vs-switch
                v-model="job.inProgress"
                color="success"
                vs-icon="done"
              />
            </vs-col>
            <!-- Periodo de fin -->
            <vs-col
              v-if="!job.inProgress"
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Fecha de fin"
                rules="required"
              >
                <label class="vs-select--label">Fecha de fin</label>
                <date-picker
                  v-model="job.endDate"
                  class="date-size"
                  type="text"
                  value-type="format"
                  format="YYYY-MM-DD"
                  :editable="false"
                  append-to-body
                  input-class="vs-inputx vs-input--input normal hasValue"
                  placeholder="Ingrese una fecha (Obligatorio)"
                  confirm-text="Listo"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  @dayclick="jobExperienceEndDateDPClicked"
                  @click="activeJobExperience = job"
                >
                </date-picker>
              </ValidationProvider>
            </vs-col>
            <!-- Razón renuncia -->
            <vs-col
              v-if="!job.inProgress"
              class="mt-2 px-1 col-inputs-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Razón renuncia"
                rules="required"
              >
                <vs-col
                  class="col-inputs-full mt-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="10"
                >
                  <vs-select
                    v-model="job.reasonForWithdrawal"
                    autocomplete
                    class="block col-inputs-full"
                    label="Razón renuncia"
                    placeholder="Elija una razón de la renuncia"
                    val-icon-danger="error"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  >
                    <vs-select-item
                      v-for="(item, indexReason) in reasonForWithdrawalCatalog"
                      :key="indexReason"
                      :value="item.id"
                      :text="item.reason"
                    />
                  </vs-select>
                </vs-col>
                <vs-col
                  class="col-inputs-full mt-3 pt-1"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="2"
                >
                  <vs-icon
                    icon="add"
                    color="success"
                    class="cursor-pointer mt-3"
                    @click="newCatalog('reasonForWithdrawal')"
                  ></vs-icon>
                </vs-col>
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
        <div slot="footer">
          <vs-row class="footer-card" vs-justify="space-between">
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="center"
              vs-md="12"
              vs-lg="6"
            >
              <vs-button
                class="mr-2 btn-lg"
                type="gradient"
                color="success"
                icon="add"
                @click="addJobExperience"
              >
                Agregar empleo
              </vs-button>
              <vs-button
                class="mr-2"
                type="gradient"
                color="danger"
                icon="add"
                @click="removeJobExperience"
              >
                Eliminar empleo
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-md="12"
              vs-lg="6"
            >
              <vs-button
                color="primary"
                icon="turned_in_not"
                :disabled="invalid"
                @click="
                  onClickSaveAndContinue('jobExperience', 'jobExperience')
                "
              >
                Guardar empleo
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </ValidationObserver>
    </vs-card>
    <!-- Comentarios -->
    <vs-card class="pt-3 px-1 mb-5 mt-5">
      <div slot="header">
        <h2>
          Comentarios
        </h2>
      </div>
      <div>
        <vs-row class="update-candidate-container mb-4">
          <vs-col
            class="mt-2 px-1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-textarea
              v-model="updateCandidate.professionalExperienceComments"
              label="Comentarios u Observaciones de esta sección"
            />
          </vs-col>
        </vs-row>
      </div>
      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button
            color="primary"
            icon="turned_in_not"
            @click="onClickSaveAndContinue('jobExperience', 'comments')"
          >
            Guardar comentarios
          </vs-button>
        </vs-row>
      </div>
    </vs-card>
    <ValidationObserver ref="observerNewCatalog" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="newCatalogPopUp"
        :title="title"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        @accept="saveNewCatalog"
      >
        <div>
          <vs-row>
            <vs-col class="mx-1 mt-1" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
                tag="div"
                class="mt-2"
              >
                <vs-input
                  v-model="nameNewCatalog"
                  label-placeholder="Nombre"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import utils from '@/utils/utils'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ExperienciaProfesionalForm',
  props: {
    updateCandidate: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      jobCatalog: [],
      reasonForWithdrawalCatalog: [],
      activeJobExperience: null,
      newCatalogPopUp: false,
      title: '',
      typeNewCatalog: '',
      nameNewCatalog: '',
    }
  },
  computed: {
    ...mapState('company', { company: 'company' }),
  },
  created() {
    this.getJobCatalog('is_active=True').then((response) => {
      this.jobCatalog = response
    })

    this.getReasonForWithdrawalCatalog('is_active=True').then((response) => {
      this.reasonForWithdrawalCatalog = response
    })
  },
  methods: {
    ...mapActions('catalogs', {
      getJobCatalog: 'getJobCatalog',
      getReasonForWithdrawalCatalog: 'getReasonForWithdrawalCatalog',
      newItemCatalog: 'newItemCatalog',
    }),
    ...mapMutations('candidates', {
      setUpdatCandidateProfessionalExperienceJob:
        'setUpdatCandidateProfessionalExperienceJob',
      setUpdateCandidateProfessionalExperienceComments:
        'setUpdateCandidateProfessionalExperienceComments',
    }),
    onClickSaveAndContinue(section, card) {
      switch (card) {
        case 'jobExperience':
          this.setUpdatCandidateProfessionalExperienceJob(this.updateCandidate)
          break
        case 'comments':
          this.setUpdateCandidateProfessionalExperienceComments(
            this.updateCandidate
          )
          break
      }
      this.$vs.notify({
        title: 'Tarjeta actualizada',
        color: 'success',
        position: 'bottom-center',
      })
    },
    addJobExperience() {
      this.updateCandidate.jobExperienceAdd.push({
        id: utils.randomId(),
        schoolAdd: '',
        careerAdd: '',
        schoolStatus: '',
        startDate: '',
        endDate: '',
      })
    },
    removeJobExperience() {
      this.updateCandidate.jobExperienceAdd.pop()
    },
    jobExperienceStartDateDPClicked(day) {
      this.updateCandidate.jobExperienceAdd.forEach((job) => {
        if (job.id === this.activeJobExperience.id) {
          job.startDate = day.id
        }
      })
    },
    jobExperienceEndDateDPClicked(day) {
      this.updateCandidate.jobExperienceAdd.forEach((job) => {
        if (job.id === this.activeJobExperience.id) {
          job.endDate = day.id
        }
      })
    },
    newCatalog(type) {
      if (type == 'job') {
        this.title = 'Nuevo puesto'
      }
      if (type == 'reasonForWithdrawal') {
        this.title = 'Nueva razón de renuncia'
      }
      this.nameNewCatalog = ''
      this.newCatalogPopUp = true
      this.typeNewCatalog = type
    },
    saveNewCatalog() {
      if (this.typeNewCatalog == 'job') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'jobs',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getJobCatalog().then((response) => {
              this.$vs.loading.close()
              this.jobCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'reasonForWithdrawal') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'withdrawal-reason',
          data: {
            reason: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getReasonForWithdrawalCatalog().then((response) => {
              this.$vs.loading.close()
              this.reasonForWithdrawalCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
    },
  },
}
</script>

<style scoped>
.date-size {
  width: 100% !important;
}
</style>
