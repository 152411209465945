<template>
  <vs-row
    class="new-candidate-container container"
    type="flex"
    vs-justify="center"
  >
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <vs-tabs alignment="center">
        <!--Información general -->
        <vs-tab label="Información General">
          <InformacionGeneralForm
            ref="informacionGeneralFormComponent"
            :new-candidate="newCandidate"
          />
        </vs-tab>
        <!-- Estudios -->
        <vs-tab label="Estudios">
          <EstudiosForm :new-candidate="newCandidate" />
        </vs-tab>
        <!-- Experiencia personal -->
        <vs-tab label="Experiencia Profesional">
          <ExperienciaProfesionalForm :new-candidate="newCandidate" />
        </vs-tab>
        <!-- Personalidad -->
        <vs-tab label="Personalidad">
          <PersonalidadForm :new-candidate="newCandidate" />
        </vs-tab>
        <!-- Documentos y Evaluación -->
        <vs-tab label="Documentos y Evaluación">
          <DocumentsForm :new-candidate="newCandidate" />
        </vs-tab>
      </vs-tabs>
    </vs-col>
    <vue-fab
      size="big"
      icon="done"
      main-btn-color="#388e3c"
      :scroll-auto-hide="false"
      @clickMainBtn="onClickNewCandidate"
    >
    </vue-fab>
  </vs-row>
</template>

<script>
import InformacionGeneralForm from '@/modules/candidates/components/new/InformacionGeneralForm'
import EstudiosForm from '@/modules/candidates/components/new/EstudiosForm'
import ExperienciaProfesionalForm from '@/modules/candidates/components/new/ExperienciaProfesionalForm'
import PersonalidadForm from '@/modules/candidates/components/new/PersonalidadForm'
import DocumentsForm from '@/modules/candidates/components/new/DocumentsForm'

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'NewCandidateView',
  components: {
    InformacionGeneralForm,
    EstudiosForm,
    ExperienciaProfesionalForm,
    PersonalidadForm,
    DocumentsForm,
  },
  data: function () {
    return {
      inputError: false,
    }
  },
  computed: {
    ...mapState('candidates', {
      newCandidate: 'newCandidate',
      newCandidateFormsProgress: 'newCandidateFormsProgress',
      candidateDocs: 'candidateDocs',
    }),
    ...mapGetters('candidates', {
      finalNewCandidate: 'finalNewCandidate',
    }),
  },
  mounted() {
    if (
      document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0] != undefined
    ) {
      let element = document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0]
      element.parentNode.removeChild(element)
    }
  },
  methods: {
    ...mapActions('candidates', {
      newCandidateAction: 'newCandidateAction',
      saveCandidateDocs: 'saveCandidateDocs',
    }),
    ...mapMutations('candidates', {
      clearNewCandidateData: 'clearNewCandidateData',
      setNewCandidateErrors: 'setNewCandidateErrors',
    }),
    async validateFormsProgress() {
      // Valida sección Información General
      if (
        this.newCandidateFormsProgress.generalInformation.candidateData ===
        false
      ) {
        throw new Error('Información general > Datos del candidato')
      }

      if (
        this.newCandidateFormsProgress.generalInformation.benefits === false
      ) {
        throw new Error('Información general > Prestaciones')
      }
      if (this.newCandidateFormsProgress.generalInformation.contact === false) {
        throw new Error('Información general > Contacto')
      }
      if (this.newCandidateFormsProgress.generalInformation.address === false) {
        throw new Error('Información general > Dirección')
      }
      // Valida sección Estudios
      if (this.newCandidateFormsProgress.studies.scholarship === false) {
        throw new Error('Estudios > Escolaridad')
      }
      if (this.newCandidateFormsProgress.studies.languages === false) {
        throw new Error('Estudios > Idiomas')
      }
      if (this.newCandidateFormsProgress.studies.certifications === false) {
        throw new Error('Estudios > Certificaciones')
      }
      if (this.newCandidateFormsProgress.studies.courses === false) {
        throw new Error('Estudios > Cursos')
      }

      // Valida sección Experiencia profesional
      if (
        this.newCandidateFormsProgress.jobExperience.jobExperience === false
      ) {
        throw new Error('Experiencia profesional > Trabajo')
      }

      // Valida sección Personaldiad
      if (this.newCandidateFormsProgress.personality.qualities === false) {
        throw new Error('Personalidad > Cualidades')
      }
      if (this.newCandidateFormsProgress.personality.opportunities === false) {
        throw new Error('Personalidad > Oportunidades')
      }
      if (this.newCandidateFormsProgress.personality.perceptions === false) {
        throw new Error('Personalidad > Percepciones')
      }

      // Valida sección de Documentos y evaluación
      if (this.newCandidateFormsProgress.docs.ratings === false) {
        throw new Error('Documentos y Evaluación > Evaluaciones')
      }
    },
    onClickNewCandidate() {
      this.validateFormsProgress()
        .then(() => {
          this.$vs.loading({
            text: 'Creando candidato...',
          })
          this.newCandidateAction(this.finalNewCandidate)
            .then((res) => {
              this.$vs.loading.close()
              // noinspection JSUnresolvedFunction
              this.$vs.loading({
                text: 'Almacenando archivo ...',
              })
              this.saveCandidateDocs({
                formData: this.candidateDocs,
                id: res['candidateDocument']['id'],
              }).then(() => {
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Éxito.',
                  text: 'Documentos almacenados',
                })
              })
              this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: `Candidato creado`,
                text: '¿Desea agregar otro candidato?',
                accept: this.goToCreateNewCandidate,
                cancel: this.goToCandidatesPage,
                acceptText: 'Nuevo candidato',
                cancelText: 'Ir a candidatos',
              })
            })
            .catch((error) => {
              this.$vs.loading.close()
              let res = ''
              if (error.response) {
                res = utils.getErrorDetails(error.response.data.errors)
              } else {
                res = error.message
              }
              this.$vs.notify({
                title:
                  'Revise los campos señalados, guárdelos y reintente nuevamente.',
                text: `${res}`,
                color: 'danger',
                fixed: true,
                position: 'top-right',
              })
            })
        })
        .catch((err) => {
          this.$vs.notify({
            title:
              'Oops... Aún no has terminado de rellenar la siguiente tarjeta:',
            text: err.message,
            color: 'warning',
            position: 'top-right',
          })
        })
    },
    goToCreateNewCandidate() {
      this.clearNewCandidateData()
      this.$forceUpdate()
    },
    goToCandidatesPage() {
      this.clearNewCandidateData()
      this.$router.push({
        name: 'candidatesList',
      })
    },
    resetObserverValidations() {
      this.$refs.informacionGeneralFormComponent._resetObserverValidations()
    },
  },
}
</script>

<style lang="scss">
.new-candidate-container {
  margin: 0 auto;
}
.block {
  width: 100% !important;
}
.candidate-cards-container {
  overflow-y: auto;
}
.vs-input-number .vs-input-number--input {
  width: auto !important;
}
.vs-tabs--content {
  min-height: 700px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
