<template>
  <vs-row vs-w="12" class="list-candidates-container">
    <vs-col vs-type="flex" vs-justify="center" vs-w="12">
      <vs-list class="candidates-list pt-3 overflow-auto">
        <vcl-bullet-list v-if="skeletonLoading" class="mt-4" />
        <virtual-list
          v-else
          class="item-candidate-container"
          :data-key="'id'"
          :data-sources="employeesList"
          :data-component="employeesItem"
        />

        <vs-alert
          class="mt-3"
          title="Ops.."
          :active="employeesList.length === 0 && skeletonLoading === false"
          color="warning"
        >
          Sin resultados
        </vs-alert>
      </vs-list>
    </vs-col>
    <vs-popup :title="'Agregar vacaciones'" :active.sync="popupVacation">
      <vs-row vs-justify="space-around">
        <p style="font-size: x-large;">
          Vacaciones del empleado {{ employeeVac.name }}
        </p>
        <vs-col class="mt-2" vs-type="flex" vs-justify="space-around" vs-w="12">
          <v-date-picker
            v-model="vacationsDates"
            :attributes="employeeCalendarVacationsAttributes"
            :masks="masks"
            is-range
          >
            <template v-slot="{ inputValue, inputEvents }">
              <vs-row vs-align="center">
                <vs-col vs-w="50%">
                  <vs-input
                    :value="inputValue.start"
                    readonly="true"
                    v-on="inputEvents.start"
                  />
                </vs-col>
                <vs-col vs-w="auto" vs-align="center">
                  <svg
                    style="
                      width: 12px;
                      height: 12px;
                      margin-right: 8px;
                      padding-left: 5px;
                      stroke: #6b7280;
                    "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </vs-col>
                <vs-col vs-w="50%">
                  <vs-input
                    :value="inputValue.end"
                    readonly="true"
                    v-on="inputEvents.end"
                  />
                </vs-col>
              </vs-row>
            </template>
          </v-date-picker>
        </vs-col>
        <vs-col
          class="mt-3 px-1 col-inputs-full"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            style="width: 100%;"
            color="primary"
            @click="createEmployeeVacation"
            >Crear
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </vs-row>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { VclBulletList } from 'vue-content-loading'
import EmployeesItem from './EmployeesItem'
import VirtualList from 'vue-virtual-scroll-list'

export default {
  name: 'CandidatesList',
  components: {
    'vcl-bullet-list': VclBulletList,
    'virtual-list': VirtualList,
  },
  data: function () {
    return {
      employeesItem: EmployeesItem,
      vacationsDates: {},
      disabledDates: [],
      employeeCalendarVacationsAttributes: [],
    }
  },
  computed: {
    ...mapState('employee', {
      selectedEmployee: 'selectedEmployee',
      employeesList: 'employeesList',
      skeletonLoading: 'skeletonLoading',
      popupVacation: 'popupVacation',
      employeeVac: 'employeeVac',
      employeeVacList: 'employeeVacList',
    }),
    popupVacation: {
      get() {
        return this.$store.state.employee.popupVacation
      },
      set(val) {
        this.setPopupVacation(val)
        this.vacationsDates = {}
      },
    },
  },
  watch: {
    employeeVacList() {
      this.disabledDates = []

      Array.from(this.employeeVacList).forEach((item) => {
        const itemArrI = item['initialDate'].split('-')
        const itemArrF = item['finalDate'].split('-')
        const idd = itemArrI[2]
        const imm = itemArrI[1] - 1
        const iyyyy = itemArrI[0]
        const fdd = itemArrF[2]
        const fmm = itemArrF[1] - 1
        const fyyyy = itemArrF[0]
        this.disabledDates.push({
          start: new Date(iyyyy, imm, idd),
          end: new Date(fyyyy, fmm, fdd),
        })
      })
    },
  },
  methods: {
    ...mapMutations('employee', {
      setSelectedEmployee: 'setSelectedEmployee',
      setPopupVacation: 'setPopupVacation',
    }),
    ...mapActions('employee', {
      createVacationAction: 'createVacationAction',
    }),
    createEmployeeVacation() {
      let data = {
        initial_date: this.vacationsDates.start.toISOString().split('T')[0],
        final_date: this.vacationsDates.end.toISOString().split('T')[0],
        employee: this.employeeVac.id,
      }
      this.createVacationAction(data)
        .then(() => {
          this.setPopupVacation(false)
          this.vacationsDates = ''
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Vacaciones creadas exitosamente',
          })
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Error al crear vacaciones',
            text: err.response.data['errors'][0]['message'],
            position: 'top-right',
            color: 'danger',
          })
        })
    },
  },
}
</script>
