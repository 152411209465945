<template>
  <div class="sidebar-candidates-container">
    <vs-sidebar
      static-position
      default-index="1"
      color="primary"
      :hidden-background="true"
    >
      <div slot="header" class="header-sidebar">
        <h3 class="mb-2">Filtrar Vacaciones</h3>

        <template v-if="company.picture === '#' || company.picture === null">
          <img class="logo" src="@/assets/images/company.png" alt="avatar1" />
        </template>
        <img v-else class="logo" :src="company.picture" alt="avatar2" />
      </div>

      <vs-row class="px-3">
        <vs-col vs-w="12">
          <vs-row class="px-0 mb-0">
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                v-model="name"
                class="input-search"
                label="Nombre y/o Apellidos"
                @keypress.enter="searchVacations"
              />
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col class="mt-1 col-inputs-full" vs-type="flex" vs-w="12">
          <label class="vs-input--label">Fecha Inicial</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-picker
            v-model="initialDate"
            class="mt-1 full-width"
            placeholder="Fecha Inicial"
            value-type="format"
            format="YYYY-MM-DD"
            :open.sync="openDatepicker"
          >
            <template v-slot:footer>
              <button
                class="mx-btn mx-btn-text mx-2"
                @click="openDatepicker = false"
              >
                Listo
              </button>
            </template>
          </date-picker>
        </vs-col>
        <vs-col class="mt-3 col-inputs-full" vs-type="flex" vs-w="12">
          <label class="vs-input--label">Fecha Final</label>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <date-picker
            v-model="endDate"
            class="mt-1 mb-3 full-width"
            placeholder="Fecha Final"
            value-type="format"
            format="YYYY-MM-DD"
            :open.sync="openDatepickerFin"
          >
            <template v-slot:footer>
              <button
                class="mx-btn mx-btn-text mx-2"
                @click="openDatepickerFin = false"
              >
                Listo
              </button>
            </template>
          </date-picker>
        </vs-col>
      </vs-row>
      <div slot="footer" class="footer-sidebar">
        <vs-row class="px-1 mb-3">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              class="pl-2 pr-3 ml-1 btn-block"
              size="small"
              color="primary"
              type="border"
              icon="search"
              :icon-after="false"
              @click="searchVacations"
              >Buscar</vs-button
            >
          </vs-col>
        </vs-row>
        <vs-row class="px-1 mb-3">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              class="pl-2 pr-3 ml-1 btn-block"
              size="small"
              color="success"
              type="border"
              icon="file_download"
              :icon-after="false"
              :disabled="disabledExcel"
              @click="generateExcel"
              >Exportar a excel</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import * as Excel from 'exceljs'
import * as FileSaver from 'file-saver'
import utils from '@/utils/utils'
import { eventBus } from '@/plugins/event-bus'

export default {
  name: 'FilterSideBar',
  data: function () {
    return {
      name: '',
      initialDate: null,
      openDatepicker: false,
      endDate: null,
      openDatepickerFin: false,
      disabledExcel: true,
    }
  },
  computed: { ...mapState('company', { company: 'company' }) },
  watch: {
    initialDate: function (date) {
      if (this.endDate == null) {
        this.disabledExcel = true
      } else {
        this.disabledExcel = !this.checkDate(date, this.endDate)
      }
    },
    endDate: function (date) {
      if (this.initialDate == null) {
        this.disabledExcel = true
      } else {
        this.disabledExcel = !this.checkDate(this.initialDate, date)
      }
    },
  },
  created() {
    let diaS = this.getDay()
    this.initialDate = diaS
    this.endDate = diaS
    eventBus.$on('searchVacations', this.searchVacations)
  },
  beforeDestroy() {
    eventBus.$off('loadData', this.listener)
  },
  methods: {
    ...mapMutations('assistance', {
      setVacationSearchParams: 'setVacationSearchParams',
    }),
    ...mapActions('assistance', {
      getExcelVacationAction: 'getExcelVacationAction',
    }),
    searchVacations() {
      let searchParams = ''
      if (!!this.name && this.name !== '') {
        searchParams += `&search=${this.name}`
      }
      if (this.initialDate !== null && this.initialDate !== '') {
        searchParams = searchParams + `&Idate=${this.initialDate}`
        if (this.endDate !== null && this.endDate !== '') {
          searchParams = searchParams + `&Fdate=${this.endDate}`
        } else {
          let finalDate = this.initialDate
          searchParams = searchParams + `&Fdate=${finalDate}`
        }
      }
      searchParams += `&fid=${Math.random()}`
      this.setVacationSearchParams(searchParams)
    },
    loadData() {
      eventBus.$emit('loadData')
    },
    getDay() {
      return utils.getActualDate()
    },
    checkDate(date1, date2) {
      let parts = date1.split('-')
      const currentDate = new Date(parts[0], parts[1] - 1, parts[2])
      parts = date2.split('-')
      const finalDate = new Date(parts[0], parts[1] - 1, parts[2])
      return currentDate < finalDate
    },
    addOneDay(dayf) {
      let dia = new Date(dayf)
      dia.setDate(dia.getDate() + 2)
      let diaS = dia.getFullYear() + '-'
      if (dia.getMonth() > 8) {
        diaS = diaS + (dia.getMonth() + 1)
      } else {
        diaS = diaS + '0' + (dia.getMonth() + 1)
      }
      if (dia.getDate() > 9) {
        diaS = diaS + '-' + dia.getDate()
      } else {
        diaS = diaS + '-0' + dia.getDate()
      }
      return diaS
    },
    generateExcel() {
      let data = {
        initial_date: this.initialDate,
        final_date: this.endDate,
        company: this.company.id,
      }
      this.getExcelVacationAction(data).then((result) => {
        let header = [
          'No Nomina',
          'Empleado',
          'Fecha de inicio',
          'Fecha de fin',
        ]

        let title = 'Reporte de vacaciones'

        let workbook = new Excel.Workbook()

        let worksheet = workbook.addWorksheet('Reporte')

        let titleRow = worksheet.addRow([title])

        titleRow.font = { name: 'Calibri', family: 4, size: 16, bold: true }
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.mergeCells('A1:H1')

        worksheet.addRow()
        worksheet.addRow()
        worksheet.addRow()

        let headerRow = worksheet.addRow(header)

        let count = 1
        headerRow.eachCell((cell) => {
          if (count == 1 || count == 2) {
            worksheet.getColumn(count).width = 25
          } else {
            worksheet.getColumn(count).width = 15
          }
          count += 1
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF808080' },
            bgColor: { argb: 'FF0000FF' },
          }

          cell.font = {
            name: 'Calibri',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: '00000000' },
          }

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        })
        result.map((employee) => {
          if (employee.vacations != null) {
            employee.vacations.map((vac) => {
              worksheet.addRow([
                employee.payroll,
                employee.name +
                  ' ' +
                  employee.lastName +
                  ' ' +
                  employee.secondLastName,
                vac.initialDate,
                vac.finalDate,
              ])
            })
          }
        })

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          FileSaver.saveAs(
            blob,
            'Reporte de vacaciones ' +
              this.initialDate +
              ' al ' +
              this.endDate +
              '.xlsx'
          )
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
@import '~@/scss/mixins/_filter-sidebar';

.vs-sidebar {
  max-width: 350px !important;
}
.sidebar-candidates-container {
  overflow: hidden;
  height: calc(100vh - #{$navbar-height});
  position: relative;
  z-index: 0;

  .vs-sidebar.vs-sidebar-parent.vs-sidebar-staticPosition {
    z-index: 10000;
  }
}

.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.vs-sidebar.vs-sidebar-primary.vs-sidebar-parent.vs-sidebar-staticPosition {
  height: 100vh !important;
  @include vs-sidebar--items;
}

.con-vs-slider {
  min-width: 175px !important;

  @include respond-to('small') {
    min-width: 200px !important;
  }
  @include respond-to('medium') {
    min-width: 230px !important;
  }
  @include respond-to('large') {
    min-width: 240px !important;
  }
  @include respond-to('xlarge') {
    min-width: 260px !important;
  }
}
.vs-sidebar {
  background-color: $bg-blue-gray !important;
  .logo {
    @include vs-sidebar--logo;
  }
}

.input-search .vs-con-input .vs-icon.vs-input--icon {
  cursor: pointer !important;
  transition: all ease 0.3s;
  background: $primary;
  color: $bg-blue-gray !important;
  padding: 8px;
  margin-top: -8px;
  margin-right: -5px;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background: rgba($primary, 0.7);
  }
}

.vc-popover-content-wrapper[data-v-88de91da] {
  z-index: 999 !important;
}
</style>
