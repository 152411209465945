<template>
  <div class="container mt-3">
    <vs-row>
      <vs-col vs-justify="center" class="px-3" vs-lg="12" vs-xs="12">
        <vs-row>
          <vs-card vs-w="6">
            <div slot="header">
              <h2>Registro asistencias del día: {{ fechaActual() }}</h2>
            </div>
            <div>
              <vs-row class="new-candidate-container mb-4">
                <vs-col
                  class="px-3"
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="2"
                  vs-xs="12"
                ></vs-col>
                <vs-col
                  class="px-3"
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="3"
                  vs-xs="12"
                >
                  <h1>{{ assistance.entryTime }}</h1>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="3"
                  vs-xs="12"
                  class="mt10"
                ></vs-col>
                <vs-col
                  class="px-3"
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="3"
                  vs-xs="12"
                >
                  <h1>{{ assistance.exitTime }}</h1>
                </vs-col>
                <vs-col vs-type="flex" vs-align="center" vs-lg="12" vs-xs="12">
                  <h1></h1>
                  <h1></h1>
                </vs-col>
                <vs-col vs-type="flex" vs-align="center" vs-lg="5" vs-xs="12">
                  <vs-button
                    :disabled="isBtnEntryDisabled"
                    class="btn btn-block mt-10"
                    color="success"
                    size="large"
                    @click="onClickNewAssistance()"
                  >
                    <h2 v-if="webcamWorks">Registrar entrada</h2>
                    <h2 v-else>Registrar entrada sin foto</h2>
                  </vs-button>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="1"
                  vs-xs="12"
                ></vs-col>
                <vs-col vs-type="flex" vs-align="center" vs-lg="5" vs-xs="12">
                  <vs-button
                    :disabled="isBtnExitDisabled && loadingCamera"
                    class="btn btn-block mt-10"
                    color="primary"
                    size="large"
                    @click="openExitAssistancePrompt()"
                  >
                    <h2 v-if="webcamWorks">
                      Registrar salida
                    </h2>
                    <h2 v-else>Registrar salida sin foto</h2>
                  </vs-button>
                </vs-col>
              </vs-row>
              <vs-row>
                <WebCam
                  ref="webcam"
                  :device-id="cameraId"
                  @cameras="onCameras"
                  @started="onLoadCamera"
                />
              </vs-row>
            </div>
          </vs-card>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import utils from '@/utils/utils'
import { mapActions, mapState, mapMutations } from 'vuex'
import { WebCam } from 'vue-web-cam'

export default {
  name: 'AssistanceView',
  components: {
    WebCam,
  },
  data: function () {
    return {
      countDown: 10,
      assistance: {},
      alta: true,
      modifica: true,
      cameraId: null,
      loadingCamera: false,
      webcamWorks: false,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('company', {
      company: 'company',
    }),
    isBtnEntryDisabled: function () {
      return !(this.alta && this.loadingCamera)
    },
    isBtnExitDisabled: function () {
      if (this.modifica && this.loadingCamera) {
        return this.alta
      } else {
        return true
      }
    },
  },
  created() {
    this.getInfo()
    this.checkWebcam()
  },
  methods: {
    ...mapActions('assistance', {
      getRegistryDateAction: 'getRegistryDateAction',
      newAssistanceAction: 'newAssistanceAction',
      validateEmployeePhotoAction: 'validateEmployeePhotoAction',
      updateAssistanceAction: 'updateAssistanceAction',
    }),
    ...mapMutations('assistance', {
      setUpdateAssistance: 'setUpdateAssistance',
    }),
    onCameras(cameras) {
      if (cameras.length > 0) {
        this.cameraId = cameras[0].deviceId
      }
    },
    /**
     * Acciones a realizar cuando la cámara haya sido cargada
     */
    onLoadCamera() {
      this.$vs.loading.close()
      this.loadingCamera = true
    },
    getInfo() {
      let querySearch =
        'employee_id=' +
        this.user.employee +
        '&working_date=' +
        this.fechaActual()
      this.getRegistryDateAction(querySearch).then((results) => {
        if (results.length > 0) {
          this.alta = false
          this.assistance.id = results[0].id
          this.assistance.employee = results[0].employee
          this.assistance.entryTime = results[0].entryTime
          this.assistance.working_date = results[0].workingDate
          this.assistance.exitTime = results[0].exitTime
          if (this.assistance.exitTime !== null) {
            this.modifica = false
          }
          this.setUpdateAssistance(results[0])
        }
      })
    },
    /**
     * Inicia validación para webcam que haya cargado
     */
    checkWebcam() {
      // Loader de carga de cámara
      this.$vs.loading({
        text: 'Cargando webcam...',
      })
      // Se ejecuta función recursiva para el timer
      this.checkWebcamCountDownTimer()
    },
    /**
     * Timer para la validación de la carga de la webcam
     */
    checkWebcamCountDownTimer() {
      if (this.countDown > 0 && !this.loadingCamera) {
        setTimeout(() => {
          this.countDown -= 1
          this.checkWebcamCountDownTimer()
        }, 1000)
      } else if (this.countDown === 0 && !this.loadingCamera) {
        this.loadingCamera = true
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'No se pudo cargar la cámara.',
          text: `Revise su conexión o registre su asistencia sin foto.`,
          icon: 'warning',
          color: 'warning',
          fixed: true,
          position: 'top-right',
        })
      } else {
        this.loadingCamera = true
        this.$vs.loading.close()
        this.webcamWorks = true
      }
    },
    /**
     * Método para obtener la fecha actual
     */
    fechaActual() {
      return utils.getActualDate()
    },
    /**
     * Método para obtener la hora  actual
     */
    horaActual() {
      return utils.noTime()
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    /**
     * Valida que existan rostros en la foto
     */
    async validateCandidatePictureFace(formDataValidateCandidateFace) {
      let validFace = false
      try {
        this.$vs.loading({
          text: 'Validando foto ...',
        })
        let res = await this.validateEmployeePhotoAction(
          formDataValidateCandidateFace
        )

        const faceDetails = res['jsonResponse']['FaceDetails']
        if (faceDetails.length > 1) {
          throw new Error('Sólo debe existir un rostro en la foto')
        }
        if (faceDetails.length === 0) {
          throw new Error('No se detectaron rostros en la foto.')
        }
        validFace = true
      } catch (error) {
        this.$refs.webcam.resume()
        this.$vs.loading.close()
        let res = ''
        if (error.response) {
          res = utils.getErrorDetails(error.response.data.errors)
        } else {
          res = error.message
        }
        this.$vs.notify({
          title: 'Ha ocurrido un error.',
          text: `${res}`,
          color: 'danger',
          icon: 'danger',
          fixed: true,
          position: 'top-right',
        })
      } finally {
        this.$vs.loading.close()
      }
      return validFace
    },
    /**
     * Almacena el registro de la asistencia.
     *
     */
    async onClickNewAssistance() {
      const fechaActual = this.fechaActual()

      // Valida que exista conexión a la cámara
      if (!this.webcamWorks) {
        // Si no se pudo cargar la webcam, envía la carga sin foto
        let formDataAssistance = new FormData()
        formDataAssistance.append('isActive', true)
        formDataAssistance.append('employee', this.user.employee)
        formDataAssistance.append('workingDate', fechaActual)
        formDataAssistance.append('entryTime', this.horaActual())
        this.uploadFormCandidateAssistance(formDataAssistance)
        return
      }
      const image = this.$refs.webcam.capture()
      const file = this.dataURLtoFile(
        image,
        `Entrada-${fechaActual}-${this.id}-${this.user.name}${this.user.lastName}${this.user.secondLastName}.jpg`
      )
      this.$refs.webcam.pause()

      // Valida que la foto contenga un rostro
      let formDataValidateCandidateFace = new FormData()
      formDataValidateCandidateFace.append('picture', file)

      // Inicia la validación de la foto del empleado
      if (
        await this.validateCandidatePictureFace(formDataValidateCandidateFace)
      ) {
        // Si la foto es válida se crea el registro de asistencia
        let formDataAssistance = new FormData()
        formDataAssistance.append('isActive', true)
        formDataAssistance.append('employee', this.user.employee)
        formDataAssistance.append('workingDate', fechaActual)
        formDataAssistance.append('entryTime', this.horaActual())
        formDataAssistance.append('entryPicture', file)
        this.uploadFormCandidateAssistance(formDataAssistance)
      }
    },
    /**
     * Actualiza el registro de asistencia con el registro de salida
     */
    async onClickUpdateAssistance() {
      const fechaActual = this.fechaActual()

      // Valida que exista conexión a la cámara
      if (!this.webcamWorks) {
        // Si no se pudo cargar la webcam, envía la carga sin foto
        let formDataUpdateAssistance = new FormData()
        const fechaActual = this.fechaActual()
        formDataUpdateAssistance.append('exitTime', this.horaActual())
        formDataUpdateAssistance.append('workingDate', fechaActual)
        formDataUpdateAssistance.append('employee', this.user.employee)
        this.uploadFormUpdateCandidateAssistance(formDataUpdateAssistance)
        return
      }

      const image = this.$refs.webcam.capture()
      const file = this.dataURLtoFile(
        image,
        `Salida-${fechaActual}-${this.user.name}${this.user.lastName}${this.user.secondLastName}.jpg`
      )
      this.$refs.webcam.pause()
      // Valida que la foto contenga un rostro
      let formDataValidateCandidateFace = new FormData()
      formDataValidateCandidateFace.append('picture', file)

      if (
        await this.validateCandidatePictureFace(formDataValidateCandidateFace)
      ) {
        let formDataUpdateAssistance = new FormData()
        const fechaActual = this.fechaActual()
        formDataUpdateAssistance.append('exitTime', this.horaActual())
        formDataUpdateAssistance.append('exitPicture', file)
        formDataUpdateAssistance.append('workingDate', fechaActual)
        formDataUpdateAssistance.append('employee', this.user.employee)
        this.uploadFormUpdateCandidateAssistance(formDataUpdateAssistance)
      }
    },
    /**
     * Realiza la subida del formulario con la asistencia del candidato
     */
    uploadFormCandidateAssistance(formDataAssistance) {
      this.$vs.loading({
        text: 'Creando asistencia ...',
      })
      this.newAssistanceAction(formDataAssistance)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Éxito.',
            type: 'success',
            text: 'Horario de entrada actualizado exitosamente',
          })
          this.getInfo()
          this.$refs.webcam.resume()
        })
        .catch((error) => {
          this.$refs.webcam.resume()

          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title: 'Ha ocurrido un error.',
            text: `${res}`,
            color: 'danger',
            fixed: true,
            position: 'top-right',
          })
        })
    },
    /**
     * Realiza la subida del formulario con la salida del candidato
     */
    uploadFormUpdateCandidateAssistance(formDataUpdateAssistance) {
      this.$vs.loading({
        text: 'Registrando salida...',
      })
      this.updateAssistanceAction({
        formData: formDataUpdateAssistance,
        id: this.assistance.id,
      })
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Éxito.',
            type: 'success',
            text: 'Horario de salida actualizado exitosamente',
          })
          this.getInfo()
          this.$refs.webcam.resume()
          //this.goToCandidatesPage()
        })
        .catch((error) => {
          this.$refs.webcam.resume()
          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title:
              'Revise los campos señalados, guárdelos y reintente nuevamente.',
            text: `${res}`,
            color: 'danger',
            fixed: true,
            position: 'top-right',
          })
        })
    },
    /**
     * Despliega el modal para la confirmación del registro de salida
     */
    openExitAssistancePrompt() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: `Confirmar Salida`,
        text: `¿Está seguro que desea registrar la salida?`,
        acceptText: 'Aceptar',
        cancelText: 'Cancelar',
        accept: this.onClickUpdateAssistance,
      })
    },
  },
}
</script>

<style lang="scss"></style>
