<template>
  <div class="mt-3 mx-5">
    <vs-row>
      <vs-col vs-justify="center" class="pr-3" vs-lg="3" vs-xs="12">
        <vs-row>
          <vs-col vs-w="10">
            <vs-card>
              <div slot="header">
                <h3>{{ company.name }}</h3>
              </div>
              <div slot="media">
                <template>
                  <vs-col
                    v-if="
                      company.picture !== undefined &&
                      company.picture !== '#' &&
                      company.picture !== null
                    "
                    class="mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <small class="mx-auto"> Imagen Actual </small>
                    <vs-icon
                      icon="edit"
                      color="blue"
                      class="cursor-pointer"
                      @click="
                        () => {
                          showImage = !showImage
                        }
                      "
                    ></vs-icon
                  ></vs-col>
                  <vs-col vs-type="flex" class="preview-images mb-2">
                    <template
                      v-if="
                        company.picture !== undefined &&
                        company.picture !== '#' &&
                        company.picture !== null
                      "
                    >
                      <img
                        :src="company.picture"
                        class="grid-view-img mx-auto"
                      />
                    </template>
                  </vs-col>
                  <template
                    v-if="
                      showImage ||
                      company.picture === undefined ||
                      company.picture === '#' ||
                      company.picture === null
                    "
                  >
                    <picture-input
                      :key="keyImage"
                      ref="uploadMainPhoto"
                      class="mt-3"
                      style="width: 80%;"
                      size="7"
                      :crop="false"
                      :min-width="300"
                      :min-height="300"
                      @change="onChangeImgOne()"
                    >
                    </picture-input>
                  </template>
                </template>
              </div>
              <div class="user-profile-container">
                <vs-input
                  v-model="companyData.name"
                  class="mt-2"
                  type="text"
                  label="Empresa"
                />
                <vs-row vs-justify="flex-end">
                  <vs-button
                    class="mt-3 btn-block"
                    color="primary"
                    type="gradient"
                    @click="_updateCompany"
                    >Actualizar datos de la empresa</vs-button
                  >
                </vs-row>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
      </vs-col>
      <vs-col vs-justify="left" class="px-3" vs-lg="9" vs-xs="12">
        <VueGoodTable
          :columns="columns"
          :rows="usersList"
          :line-numbers="true"
          max-height="65vh"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'top',
            perPageDropdown: [20, 30, 40, 50, 60, 70, 80, 90, 100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'Siguiente',
            prevLabel: 'Anterior',
            rowsPerPageLabel: 'Registros por Página',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          }"
        />
      </vs-col>
    </vs-row>
    <vue-fab size="big" @clickMainBtn="activePromptInviteUser = true" />
    <vs-prompt
      :active.sync="activePromptInviteUser"
      title="Invitar usuario"
      accept-text="Enviar invitación"
      cancel-text="Cancelar"
      @accept="sendEmail()"
    >
      <div class="input mt-2">
        <vs-input
          v-model="email"
          icon="email"
          label-placeholder="Email para enviar la invitación"
          type="email"
        />
      </div>
      <div class="input mt-3 justify-content-left">
        <vs-radio v-model="rolRadio" vs-name="rol" vs-value="isRecruiter">
          Reclutador
        </vs-radio>
        <vs-radio
          v-model="rolRadio"
          class="ml-4"
          vs-name="rol"
          vs-value="isCompanyAdmin"
        >
          Administrador
        </vs-radio>
      </div>
    </vs-prompt>
    <vs-prompt
      color="primary"
      :active.sync="dialog"
      title="Recortar Imagen"
      accept-text="Aceptar"
      cancel-text="Cerrar"
      @accept="saveImageOne"
      @close="cancelImage"
      @cancel="cancelImage"
    >
      <div class="modal-venta">
        <vueCropper
          v-show="selectedFile"
          ref="cropperPhoto"
          :src="selectedFile"
          :crop-box-resizable="true"
          :aspect-ratio="1"
          alt="Source Image"
        ></vueCropper>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { roles } from '@/utils/utils'
import utils from '@/utils/utils'
import PictureInput from '@/components/PictureInput'
import axios from 'axios'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import VueCropper from 'vue-cropperjs'

export default {
  name: 'CompanyView',
  components: {
    'picture-input': PictureInput,
    VueGoodTable,
    vueCropper: VueCropper,
  },
  data: function () {
    return {
      columns: [
        {
          label: 'Nombre',
          field: 'name',
        },
        {
          label: 'Primer Apellido',
          field: 'lastName',
        },
        {
          label: 'Segundo Apellido',
          field: 'secondLastName',
        },
        {
          label: 'Username',
          field: 'username',
        },
        {
          label: 'Rol',
          field: 'role',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Teléfono',
          field: 'phoneNumber',
        },
      ],
      showImage: false,
      urlAction: '',
      text: 'Cargar imagen',
      filename: 'picture',
      activePromptInviteUser: false,
      email: '',
      rolRadio: 'recruiter',
      usersList: [],
      companyData: { name: '' },
      roles,
      dialog: false,
      selectedFile: '',
      keyImage: 0,
    }
  },
  computed: {
    ...mapState('auth', { tokenAccess: 'tokenAccess' }),
    ...mapState('company', { company: 'company' }),
    ...mapState('auth', { user: 'user' }),
    headers: function () {
      let headers = {
        Authorization: 'Bearer ' + this.tokenAccess,
      }
      return headers
    },
  },
  created: function () {
    this.getCompany(this.user.userprofile.company.id).then((response) => {
      this.setCompany(response)
      this.companyData.name = response.name
      this.urlAction = `${axios.defaults.baseURL}companies/${this.company.id}/`
    })
    this.getUsers('role=admin,recruiter').then((response) => {
      this.usersList = response
      this.usersList.map((user) => {
        if (user.userprofile.role === roles.ADMIN) {
          user.role = 'Administrador'
        }
        if (user.userprofile.role === roles.RECRUITER) {
          user.role = 'Recultador'
        }
        if (user.userprofile.role === roles.EMPLOYEE) {
          user.role = 'Empleado'
        }
        return user
      })
    })
  },
  methods: {
    ...mapActions('company', {
      inviteUser: 'inviteUser',
      updateCompany: 'updateCompany',
      getCompany: 'getCompany',
      updatePictureCompany: 'updatePictureCompany',
    }),
    ...mapActions('users', {
      getUsers: 'getUsers',
      updateUser: 'updateUser',
    }),
    ...mapMutations('company', { setCompany: 'setCompany' }),
    validateEmail: function () {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.email)) {
        return true
      }
      return false
    },
    onChangeImgOne() {
      if (this.$refs.uploadMainPhoto.image) {
        const file = this.$refs.uploadMainPhoto.file
        this.mime_type = file.type
        this.dialog = true
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.selectedFile = event.target.result
            this.$refs.cropperPhoto.replace(this.selectedFile)
            const data = {
              width: 400,
              height: 400,
            }
            this.$refs.cropperPhoto.setCropBoxData(data)
          }
          reader.readAsDataURL(file)
        } else {
          alert('Sorry, FileReader API not supported')
        }
      }
    },
    saveImageOne() {
      let formData = new FormData()
      let self = this
      this.cropedImage = this.$refs.cropperPhoto.getCroppedCanvas().toDataURL()
      this.$refs.cropperPhoto.getCroppedCanvas().toBlob(
        (blob) => {
          let file = new File([blob], 'photo.webp')
          formData.append('picture', file, 'photo.webp')
          self.image = new File([blob], 'temp.webp')
          self
            .updatePictureCompany({
              formData: formData,
              companyId: this.company.id,
            })
            .then((res) => {
              self.showImage = false
              self.setCompany(res)
            })
            .catch((err) => {
              self.$vs.notify({
                title: 'Error al cargar la imagen',
                text: 'Asegurese de adjuntar una imagen válida.',
                position: 'top-right',
                color: 'danger',
              })
              throw new Error(err.message)
            })
        },
        'image/webp',
        0.8
      )
    },
    cancelImage() {
      this.keyImage++
    },
    sendEmail: async function () {
      if (!this.validateEmail()) {
        this.$vs.notify({
          color: 'warning',
          title: 'Ops..',
          text: 'Por favor, introduce un email válido',
          position: 'top-right',
        })
        return false
      }
      await this.inviteUser({
        email: this.email,
        isCompanyAdmin: this.rolRadio === 'isCompanyAdmin' ? true : false,
      })
        .then((emailInvitationStatus) => {
          if (emailInvitationStatus) {
            this.$vs.notify({
              color: 'success',
              title: 'Éxito',
              text: 'La invitación ha sido enviada exitosamente.',
              position: 'top-right',
            })
            this.email = ''
          } else {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text:
                'Ha fallado el envío de la invitación, reintente más tarde.',
              position: 'top-right',
            })
          }
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          res = res.replace(
            'email: Este campo debe ser único.',
            'Este usuario ya existe o está en proceso de activación de cuenta.'
          )
          this.$vs.notify({
            color: 'warning',
            title: 'Ha fallado el envío de la invitación',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },
    activateDeactivateUser: function (username, status) {
      this.updateUser({
        data: { isActive: status },
        username: username,
      })
        .then((response) => {
          this.getUsers().then((response) => {
            this.usersList = response.results
          })
          this.$vs.notify({
            title: 'Éxito',
            text: 'Usuario actualizado',
            color: 'success',
            position: 'bottom-center',
          })
          this.setUser(response)
        })
        .catch(() => {})
    },
    successUpload(event) {
      let responseTarget = event.currentTarget.response
      let response = JSON.parse(responseTarget)
      this.setCompany(response)
      this.$vs.notify({
        title: 'Éxito',
        text: 'Avatar actualizado',
        color: 'success',
        position: 'bottom-center',
      })
    },
    _updateCompany: function () {
      this.updateCompany({
        data: { name: this.companyData.name },
        companyId: this.company.id,
      })
        .then((response) => {
          this.$vs.notify({
            title: 'Éxito',
            text: 'Información actualizada',
            color: 'success',
            position: 'bottom-center',
          })
          this.setCompany(response)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
.company-card-description {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
