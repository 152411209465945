<template>
  <vs-card class="px-2 pt-3">
    <div slot="header" class="mb-1">
      <h2 class="mb-2">Recuperar contraseña</h2>
      <p class="small-font">
        Te enviaremos un email para que actualices tus credenciales
      </p>
    </div>
    <div class="pt-3">
      <ValidationObserver ref="observernewUser" v-slot="{ invalid }">
        <form ref="form" @submit.prevent="submit">
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="col-inputs-full"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <vs-input
                  v-model="recoveryAccount.email"
                  size="large"
                  type="email"
                  label-placeholder="Email"
                  autocomplete="off"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <router-link class="ml-auto mt-3" :to="{ name: 'authLogin' }">
                Iniciar sesión
              </router-link>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <vs-button
                class="btn btn-block mt-4"
                color="primary"
                size="large"
                type="filled"
                :disabled="invalid"
                @click="submit"
              >
                Recuperar contraseña
              </vs-button>
            </vs-col>
          </vs-row>
        </form>
      </ValidationObserver>
    </div>
  </vs-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'RegisterForm',
  data: function () {
    return {
      recoveryAccount: { email: '', isClient: false },
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
  },
  methods: {
    ...mapActions('auth', {
      recoverPasswordByEmail: 'recoverPasswordByEmail',
    }),
    async submit() {
      this.recoverPasswordByEmail(this.recoveryAccount)
        .then(() => {
          this.$vs.notify({
            title: 'Email enviado.',
            text: 'Por favor, revisa tu correo para recuperar tu cuenta.',
            color: 'success',
            position: 'bottom-center',
            icon: 'mail',
          })
          this.$router
            .push({
              name: 'authLogin',
            })
            .then(() => {
              this.active = false
            })
            .catch(() => {})
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            color: 'danger',
            title: 'Ocurrió un problema al recuperar tu cuenta',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
