import CandidatesView from '@/modules/candidates/views/CandidatesView'
import UpdateCandidateView from '@/modules/candidates/views/UpdateCandidateView'
import NewCandidateView from '@/modules/candidates/views/NewCandidateView'

export default [
  {
    path: '/candidatos',
    name: 'candidatesList',
    meta: {
      title: 'Lista de candidatos',
      rule: 'recruiter',
    },
    component: CandidatesView,
  },
  {
    path: '/candidatos/nuevo',
    name: 'newCandidate',
    meta: {
      title: 'Nuevo candidato',
      rule: 'recruiter',
    },
    component: NewCandidateView,
  },
  {
    path: '/candidatos/:id',
    name: 'updateCandidate',
    meta: {
      title: 'Editar candidato',
      rule: 'recruiter',
    },
    component: UpdateCandidateView,
  },
]
