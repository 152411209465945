<template>
  <vs-sidebar
    v-model="active"
    parent="body"
    default-index="3.1"
    color="primary"
    class="sidebarx"
    :click-not-close="true"
    spacer
  >
    <div slot="header" class="header-sidebar">
      <div class="header-desc">
        <vs-button
          icon="close"
          color="primary"
          type="flat"
          @click="active = false"
        />
      </div>
      <template v-if="user.userprofile.picture !== '#'">
        <vs-avatar size="70px" :src="user.userprofile.picture" />
      </template>
      <vs-avatar
        v-else
        size="70px"
        :src="require('@/assets/images/user-avatar.png')"
      />

      <h4>{{ user.name }} {{ user.lastName }} {{ user.secondLastName }}</h4>
      <span class="small-font">{{ user.email }}</span>
      <small class="small-font mt-1">{{ company.name }}</small>
    </div>

    <vs-sidebar-item index="1" icon="account_box" @click="goToProfile">
      Mi Perfil
    </vs-sidebar-item>

    <vs-sidebar-item
      v-if="
        user.userprofile.role === roles.ADMIN ||
        user.userprofile.role === roles.RECRUITER
      "
      index="2"
      icon="library_books"
      @click="goToCatalogs"
    >
      Catálogos
    </vs-sidebar-item>

    <!-- Candidatos -->
    <vs-sidebar-group
      v-if="
        user.userprofile.role === roles.ADMIN ||
        user.userprofile.role === roles.RECRUITER
      "
      title="Candidatos"
      :open="true"
    >
      <vs-sidebar-item index="3.1" icon="list" @click="goToCandidates">
        Lista de candidatos
      </vs-sidebar-item>
      <vs-sidebar-item index="3.2" icon="person_add" @click="goToNewCandidate">
        Agregar Candidato
      </vs-sidebar-item>
    </vs-sidebar-group>

    <!-- Empleados -->
    <vs-sidebar-group
      v-if="
        user.userprofile.role === roles.ADMIN ||
        user.userprofile.role === roles.RECRUITER
      "
      title="Empleados"
      :open="true"
    >
      <vs-sidebar-item index="4.1" icon="list" @click="goToEmployees">
        Lista de empleados
      </vs-sidebar-item>
      <vs-sidebar-item
        index="4.2"
        icon="work_outline"
        @click="goToListAssistance"
      >
        Lista de asistencia
      </vs-sidebar-item>
      <vs-sidebar-item
        index="4.3"
        icon="beach_access"
        @click="goToListVacations"
      >
        Lista de vacaciones
      </vs-sidebar-item>
    </vs-sidebar-group>

    <!-- Usuarios -->
    <vs-sidebar-group
      v-if="user.userprofile.role === roles.ADMIN"
      title="Empresa"
      :open="true"
    >
      <vs-sidebar-item index="5.1" icon="business" @click="goToCompanyDetail">
        Reclutadores
      </vs-sidebar-item>
    </vs-sidebar-group>
    <vs-sidebar-group
      v-if="user.employee != null && user.employee != undefined"
      title="Asistencia"
      :open="true"
    >
      <vs-sidebar-item
        style="margin-left: -16px;"
        index="0"
        icon="clock"
        @click="goToAssistance"
      >
        Asistencia
      </vs-sidebar-item>
      <vs-sidebar-item
        index="0.1"
        icon="work_outline"
        @click="goToEmployeeAssistance"
      >
        Mis asistencias
      </vs-sidebar-item>
    </vs-sidebar-group>

    <div slot="footer" class="footer-sidebar">
      <vs-button icon="reply" color="danger" type="flat" @click="_logout">
        Cerrar Sesión
      </vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { eventBus } from '@/plugins/event-bus'
import { roles } from '@/utils/utils'

export default {
  name: 'MainSideBar',
  data: function () {
    return {
      active: false,
      roles,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('company', {
      company: 'company',
    }),
  },
  created: function () {
    eventBus.$on('showSideBar', this.showSideBar)
  },
  methods: {
    ...mapActions('auth', { logout: 'logout' }),
    showSideBar() {
      this.active = true
    },
    goToCatalogs() {
      this.$router
        .push({
          name: 'catalogs',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToCandidates() {
      this.$router
        .push({
          name: 'candidatesList',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToNewCandidate() {
      this.$router
        .push({
          name: 'newCandidate',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToEmployees() {
      this.$router
        .push({
          name: 'employeeList',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToCompanyDetail() {
      this.$router
        .push({
          name: 'Company',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToListAssistance() {
      this.$router
        .push({
          name: 'Listassitance',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToEmployeeAssistance() {
      this.$router
        .push({
          name: 'Employeeassitance',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToListVacations() {
      this.$router
        .push({
          name: 'ListVacations',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToProfile() {
      this.$router
        .push({
          name: 'MyProfile',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    goToAssistance() {
      this.$router
        .push({
          name: 'assitance',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
    _logout() {
      this.logout()
      this.$router
        .push({
          name: 'authLogin',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .header-desc {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
}
</style>
