<template>
  <div class="candidate-info-tabs">
    <div class="personalidad-cards-container px-3">
      <!-- Cualidades -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Cualidades
          </h2>
        </div>
        <ValidationObserver ref="observerCualidades" v-slot="{ invalid }">
          <div>
            <vs-row
              v-for="quality in updateCandidate.qualityAdd"
              :key="quality.id"
              class="update-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Cualidad"
                  rules="required|min:5"
                >
                  <vs-input
                    v-model="quality.quality"
                    label-placeholder="Cualidad"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="mr-2"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addQuality"
                >
                  Agregar cualidad
                </vs-button>
                <vs-button
                  :disabled="updateCandidate.qualityAdd.length === 0"
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeQuality"
                >
                  Eliminar cualidad
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="onClickSaveAndContinue('personality', 'qualities')"
                >
                  Guardar cualidades
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Áreas de oportunidad -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Áreas de oportunidad
          </h2>
        </div>
        <ValidationObserver ref="observerOportunidades" v-slot="{ invalid }">
          <div>
            <vs-row
              v-for="opportunity in updateCandidate.opportunityAreaAdd"
              :key="opportunity.id"
              class="update-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Oportunidad"
                  rules="required|min:5"
                >
                  <vs-input
                    v-model="opportunity.opportunity"
                    label-placeholder="Área de oportunidad"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="mr-2"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addOpportunity"
                >
                  Agregar oportunidad
                </vs-button>
                <vs-button
                  :disabled="updateCandidate.opportunityAreaAdd.length === 0"
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeOpportunity"
                >
                  Eliminar oportunidad
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="
                    onClickSaveAndContinue('personality', 'opportunities')
                  "
                >
                  Guardar oportunidades
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Percepción de externos -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Percepción de externos
          </h2>
        </div>
        <ValidationObserver
          ref="observerPercepcionExternos"
          v-slot="{ invalid }"
        >
          <div>
            <vs-row
              v-for="perception in updateCandidate.externalPerceptionsAdd"
              :key="perception.id"
              class="update-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Percepción"
                  rules="required|min:5"
                >
                  <vs-input
                    v-model="perception.perception"
                    label-placeholder="Percepción"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="mr-2"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addExternalPerception"
                >
                  Agregar percepción
                </vs-button>
                <vs-button
                  :disabled="
                    updateCandidate.externalPerceptionsAdd.length === 0
                  "
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeExternalPerception"
                >
                  Eliminar percepción
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="onClickSaveAndContinue('personality', 'perceptions')"
                >
                  Guardar percepción de externos
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Comentarios -->
      <vs-card class="pt-3 px-1">
        <div slot="header">
          <h2>
            Comentarios
          </h2>
        </div>
        <div>
          <vs-row class="update-candidate-container mb-4">
            <vs-col
              class="mt-2 px-1"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-textarea
                v-model="updateCandidate.personalityComments"
                label="Comentarios u Observaciones de esta sección"
              />
            </vs-col>
          </vs-row>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button
              color="primary"
              icon="turned_in_not"
              @click="onClickSaveAndContinue('personality', 'comments')"
            >
              Guardar comentarios
            </vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'PersonalidadForm',
  props: {
    updateCandidate: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {}
  },
  methods: {
    ...mapMutations('candidates', {
      setUpdateCandidatePersonalityQuality:
        'setUpdateCandidatePersonalityQuality',
      setUpdateCandidatePersonalityOpportunityArea:
        'setUpdateCandidatePersonalityOpportunityArea',
      setUpdateCandidatePersonalityExternalPerceptions:
        'setUpdateCandidatePersonalityExternalPerceptions',
      setUpdateCandidatePersonalityComments:
        'setUpdateCandidatePersonalityComments',
    }),
    addQuality() {
      this.updateCandidate.qualityAdd.push({
        id: utils.randomId(),
        quality: '',
      })
    },
    removeQuality() {
      this.updateCandidate.qualityAdd.pop()
    },
    addOpportunity() {
      this.updateCandidate.opportunityAreaAdd.push({
        id: utils.randomId(),
        opportunity: '',
      })
    },
    removeOpportunity() {
      this.updateCandidate.opportunityAreaAdd.pop()
    },
    addExternalPerception() {
      this.updateCandidate.externalPerceptionsAdd.push({
        id: utils.randomId(),
        perception: '',
      })
    },
    removeExternalPerception() {
      this.updateCandidate.externalPerceptionsAdd.pop()
    },
    onClickSaveAndContinue(section, card) {
      switch (card) {
        case 'qualities':
          this.setUpdateCandidatePersonalityQuality(this.updateCandidate)
          break
        case 'opportunities':
          this.setUpdateCandidatePersonalityOpportunityArea(
            this.updateCandidate
          )
          break
        case 'perceptions':
          this.setUpdateCandidatePersonalityExternalPerceptions(
            this.updateCandidate
          )
          break
        case 'comments':
          this.setUpdateCandidatePersonalityComments(this.updateCandidate)
          break
      }
      this.$vs.notify({
        title: 'Tarjeta actualizada',
        color: 'success',
        position: 'bottom-center',
      })
    },
  },
}
</script>

<style scoped></style>
