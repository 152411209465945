/**
 * @return {string}
 */
module.exports = {
  randomId: function () {
    return `_${Math.random().toString(36).substr(2, 9)}`
  },
  calculateAge: function (dateString) {
    let today = new Date()
    let birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },
  getErrorDetails: function (errors) {
    var toType = function (obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
    }

    let errorDetails = ''
    let messages = ''

    errors.forEach((err) => {
      if (toType(err['message']) === 'object') {
        let messagesObj = err['message']
        Object.keys(messagesObj[0]).forEach((messageInput) => {
          let formatMessage = messagesObj[0][messageInput].join(', ')
          messages += `${messageInput}: ${formatMessage} \n`
        })
      } else {
        messages = err['message']
      }
      errorDetails += `${err['field']}: ${messages} \n`
    })
    errorDetails = errorDetails.replace('non_field_errors: ', '')
    errorDetails = errorDetails.replace('external_perceptions_add: ', '')
    return errorDetails
  },
  /**
   * Objeto con la lista de roles disponibles.
   *
   */
  roles: {
    ADMIN: 'admin',
    RECRUITER: 'recruiter',
    EMPLOYEE: 'employee',
  },
  /**
   * Método para regresar fecha actual
   */
  getActualDate: function () {
    const today = new Date()
    const day = today.getDate()
    const month = today.getMonth() + 1
    return (
      today.getFullYear() +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (day < 10 ? '0' : '') +
      day
    )
  },

  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  BeforeToday: function (date) {
    return date > new Date(new Date().setHours(0, 0, 0, 0) + 1)
  },
  /**
   * Método para regresar hora actual
   */
  noTime: function () {
    const today = new Date()
    const hour = today.getHours()
    const min = today.getMinutes()
    const seconds = today.getSeconds()
    return (
      (hour < 10 ? '0' : '') +
      hour +
      ':' +
      (min < 10 ? '0' : '') +
      min +
      ':' +
      (seconds < 10 ? '0' : '') +
      seconds
    )
  },
}
