<template>
  <vs-row
    class="new-candidate-container container"
    type="flex"
    vs-justify="center"
  >
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-xs="12"
    >
      <div class="candidate-info-tabs mt-4">
        <vs-alert
          v-for="(err, key) in getUpdateEmployeeErrors.generalInformation
            .employeeData"
          :key="key"
          class="mt-1"
          color="danger"
          icon="new_releases"
        >
          <span>{{ `${err.name}: ${err.msg}` }}</span>
        </vs-alert>
        <div class="candidate-cards-container px-3">
          <!-- Datos personales-->
          <ValidationObserver ref="observerDatosCandidato" v-slot="{ invalid }">
            <vs-card class="pt-3 px-1">
              <div slot="header">
                <h2>Datos baja</h2>
              </div>
              <div>
                <vs-row class="new-candidate-container mb-4">
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="4"
                  >
                    <vs-input
                      v-model="updateEmployee.name"
                      label-placeholder="Nombre(s)"
                      disabled="true"
                    />
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="4"
                  >
                    <vs-input
                      v-model="updateEmployee.lastName"
                      label-placeholder="Apellido Paterno"
                      disabled="true"
                    />
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="4"
                  >
                    <vs-input
                      v-model="updateEmployee.secondLastName"
                      label-placeholder="Apellido Materno"
                      disabled="true"
                    />
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="6"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Fecha baja"
                      rules="required"
                    >
                      <div
                        class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                        :danger="!!errors[0]"
                        :danger-text="errors[0]"
                      >
                        <div
                          class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                        >
                          <date-picker
                            v-model="updateEmployee.endDate"
                            class="full-width"
                            placeholder="Fecha baja"
                            value-type="format"
                            format="YYYY-MM-DD"
                            :open.sync="openDatepicker"
                          >
                            <template v-slot:footer>
                              <button
                                class="mx-btn mx-btn-text mx-2"
                                @click="openDatepicker = false"
                              >
                                Listo
                              </button>
                            </template>
                          </date-picker>
                          <span
                            class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                            style="margin-top: -28px;"
                          >
                            Fecha baja
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="6"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Motivo renuncia"
                      rules="required"
                    >
                      <vs-col
                        class="col-inputs-full mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="10"
                      >
                        <vs-select
                          v-model="updateEmployee.reasonForWithdrawal"
                          autocomplete
                          class="block col-inputs-full"
                          label="Motivo renuncia"
                          placeholder="Elija motivo renuncia"
                          val-icon-danger="error"
                          :danger="!!errors[0]"
                          :danger-text="errors[0]"
                        >
                          <vs-select-item
                            v-for="(item,
                            indexReason) in reasonForWithdrawalCatalog"
                            :key="indexReason"
                            :value="item.id"
                            :text="item.reason"
                          />
                        </vs-select>
                      </vs-col>
                      <vs-col
                        class="col-inputs-full mt-3 pt-1"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="2"
                      >
                        <vs-icon
                          icon="add"
                          color="success"
                          class="cursor-pointer mt-3"
                          @click="newCatalog('reasonForWithdrawal')"
                        ></vs-icon>
                      </vs-col>
                    </ValidationProvider>
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Motivo"
                      rules="required"
                    >
                      <vs-textarea
                        id="reason"
                        v-model="updateEmployee.reasonEnd"
                        label-placeholder="Motivo"
                        val-icon-danger="error"
                        maxlength="1000"
                        rows="4"
                        :class="{
                          'input-icon-validate-danger': errors.length > 0,
                        }"
                      >
                      </vs-textarea>
                      <small class="small-error-message">
                        {{ errors[0] }}
                      </small>
                    </ValidationProvider>
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="4"
                  >
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="4"
                  >
                    <a
                      v-if="
                        documentoBaja !== null && documentoBaja !== undefined
                      "
                      :href="documentoBaja"
                    >
                      {{ documentoBaja }}
                    </a>
                    <vs-upload
                      ref="baja1"
                      accept="application/pdf"
                      :limit="1"
                      :show-upload-button="false"
                      text="Adjuntar Documento"
                      @change="uploadDoc()"
                    />
                  </vs-col>
                  <vs-col
                    class="mt-2 px-1 col-inputs-full"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="4"
                  >
                  </vs-col>
                </vs-row>
              </div>
              <div slot="footer">
                <vs-row vs-justify="flex-end">
                  <vs-button
                    color="primary"
                    icon="turned_in_not"
                    :disabled="invalid"
                    @click="onClickUpdateEmployee('employeeData')"
                  >
                    Guardar datos baja
                  </vs-button>
                </vs-row>
              </div>
            </vs-card>
          </ValidationObserver>
        </div>
      </div>
    </vs-col>
    <ValidationObserver ref="observerNewCatalog" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="newCatalogPopUp"
        :title="title"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        @accept="saveNewCatalog"
      >
        <div>
          <vs-row>
            <vs-col class="mx-1 mt-1" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
                tag="div"
                class="mt-2"
              >
                <vs-input
                  v-model="nameNewCatalog"
                  label-placeholder="Nombre"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </vs-row>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

//import Compressor from 'compressorjs'
import utils from '@/utils/utils'

export default {
  name: 'DismissalEmployeeView',
  components: {
    'date-picker': DatePicker,
  },
  data: function () {
    return {
      idEmployee: 0,
      isLoading: false,
      isDocument: false,
      openDatepicker: false,
      openStartDatepicker: false,
      reasonForWithdrawalCatalog: [],
      nombre: 'x',
      appat: 'y',
      apmat: 'z',
      dialog: false,
      dismissalEmployee: {},
      documentoBaja: '',
      employeeData: {},
      newCatalogPopUp: false,
      title: '',
      typeNewCatalog: '',
      nameNewCatalog: '',
    }
  },
  computed: {
    ...mapState('employee', {
      updateEmployee: 'updateEmployee',
      newEmployeeFormsProgress: 'newEmployeeFormsProgress',
    }),
    ...mapState('company', { company: 'company' }),
    ...mapGetters('employee', {
      finalUpdateEmployee: 'finalUpdateEmployee',
      getUpdateEmployeeErrors: 'getUpdateEmployeeErrors',
    }),
  },
  watch: {
    isLoading: function () {
      if (this.isLoading) {
        this.$vs.loading({
          type: 'radius',
        })
      } else {
        this.$vs.loading.close()
      }
    },
  },
  created() {
    this.getReasonForWithdrawalCatalog('is_active=True').then((response) => {
      this.reasonForWithdrawalCatalog = response
    })
  },
  mounted() {
    if (
      document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0] != undefined
    ) {
      let element = document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0]
      element.parentNode.removeChild(element)
    }
    this.idEmployee = this.$route.params.id
    this.getEmployeeByIdAction(this.idEmployee)
      .then((res) => {
        this.setUpdateEmployee(res)
      })
      .catch(() => {
        this.$vs.notify({
          title: 'Opps...',
          text: 'Empleado no encontrado',
        })
        this.$router.push({ name: 'employeeList' })
      })
  },
  methods: {
    ...mapActions('catalogs', {
      getReasonForWithdrawalCatalog: 'getReasonForWithdrawalCatalog',
      newItemCatalog: 'newItemCatalog',
    }),
    ...mapActions('candidates', {
      getCandidateById: 'getCandidateById',
    }),
    ...mapActions('employee', {
      dismissalEmployeeAction: 'dismissalEmployeeAction',
      getEmployeeByIdAction: 'getEmployeeByIdAction',
      createEmployeePhotosAction: 'createEmployeePhotosAction',
      saveDocumentAction: 'saveDocumentAction',
    }),
    ...mapMutations('employee', {
      clearUpdateEmployeeData: 'clearUpdateEmployeeData',
      setUpdateEmployee: 'setUpdateEmployee',
    }),
    uploadDoc() {
      let formData = new FormData()
      let arrayLength = this.$refs['baja1'].filesx.length - 1
      let file = this.$refs['baja1'].filesx[arrayLength]

      formData.append('document', file, file.name)
      formData.append('documentType', 'BAJA')
      this.$vs.loading({
        text: 'Almacenando archivo ...',
      })
      this.saveDocumentAction(formData)
        .then((res) => {
          this.$vs.loading.close()
          this.isDocument = true
          this.updateEmployee.documentsAdd.push(res.id)
        })
        .catch((error) => {
          this.$vs.loading.close()

          let res = ''
          if (!error.response || !error.response.data['errors']) {
            res = 'Error'
          } else {
            res = utils.getErrorDetails(error.response.data['errors'])
          }
          this.$vs.notify({
            title: 'Error al almacenar documento.',
            text: res,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    goToEmployeesPage() {
      this.clearUpdateEmployeeData()
      this.$router.push({
        name: 'employeeList',
      })
    },
    onClickUpdateEmployee() {
      this.isLoading = true
      this.updateEmployee.isActive = false
      if (this.isDocument === true) {
        this.dismissalEmployeeAction({
          data: this.updateEmployee,
          id: this.idEmployee,
        })
          .then(() => {
            this.isLoading = false
            this.$vs.notify({
              title: 'Éxito.',
              text: 'Empleado se dió de baja Exitosamente',
            })
            this.$vs.loading.close()
            this.goToEmployeesPage()
          })
          .catch((error) => {
            this.isLoading = false
            this.$vs.loading.close()
            let res = ''
            if (error.response) {
              res = utils.getErrorDetails(error.response.data.errors)
            } else {
              res = error.message
            }
            this.$vs.notify({
              title:
                'Revise los campos señalados, guárdelos y reintente nuevamente.',
              text: `${res}`,
              color: 'danger',
              fixed: true,
              position: 'top-right',
            })
          })
      } else {
        this.$vs.notify({
          title: 'Es necesario adjuntar el documento relacionado con la baja.',
          color: 'danger',
          fixed: true,
          position: 'top-right',
        })
        this.isLoading = false
        this.$vs.loading.close()
      }
    },
    newCatalog(type) {
      if (type == 'reasonForWithdrawal') {
        this.title = 'Nueva razón de renuncia'
      }
      this.nameNewCatalog = ''
      this.newCatalogPopUp = true
      this.typeNewCatalog = type
    },
    saveNewCatalog() {
      if (this.typeNewCatalog == 'reasonForWithdrawal') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'withdrawal-reason',
          data: {
            reason: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getReasonForWithdrawalCatalog().then((response) => {
              this.$vs.loading.close()
              this.reasonForWithdrawalCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
.mx-datepicker-main {
  z-index: 10002;
}
.new-candidate-container {
  margin: 0 auto;
}
.block {
  width: 100% !important;
}
.candidate-cards-container {
  overflow-y: auto;
}
.vs-input-number .vs-input-number--input {
  width: auto !important;
}
.vs-tabs--content {
  min-height: 700px;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grid-view-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  border-radius: 5%;
  object-fit: contain;
}
.preview-images {
  max-height: 125px;
  width: 300px;
  background-size: contain;
}
</style>
