<template>
  <vs-row vs-w="12" class="list-candidates-container">
    <vs-col vs-type="flex" vs-justify="center" vs-w="12">
      <vs-list class="candidates-list pt-3 overflow-auto">
        <vcl-bullet-list v-if="skeletonLoading" class="mt-4" />
        <virtual-list
          v-else
          class="item-candidate-container"
          :data-key="'id'"
          :data-sources="candidatesList"
          :data-component="candidateItem"
        />

        <vs-alert
          class="mt-3"
          title="Ops.."
          :active="candidatesList.length === 0 && skeletonLoading === false"
          color="warning"
        >
          Sin resultados
        </vs-alert>
      </vs-list>
    </vs-col>
  </vs-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { VclBulletList } from 'vue-content-loading'
import CandidateItem from './CandidateItem'
import VirtualList from 'vue-virtual-scroll-list'

export default {
  name: 'CandidatesList',
  components: {
    'vcl-bullet-list': VclBulletList,
    'virtual-list': VirtualList,
  },
  data: function () {
    return {
      candidateItem: CandidateItem,
    }
  },
  computed: {
    ...mapState('candidates', {
      selectedCandidate: 'selectedCandidate',
      candidatesList: 'candidatesList',
      skeletonLoading: 'skeletonLoading',
    }),
  },
  methods: {
    ...mapMutations('candidates', {
      setSelectedCandidate: 'setSelectedCandidate',
    }),
  },
}
</script>
