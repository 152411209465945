<template>
  <vs-card class="pdf-card-container mb-0 pb-1">
    <div class="pdf-container">
      <template v-if="src !== null">
        <embed :src="src" type="application/pdf" width="100%" height="99%" />
      </template>
      <template v-else>
        <div class="centerx">
          <vs-upload
            automatic
            accept="application/pdf"
            :file-name="type"
            type-submit="PATCH"
            :with-credentials="false"
            :limit="1"
            :headers="headers"
            :action="urlAction"
            :text="text"
            @on-success="successUpload"
          />
        </div>
      </template>
    </div>
  </vs-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'AppPdf',
  components: {},
  props: {
    src: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      urlAction: '',
      text: 'Cargar documento',
    }
  },
  computed: {
    ...mapState('candidates', {
      selectedCandidate: 'selectedCandidate',
    }),
    ...mapState('auth', { tokenAccess: 'tokenAccess' }),
    headers: function () {
      let headers = {
        Authorization: 'Bearer ' + this.tokenAccess,
      }
      return headers
    },
  },
  created: function () {
    const documentId = this.selectedCandidate.candidateDocument.id
    this.urlAction = `${axios.defaults.baseURL}docs/${documentId}/`
  },
  methods: {
    ...mapMutations({ setLoading: 'setLoading' }),
    ...mapMutations('candidates', {
      setSelectedCandidateDocuments: 'setSelectedCandidateDocuments',
    }),
    successUpload(event) {
      // Update the selectedCandidate

      let responseTarget = event.currentTarget.response
      let response = JSON.parse(responseTarget)
      this.setSelectedCandidateDocuments(response)
      this.$vs.notify({
        color: 'Éxito',
        title: 'Se ha almacenado el documento',
        position: 'bottom-center',
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
.pdf-card-container {
  overflow: auto;
  .vs-card--content {
    height: calc(100vh - 230px);
    min-width: 600px;
  }
  .pdf-container {
    height: calc(100vh - 230px);
    .pdf-viewer {
      width: 100%;
    }
  }
  &::-webkit-scrollbar {
    width: 15px;
    height: 13px;
  }
}
</style>
