<template>
  <vs-row
    class="full-height"
    vs-align="center"
    vs-type="flex"
    vs-justify="center"
    vs-w="12"
  >
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="3"
      vs-sm="4"
      vs-xs="0"
    >
      <div class="logo-container">
        <figure>
          <img src="@/assets/images/logo.png" class="img-logo" alt="LOGO" />
        </figure>
      </div>
    </vs-col>
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="3"
      vs-sm="8"
      vs-xs="12"
    >
      <LoginForm />
    </vs-col>
  </vs-row>
</template>

<script>
import LoginForm from '@/modules/auth/views/login/LoginForm'

export default {
  components: {
    LoginForm,
  },
}
</script>

<style scoped lang="scss">
.logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1em;
  figure {
    width: 90%;
    .img-logo {
      object-fit: fill;
      width: 100%;
      margin: -5px;
      align-self: center;
      justify-self: center;
    }
  }
}
</style>
