import { employeeObject } from '@/models/employee'
const _ = require('lodash')

export default {
  setUpdateEmployee: (state, employee) => {
    let updateEmployee = _.cloneDeep(employee)
    // Starts to change the object to the final updateEmployeeObject
    updateEmployee.name = employee.candidateInfo.name
    updateEmployee.lastName = employee.candidateInfo.lastName
    updateEmployee.secondLastName = employee.candidateInfo.secondLastName

    updateEmployee.documentsAdd = []
    updateEmployee.documents.forEach((doc) => {
      updateEmployee.documentsAdd.push(doc.id)
    })

    updateEmployee.contacts_add = employee.contacts.map((item) => {
      return {
        //idTemp: item.employee,
        phoneNumber: item.phoneNumber,
        name: item.name,
        relationship: item.relationship,
      }
    })

    delete updateEmployee.contacts

    state.updateEmployee = _.cloneDeep(updateEmployee)
  },
  setSelectedEmployee: (state, selectedEmployee) => {
    state.selectedEmployee = selectedEmployee
  },
  clearNewEmployeeData: (state) => {
    state.newEmployee = _.cloneDeep(employeeObject)
    state.newEmployeeFormsProgress = {
      employeeInformation: {
        employeeData: false,
        job: false,
        assignment: false,
        contact: false,
      },
    }
  },
  clearUpdateEmployeeData: (state) => {
    state.updateEmployee = _.cloneDeep(employeeObject)
  },
  setNewEmployeeProgress: (state, { section, card, status }) => {
    state.newEmployeeFormsProgress[section][card] = status
  },
  setNewEmployeeErrors: (state, errors) => {
    state.newEmployeeErrors = _.cloneDeep(errors)
  },
  setupdateEmployeeErrors: (state, errors) => {
    state.updateEmployeeErrors = _.cloneDeep(errors)
  },
  setnewEmployeeRatings: (state, newEmployee) => {
    state.newEmployee = _.merge(state.newEmployee, {
      candidateRatingAdd: newEmployee.candidateRatingAdd,
    })
  },
  setupdateEmployeeRatings: (state, updateEmployee) => {
    state.updateEmployee = _.merge(state.updateEmployee, {
      employeeFollowUp: updateEmployee.employeeFollowUp,
    })
  },
  setupdateEmployeeFollowUp: (state, updateEmployee) => {
    state.updateEmployee = _.merge(state.updateEmployee, {
      employeeFollowUpAdd: updateEmployee.employeeRatingAdd,
    })
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setSkeletonLoading: (state, isLoading) => {
    state.skeletonLoading = isLoading
  },
  setTotalEmployeePages: (state, totalEmployeePages) => {
    state.totalEmployeePages = totalEmployeePages
  },
  setEmployeesList: (state, employeesList) => {
    state.employeesList = employeesList
  },
  setPopupVacation: (state, popupVacation) => {
    state.popupVacation = popupVacation
  },
  setEmployeeVac: (state, employeeVac) => {
    state.employeeVac = employeeVac
  },
  setEmployeeVacList: (state, employeeVacList) => {
    state.employeeVacList = employeeVacList
  },
}
