<template>
  <div id="restore-password-container" class="container">
    <vs-row
      class="full-height"
      vs-align="center"
      vs-type="flex"
      vs-justify="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="3"
        vs-sm="4"
        vs-xs="0"
      >
        <div class="logo-container">
          <figure>
            <img src="@/assets/images/logo.png" class="img-logo" alt="LOGO" />
          </figure>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="8"
        vs-xs="12"
      >
        <EmailRestorePasswordForm v-if="showEmailRestorePasswordForm" />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import EmailRestorePasswordForm from '@/modules/auth/views/recover/EmailRestorePasswordForm'

export default {
  components: {
    EmailRestorePasswordForm,
  },
  data: function () {
    return {
      showEmailRestorePasswordForm: false,
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters['auth/isLogged']
    },
  },
  created() {
    if (this.isLogged) {
      this.$router
        .push({
          name: 'productList',
        })
        .catch(() => {})
    } else {
      this.showEmailRestorePasswordForm = true
    }
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';

#restore-password-container {
  .logo-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1em;
    figure {
      width: 90%;
      .img-logo {
        object-fit: fill;
        width: 100%;
        margin: -5px;
        align-self: center;
        justify-self: center;
      }
    }
  }
}
</style>
