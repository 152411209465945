<template>
  <div class="estudios-info-tabs">
    <div class="candidate-cards-container px-3">
      <!-- Escolaridad-->
      <vs-card class="pt-3 px-1 mb-5">
        <div slot="header">
          <h2>Estudios</h2>
        </div>
        <ValidationObserver ref="observerEstudios" v-slot="{ invalid }">
          <div>
            <vs-row
              v-for="(scholarship, index) in updateCandidate.scholarshipAdd"
              :key="scholarship.id"
              class="update-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-w="12"
              >
                <!-- Escuela -->
                <h3>Escolaridad {{ index + 1 }}</h3>
              </vs-col>
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Institución educativa"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="scholarship.schoolAdd"
                      autocomplete
                      class="block col-inputs-full"
                      label="Institución"
                      placeholder="Elija una institución educativa"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, indexSchool) in schoolsCatalog"
                        :key="indexSchool"
                        :value="item.id"
                        :text="item.name"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('schools')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <!-- Carrera -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Carrera"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="scholarship.careerAdd"
                      autocomplete
                      class="block col-inputs-full"
                      label="Carrera"
                      placeholder="Elija una carrera"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, indexSchoolCareer) in schoolCareerCatalog"
                        :key="indexSchoolCareer"
                        :value="item.id"
                        :text="item.name"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('schoolCareer')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <!-- Estatus escolar -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Estatus escolar"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="scholarship.schoolStatus"
                      autocomplete
                      class="block col-inputs-full"
                      label="Estatus Escolar"
                      placeholder="Elija un estatus escolar"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, indexSchool) in schoolStatusCatalog"
                        :key="indexSchool"
                        :value="item.id"
                        :text="item.status"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('schoolStatus')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <!-- Periodo de inicio -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Fecha de inicio"
                  rules="required"
                >
                  <label class="vs-select--label">Fecha de inicio</label>
                  <date-picker
                    v-model="scholarship.startDate"
                    class="date-size"
                    type="text"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :editable="false"
                    append-to-body
                    input-class="vs-inputx vs-input--input normal hasValue"
                    placeholder="Ingrese una fecha (Obligatorio)"
                    confirm-text="Listo"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                    @dayclick="scholarshipStartDateDPClicked"
                    @click="activeScholarship = scholarship"
                  >
                  </date-picker>
                </ValidationProvider>
              </vs-col>
              <!-- Periodo de fin -->
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Fecha de fin"
                  rules="required"
                >
                  <label class="vs-select--label">Fecha de fin</label>
                  <date-picker
                    v-model="scholarship.endDate"
                    class="date-size"
                    type="text"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :editable="false"
                    append-to-body
                    input-class="vs-inputx vs-input--input normal hasValue"
                    placeholder="Ingrese una fecha (Obligatorio)"
                    confirm-text="Listo"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                    @dayclick="scholarshipEndDateDPClicked"
                    @click="activeScholarship = scholarship"
                  >
                  </date-picker>
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-md="12"
                vs-lg="6"
              >
                <vs-button
                  class="mr-2 btn-lg"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addScholarship"
                >
                  Agregar escolaridad
                </vs-button>
                <vs-button
                  :disabled="updateCandidate.scholarshipAdd.length === 1"
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeScholarship"
                >
                  Eliminar escolaridad
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-md="12"
                vs-lg="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="onClickSaveAndContinue('studies', 'scholarship')"
                >
                  Guardar escolaridad
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Idiomas -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Idiomas
          </h2>
        </div>
        <ValidationObserver ref="observerIdiomas" v-slot="{ invalid }">
          <div>
            <vs-row
              v-for="(language, index) in updateCandidate.languageAdd"
              :key="language.id"
              class="update-candidate-container mb-4"
            >
              <h3>Idioma {{ index + 1 }}</h3>
              <vs-col
                class="block col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Idioma"
                  rules="required"
                >
                  <vs-col
                    class="col-inputs-full mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="10"
                  >
                    <vs-select
                      v-model="language.language"
                      autocomplete
                      class="selectExample block"
                      label="Idioma"
                      placeholder="Elija un idioma"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    >
                      <vs-select-item
                        v-for="(item, indexLanguage) in languagesCatalog"
                        :key="indexLanguage"
                        :value="item.id"
                        :text="item.name"
                      />
                    </vs-select>
                  </vs-col>
                  <vs-col
                    class="col-inputs-full mt-3 pt-1"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2"
                  >
                    <vs-icon
                      icon="add"
                      color="success"
                      class="cursor-pointer mt-3"
                      @click="newCatalog('languages')"
                    ></vs-icon>
                  </vs-col>
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="3"
              >
                <label class="vs-select--label">Idioma hablado</label>
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="9"
              >
                <vs-slider v-model="language.spokenLanguage" step="5" />
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="3"
              >
                <label class="vs-select--label">Idioma Leido</label>
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="9"
              >
                <vs-slider v-model="language.languageRead" step="5" />
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="3"
              >
                <label class="vs-select--label">Idioma Escrito</label>
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="9"
              >
                <vs-slider v-model="language.writtenLanguage" step="5" />
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="mr-2"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addLanguage"
                >
                  Agregar idioma
                </vs-button>
                <vs-button
                  :disabled="updateCandidate.languageAdd.length === 1"
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeLanguage"
                >
                  Eliminar idioma
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="onClickSaveAndContinue('studies', 'languages')"
                >
                  Guardar idiomas
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Certificaciones -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Certificaciones
          </h2>
        </div>
        <ValidationObserver ref="observerCertificaciones" v-slot="{ invalid }">
          <div>
            <vs-row
              v-for="certification in updateCandidate.certificationAdd"
              :key="certification.id"
              class="update-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="9"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Idioma"
                  rules="required"
                >
                  <vs-input
                    v-model="certification.certification"
                    label="Certificación"
                    label-placeholder="Título de la Certificación"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Año idioma"
                  :rules="{
                    required: true,
                    min_value: new Date().getFullYear() - 20,
                    max_value: new Date().getFullYear(),
                  }"
                >
                  <vs-input
                    v-model="certification.year"
                    label="Año"
                    label-placeholder="Año de la Certificación"
                    type="number"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="mr-2"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addCertification"
                >
                  Agregar certificación
                </vs-button>
                <vs-button
                  :disabled="updateCandidate.certificationAdd.length === 0"
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeCertification"
                >
                  Eliminar certificación
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="onClickSaveAndContinue('studies', 'certifications')"
                >
                  Guardar certificación
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Cursos -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Cursos
          </h2>
        </div>
        <ValidationObserver ref="observerCursos" v-slot="{ invalid }">
          <div>
            <vs-row
              v-for="course in updateCandidate.courseAdd"
              :key="course.id"
              class="update-candidate-container mb-4"
            >
              <vs-col
                class="mt-2 px-1 col-inputs-full"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="9"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Curso"
                  rules="required"
                >
                  <vs-input
                    v-model="course.course"
                    label="Curso"
                    label-placeholder="Título del curso"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
              <vs-col
                class="mt-2 px-1"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Año del curso"
                  :rules="{
                    required: true,
                    min_value: new Date().getFullYear() - 20,
                    max_value: new Date().getFullYear(),
                  }"
                >
                  <vs-input
                    v-model="course.year"
                    label="Año"
                    label-placeholder="Año del curso"
                    type="number"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </div>
          <div slot="footer">
            <vs-row class="footer-card" vs-justify="space-between">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="mr-2"
                  type="gradient"
                  color="success"
                  icon="add"
                  @click="addCourse"
                >
                  Agregar curso
                </vs-button>
                <vs-button
                  :disabled="updateCandidate.courseAdd.length === 0"
                  class="mr-2"
                  type="gradient"
                  color="danger"
                  icon="add"
                  @click="removeCourse"
                >
                  Eliminar curso
                </vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  color="primary"
                  icon="turned_in_not"
                  :disabled="invalid"
                  @click="onClickSaveAndContinue('studies', 'courses')"
                >
                  Guardar curso
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </vs-card>
      <!-- Comentarios -->
      <vs-card class="pt-3 px-1 mb-5 mt-4">
        <div slot="header">
          <h2>
            Comentarios
          </h2>
        </div>
        <div>
          <vs-row class="update-candidate-container mb-4">
            <vs-col
              class="mt-2 px-1"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-textarea
                v-model="updateCandidate.studiesComments"
                label="Comentarios u Observaciones de esta sección"
              />
            </vs-col>
          </vs-row>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button
              color="primary"
              icon="turned_in_not"
              @click="onClickSaveAndContinue('studies', 'comments')"
            >
              Guardar comentarios de la sección
            </vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div>
    <ValidationObserver ref="observerNewCatalog" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="newCatalogPopUp"
        :title="title"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        @accept="saveNewCatalog"
      >
        <div>
          <vs-row>
            <vs-col class="mx-1 mt-1" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
                tag="div"
                class="mt-2"
              >
                <vs-input
                  v-model="nameNewCatalog"
                  label-placeholder="Nombre"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import utils from '@/utils/utils'
import { mapActions, mapMutations, mapState } from 'vuex'
// TODO: Validar montos maximos y minimos
export default {
  name: 'EstudiosForm',
  props: {
    updateCandidate: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      schoolsCatalog: [],
      schoolCareerCatalog: [],
      estatusLaboralCatalog: [],
      schoolStatusCatalog: [],
      languagesCatalog: [],
      activeScholarship: null,
      newCatalogPopUp: false,
      title: '',
      typeNewCatalog: '',
      nameNewCatalog: '',
    }
  },
  computed: {
    ...mapState('company', { company: 'company' }),
  },

  created() {
    this.getSchoolsCatalog('is_active=True').then((response) => {
      this.schoolsCatalog = response
    })
    this.getSchoolCareerCatalog('is_active=True').then((response) => {
      this.schoolCareerCatalog = response
    })
    this.getSchoolStatusCatalog('is_active=True').then((response) => {
      this.schoolStatusCatalog = response
    })
    this.getLanguagesCatalog('is_active=True').then((response) => {
      this.languagesCatalog = response
    })
  },
  methods: {
    ...mapActions('catalogs', {
      getSchoolsCatalog: 'getSchoolsCatalog',
      getSchoolCareerCatalog: 'getSchoolCareerCatalog',
      getSchoolStatusCatalog: 'getSchoolStatusCatalog',
      getLanguagesCatalog: 'getLanguagesCatalog',
      newItemCatalog: 'newItemCatalog',
    }),
    ...mapMutations('candidates', {
      setUpdateCandidateStudiesScholarship:
        'setUpdateCandidateStudiesScholarship',
      setUpdateCandidateStudiesLanguage: 'setUpdateCandidateStudiesLanguage',
      setUpdateCandidateStudiesCertification:
        'setUpdateCandidateStudiesCertification',
      setUpdateCandidateStudiesCourse: 'setUpdateCandidateStudiesCourse',
      setUpdateCandidateStudiesComments: 'setUpdateCandidateStudiesComments',
    }),
    onClickSaveAndContinue(section, card) {
      switch (card) {
        case 'scholarship':
          this.setUpdateCandidateStudiesScholarship(this.updateCandidate)
          break
        case 'languages':
          this.setUpdateCandidateStudiesLanguage(this.updateCandidate)
          break
        case 'certifications':
          this.setUpdateCandidateStudiesCertification(this.updateCandidate)
          break
        case 'courses':
          this.setUpdateCandidateStudiesCourse(this.updateCandidate)
          break
        case 'comments':
          this.setUpdateCandidateStudiesComments(this.updateCandidate)
          break
      }
      this.$vs.notify({
        title: 'Tarjeta actualizada',
        color: 'success',
        position: 'bottom-center',
      })
    },
    scholarshipStartDateDPClicked(day) {
      this.updateCandidate.scholarshipAdd.forEach((scholarship) => {
        if (scholarship.id === this.activeScholarship.id) {
          scholarship.startDate = day.id
        }
      })
    },
    scholarshipEndDateDPClicked(day) {
      this.updateCandidate.scholarshipAdd.forEach((scholarship) => {
        if (scholarship.id === this.activeScholarship.id) {
          scholarship.endDate = day.id
        }
      })
    },
    addScholarship() {
      this.updateCandidate.scholarshipAdd.push({
        id: utils.randomId(),
        schoolAdd: '',
        careerAdd: '',
        schoolStatus: '',
        startDate: '',
        endDate: '',
      })
    },
    removeScholarship() {
      this.updateCandidate.scholarshipAdd.pop()
    },
    addLanguage() {
      this.updateCandidate.languageAdd.push({
        id: utils.randomId(),
        language: '',
        spokenLanguage: 50,
        writtenLanguage: 50,
        languageRead: 50,
      })
    },
    removeLanguage() {
      this.updateCandidate.languageAdd.pop()
    },
    addCertification() {
      this.updateCandidate.certificationAdd.push({
        id: utils.randomId(),
        certification: '',
        year: '',
      })
    },
    removeCertification() {
      this.updateCandidate.certificationAdd.pop()
    },
    addCourse() {
      this.updateCandidate.courseAdd.push({
        id: utils.randomId(),
        course: '',
        year: '',
      })
    },
    removeCourse() {
      this.updateCandidate.courseAdd.pop()
    },
    newCatalog(type) {
      if (type == 'schools') {
        this.title = 'Nueva escuela'
      }
      if (type == 'schoolCareer') {
        this.title = 'Nueva carrera'
      }
      if (type == 'schoolStatus') {
        this.title = 'Nuevo estatus escolar'
      }
      if (type == 'languages') {
        this.title = 'Nuevo idioma'
      }
      this.nameNewCatalog = ''
      this.newCatalogPopUp = true
      this.typeNewCatalog = type
    },
    saveNewCatalog() {
      if (this.typeNewCatalog == 'schools') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'schools',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getSchoolsCatalog().then((response) => {
              this.$vs.loading.close()
              this.schoolsCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'schoolCareer') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'school-career',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getSchoolCareerCatalog().then((response) => {
              this.$vs.loading.close()
              this.schoolCareerCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'schoolStatus') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'school-status',
          data: {
            status: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getSchoolStatusCatalog().then((response) => {
              this.$vs.loading.close()
              this.schoolStatusCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'languages') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'languages',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getLanguagesCatalog().then((response) => {
              this.$vs.loading.close()
              this.languagesCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
    },
  },
}
</script>

<style scoped>
.date-size {
  width: 100% !important;
}
</style>
