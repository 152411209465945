import AssitanceView from '@/modules/assistance/views/AssistanceView'
import ListAssitanceView from '@/modules/assistance/views/ListAssitenceView'
import ListVacationsView from '@/modules/assistance/views/ListVacationsView'
import ListAssitenceEmployeeView from '@/modules/assistance/views/ListAssitenceEmployeeView'

export default [
  {
    path: '/asistencia',
    name: 'assitance',
    meta: {
      title: 'Mi asistencia',
      rule: 'employee',
    },
    component: AssitanceView,
  },
  {
    path: '/asistencia-lista',
    name: 'Listassitance',
    meta: {
      title: 'Asistencia Empleados',
      rule: 'recruiter',
    },
    component: ListAssitanceView,
  },
  {
    path: '/mis-asistencias',
    name: 'Employeeassitance',
    meta: {
      title: 'Mis asistencias',
      rule: 'employee',
    },
    component: ListAssitenceEmployeeView,
  },
  {
    path: '/vacaciones-lista',
    name: 'ListVacations',
    meta: {
      title: 'Vacaciones Empleados',
      rule: 'recruiter',
    },
    component: ListVacationsView,
  },
]
