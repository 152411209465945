<template>
  <vs-list>
    <vs-list-header title="Lista de catálogos"></vs-list-header>
    <!-- Area -->
    <vs-list-item title="Áreas de trabajo">
      <template slot="avatar">
        <vs-avatar icon="group" />
      </template>
      <vs-button class="mr-1" color="success" @click="newArea">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openAreaList">Editar</vs-button>
    </vs-list-item>
    <!-- Carrera -->
    <vs-list-item title="Carrera">
      <template slot="avatar">
        <vs-avatar icon="square_foot" />
      </template>
      <vs-button class="mr-1" color="success" @click="newCareer">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openCareerList">Editar</vs-button>
    </vs-list-item>
    <!-- Empleos (Vacantes)-->
    <vs-list-item title="Empleos (Vacantes)">
      <template slot="avatar">
        <vs-avatar icon="work_outline" />
      </template>
      <vs-button class="mr-1" color="success" @click="newJob">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openJobList">Editar</vs-button>
    </vs-list-item>
    <!-- Escuelas -->
    <vs-list-item title="Escuelas">
      <template slot="avatar">
        <vs-avatar icon="school" />
      </template>
      <vs-button class="mr-1" color="success" @click="newSchool">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openSchoolList">Editar</vs-button>
    </vs-list-item>
    <!-- Estatus escolar -->
    <vs-list-item title="Estatus escolar">
      <template slot="avatar">
        <vs-avatar icon="edit" />
      </template>
      <vs-button class="mr-1" color="success" @click="newSchoolStatus">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openSchoolStatusList">
        Editar
      </vs-button>
    </vs-list-item>
    <!-- Estatus laboral -->
    <vs-list-item title="Estatus laboral">
      <template slot="avatar">
        <vs-avatar icon="work" />
      </template>
      <vs-button class="mr-1" color="success" @click="newEmploymentStatus">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openEmploymentStatusList">
        Editar
      </vs-button>
    </vs-list-item>
    <!-- Idiomas -->
    <vs-list-item title="Idiomas">
      <template slot="avatar">
        <vs-avatar icon="translate" />
      </template>
      <vs-button class="mr-1" color="success" @click="newLanguage">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openLanguageList">Editar</vs-button>
    </vs-list-item>
    <!-- Medios de reclutamiento -->
    <vs-list-item title="Medios de reclutamiento">
      <template slot="avatar">
        <vs-avatar icon="find_in_page" />
      </template>
      <vs-button class="mr-1" color="success" @click="newRecruitingMedium">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openRecruitingMediumList">
        Editar
      </vs-button>
    </vs-list-item>
    <!-- Nacionalidades -->
    <vs-list-item title="Nacionalidades">
      <template slot="avatar">
        <vs-avatar icon="flag" />
      </template>
      <vs-button class="mr-1" color="success" @click="newNationality">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openNationalityList">Editar</vs-button>
    </vs-list-item>
    <!-- Parentescos familiares -->
    <vs-list-item title="Parentescos familiares">
      <template slot="avatar">
        <vs-avatar icon="people" />
      </template>
      <vs-button class="mr-1" color="success" @click="newRelationship">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openRelationshipList"
        >Editar</vs-button
      >
    </vs-list-item>
    <!-- Prestaciones -->
    <vs-list-item title="Prestaciones">
      <template slot="avatar">
        <vs-avatar icon="attach_money" />
      </template>
      <vs-button class="mr-1" color="success" @click="newBenefit">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openBenefitList">Editar</vs-button>
    </vs-list-item>
    <!-- Puestos de trabajo -->
    <vs-list-item title="Puestos de trabajo">
      <template slot="avatar">
        <vs-avatar icon="build" />
      </template>
      <vs-button class="mr-1" color="success" @click="newPayrollPosition">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openPayrollPositionList"
        >Editar</vs-button
      >
    </vs-list-item>
    <!-- Razones de baja laboral -->
    <vs-list-item title="Razones de baja laboral">
      <template slot="avatar">
        <vs-avatar icon="trending_down" />
      </template>
      <vs-button class="mr-1" color="success" @click="newReasonForWithdrawal">
        Agregar</vs-button
      >
      <vs-button color="primary" @click="openReasonForWithdrawalList">
        Editar
      </vs-button>
    </vs-list-item>
    <!-- Vacantes de la empresa -->
    <vs-list-item title="Vacantes de la empresa">
      <template slot="avatar">
        <vs-avatar icon="work_outline" />
      </template>
      <vs-button class="mr-1" color="success" @click="newJobVacancy">
        Agregar
      </vs-button>
      <vs-button color="primary" @click="openJobVacancyList">Editar</vs-button>
    </vs-list-item>
  </vs-list>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { eventBus } from '@/plugins/event-bus'

export default {
  computed: {
    ...mapState('catalogs', {
      cardNewItemActive: 'cardNewItemActive',
      cardListActive: 'cardListActive',
      titleCard: 'titleCard',
      typeSubmit: 'typeSubmit',
    }),
  },
  /**
   * Destruye los listeners.
   */
  beforeDestroy() {
    eventBus.$off('openCareerList', this.listener)
    eventBus.$off('openEmploymentStatusList', this.listener)
    eventBus.$off('openLanguageList', this.listener)
    eventBus.$off('openSchoolList', this.listener)
    eventBus.$off('openSchoolStatusList', this.listener)
    eventBus.$off('openRecruitingMediumList', this.listener)
    eventBus.$off('openNationalityList', this.listener)
    eventBus.$off('openBenefitList', this.listener)
    eventBus.$off('openNewJobList', this.listener)
    eventBus.$off('openNewJobVacancyList', this.listener)
    eventBus.$off('openNewReasonForWithdrawalList', this.listener)
    eventBus.$off('openAreaList', this.listener)
    eventBus.$off('openPayrollPositionList', this.listener)
    eventBus.$off('openRelationshipList', this.listener)
  },
  methods: {
    ...mapMutations('catalogs', {
      updateCardListActive: 'updateCardListActive',
      updateCardNewItemActive: 'updateCardNewItemActive',
      updateTitleCard: 'updateTitleCard',
      updateTypeSubmit: 'updateTypeSubmit',
    }),
    /**
     * Modal de nueva carrera.
     *
     */
    newCareer() {
      this.updateTitleCard('Nueva carrera')
      this.updateTypeSubmit('schoolCareer')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal de Nuevo Estatus laboral.
     *
     */
    newEmploymentStatus() {
      this.updateTitleCard('Nuevo estatus laboral')
      this.updateTypeSubmit('employmentStatus')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nuevo idioma.
     *
     */
    newLanguage() {
      this.updateTitleCard('Nuevo idioma')
      this.updateTypeSubmit('language')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nueva escuela.
     *
     */
    newSchool() {
      this.updateTitleCard('Nueva escuela')
      this.updateTypeSubmit('school')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nuevo estatus escolar.
     *
     */
    newSchoolStatus() {
      this.updateTitleCard('Nuevo estatus escolar')
      this.updateTypeSubmit('schoolStatus')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nuevo medio de reclutamiento.
     *
     */
    newRecruitingMedium() {
      this.updateTitleCard('Nuevo medio de reclutamiento')
      this.updateTypeSubmit('recruitmentMedium')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nueva nacionalidad.
     *
     */
    newNationality() {
      this.updateTitleCard('Nueva nacionalidad')
      this.updateTypeSubmit('nationality')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nueva prestación.
     *
     */
    newBenefit() {
      this.updateTitleCard('Nueva prestación')
      this.updateTypeSubmit('benefit')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nuevo empleo.
     *
     */
    newJob() {
      this.updateTitleCard('Nuevo empleo')
      this.updateTypeSubmit('job')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nueva vacante de la empresa.
     *
     */
    newJobVacancy() {
      this.updateTitleCard('Nueva vacante de la empresa')
      this.updateTypeSubmit('jobVacancy')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nueva razón de baja laboral.
     *
     */
    newReasonForWithdrawal() {
      this.updateTitleCard('Nueva razón de baja laboral')
      this.updateTypeSubmit('withdrawal')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nueva area.
     *
     */
    newArea() {
      this.updateTitleCard('Nueva área de trabajo')
      this.updateTypeSubmit('area')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nuevo puesto.
     *
     */
    newPayrollPosition() {
      this.updateTitleCard('Nuevo puesto de trabajo')
      this.updateTypeSubmit('payrollPosition')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Modal para nuevo parentesco.
     *
     */
    newRelationship() {
      this.updateTitleCard('Nueva parentesco')
      this.updateTypeSubmit('relationship')
      this.updateCardNewItemActive(true)
      this.updateCardListActive(false)
    },
    /**
     * Despliega la lista de carreras.
     *
     */
    openCareerList() {
      this.updateTypeSubmit('schoolCareer')
      eventBus.$emit('openCareerList')
    },
    /**
     * Despliega la lista de estatus laboral.
     *
     */
    openEmploymentStatusList() {
      this.updateTypeSubmit('employmentStatus')
      eventBus.$emit('openEmploymentStatusList')
    },
    /**
     * Despliega la lista de idiomas.
     *
     */
    openLanguageList() {
      this.updateTypeSubmit('language')
      eventBus.$emit('openLanguageList')
    },
    /**
     * Despliega la lista de escuelas.
     *
     */
    openSchoolList() {
      this.updateTypeSubmit('school')
      eventBus.$emit('openSchoolList')
    },
    /**
     * Despliega la lista de estatus escoelar.
     *
     */
    openSchoolStatusList() {
      this.updateTypeSubmit('schoolStatus')
      eventBus.$emit('openSchoolStatusList')
    },
    /**
     * Despliega la lista de medios de reclutamiento.
     *
     */
    openRecruitingMediumList() {
      this.updateTypeSubmit('recruitmentMedium')
      eventBus.$emit('openRecruitingMediumList')
    },
    /**
     * Despliega la lista de nacionalidades.
     *
     */
    openNationalityList() {
      this.updateTypeSubmit('nationality')
      eventBus.$emit('openNationalityList')
    },
    /**
     * Despliega la lista de prestaciones.
     *
     */
    openBenefitList() {
      this.updateTypeSubmit('benefit')
      eventBus.$emit('openBenefitList')
    },
    /**
     * Despliega la lista de empleos.
     *
     */
    openJobList() {
      this.updateTypeSubmit('job')
      eventBus.$emit('openJobList')
    },
    /**
     * Despliega la lista de vacantes laborales.
     *
     */
    openJobVacancyList() {
      this.updateTypeSubmit('jobVacancy')
      eventBus.$emit('openJobVacancyList')
    },
    /**
     * Despliega la lista de razones de baja laboral.
     *
     */
    openReasonForWithdrawalList() {
      this.updateTypeSubmit('withdrawal')
      eventBus.$emit('openReasonForWithdrawalList')
    },
    /**
     * Despliega la lista de areas de trabajo.
     *
     */
    openAreaList() {
      this.updateTypeSubmit('area')
      eventBus.$emit('openAreaList')
    },
    /**
     * Despliega la lista de puestos de trabajo.
     *
     */
    openPayrollPositionList() {
      this.updateTypeSubmit('payrollPosition')
      eventBus.$emit('openPayrollPositionList')
    },
    /**
     * Despliega la lista de parentescos.
     *
     */
    openRelationshipList() {
      this.updateTypeSubmit('relationship')
      eventBus.$emit('openRelationshipList')
    },
  },
}
</script>
