<template>
  <vs-row
    class="new-candidate-container container-fluid mb-3 pb-5"
    type="flex"
    vs-justify="center"
  >
    <vs-tabs alignment="center">
      <!--Información general -->
      <vs-tab label="Información General">
        <vs-col vs-justify="center" class="px-1" vs-lg="3" vs-sm="4" vs-xs="12">
          <div class="candidate-info-tabs mt-4">
            <div class="candidate-cards-container">
              <vs-card class="px-3">
                <vs-row>
                  <vs-col>
                    <h2>
                      <p class="mt-1 mb-7 text-bold" vs-align="center">
                        Foto Empleado
                      </p>
                    </h2>
                  </vs-col>
                  <vs-col
                    v-if="
                      image !== undefined && image !== '#' && image !== null
                    "
                    class="mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <small class="mx-auto"> Imagen Actual </small>
                    <vs-icon
                      icon="edit"
                      color="blue"
                      class="cursor-pointer"
                      @click="
                        () => {
                          showImage = !showImage
                        }
                      "
                    ></vs-icon
                  ></vs-col>
                  <vs-col vs-type="flex" class="preview-images mb-2">
                    <template
                      v-if="
                        image !== undefined && image !== '#' && image !== null
                      "
                    >
                      <img :src="cropedImage" class="grid-view-img mx-auto" />
                    </template>
                  </vs-col>
                  <template
                    v-if="
                      showImage ||
                      image === undefined ||
                      image === '#' ||
                      image === null
                    "
                  >
                    <picture-input
                      :key="keyImage"
                      ref="uploadMainPhoto"
                      class="mt-3"
                      size="7"
                      :crop="false"
                      :min-width="300"
                      :min-height="300"
                      @change="onChangeImgOne()"
                    >
                    </picture-input>
                  </template>
                </vs-row>
              </vs-card>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="9"
          vs-sm="8"
          vs-xs="12"
        >
          <div class="candidate-info-tabs container-fluid mt-4">
            <div class="candidate-cards-container px-3">
              <!-- Datos personales-->
              <ValidationObserver
                ref="observerDatosCandidato"
                v-slot="{ invalid }"
              >
                <vs-card :ref="employeeData" class="pt-3 px-1">
                  <div slot="header">
                    <h2>Datos del empleado</h2>
                  </div>
                  <div>
                    <vs-row class="new-candidate-container mb-4">
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="4"
                      >
                        <vs-input
                          v-model="newEmployee.name"
                          label-placeholder="Nombre(s)"
                          @blur="refresh()"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="4"
                      >
                        <vs-input
                          v-model="newEmployee.lastName"
                          label-placeholder="Apellido Paterno"
                          @blur="refresh()"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="4"
                      >
                        <vs-input
                          v-model="newEmployee.secondLastName"
                          label-placeholder="Apellido Materno"
                          @blur="refresh()"
                        />
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="CURP"
                          rules="required|max:18|min:18|curp"
                        >
                          <vs-input
                            v-model="newEmployee.curp"
                            label-placeholder="CURP"
                            maxlength="18"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="RFC"
                          rules="required|max:13|min:13|rfc"
                        >
                          <vs-input
                            v-model="newEmployee.rfc"
                            label-placeholder="RFC"
                            maxlength="13"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="NSS"
                          rules="required|max:11|min:11"
                        >
                          <vs-input
                            v-model="newEmployee.nss"
                            label-placeholder="NSS"
                            maxlength="11"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('employeeData')"
                      >
                        Guardar datos empleado
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-xs="12"
        >
          <div class="candidate-info-tabs mt-4">
            <div class="candidate-cards-container px-3">
              <!-- Prestaciones -->
              <ValidationObserver
                ref="observerPrestaciones"
                v-slot="{ invalid }"
              >
                <vs-card
                  v-if="newEmployeeFormsProgress.employeeData"
                  class="pt-3 px-1"
                >
                  <div slot="header">
                    <h2>Datos empleo</h2>
                  </div>
                  <div>
                    <vs-row>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          name="Fecha ingreso"
                          rules="required"
                        >
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <date-picker
                                id="ingreso"
                                v-model="newEmployee.startDate"
                                class="full-width"
                                placeholder="Fecha de Entrada"
                                value-type="format"
                                format="YYYY-MM-DD"
                                :open.sync="openDatepicker"
                              >
                                <template v-slot:footer>
                                  <button
                                    class="mx-btn mx-btn-text mx-2"
                                    @click="openDatepicker = false"
                                  >
                                    Listo
                                  </button>
                                </template>
                              </date-picker>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Fecha ingreso
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="nómina"
                          rules="required|numeric"
                        >
                          <vs-input
                            v-model="newEmployee.payrollNumber"
                            label-placeholder="Número de Nómina"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Puesto"
                          rules="required"
                        >
                          <vs-col
                            class="col-inputs-full mt-2"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="10"
                          >
                            <vs-select
                              v-model="newEmployee.payrollPosition"
                              autocomplete
                              class="block col-inputs-full"
                              label="Puesto"
                              placeholder="Elija puesto"
                              val-icon-danger="error"
                              :danger="!!errors[0]"
                              :danger-text="errors[0]"
                            >
                              <vs-select-item
                                v-for="(item, index) in payrollPosition"
                                :key="index"
                                :value="item.id"
                                :text="item.name"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col
                            class="col-inputs-full mt-3 pt-1"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                          >
                            <vs-icon
                              icon="add"
                              color="success"
                              class="cursor-pointer mt-3"
                              @click="newCatalog('payrollPosition')"
                            ></vs-icon>
                          </vs-col>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Correo interno"
                          rules="required|email"
                        >
                          <vs-input
                            v-model="newEmployee.internalEmail"
                            label-placeholder="Correo interno"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Sueldo diario nómina"
                          rules="required|decimal|min_value:0"
                        >
                          <vs-input
                            v-model="newEmployee.nominalDailySalary"
                            label-placeholder="Sueldo Diario Nómina"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Sueldo mixto neto mensual"
                          rules="required|decimal|min_value:0"
                        >
                          <vs-input
                            v-model="newEmployee.monthlyNetMixedSalary"
                            label-placeholder="Sueldo Mixto Neto Mensual"
                            val-icon-danger="error"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('job')"
                      >
                        Guardar empleo
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>

              <ValidationObserver
                ref="observerPrestaciones"
                v-slot="{ invalid }"
              >
                <vs-card v-if="newEmployeeFormsProgress.job" class="pt-3 px-1">
                  <div slot="header">
                    <h2>Asignación</h2>
                  </div>
                  <div>
                    <vs-row>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="area"
                          rules="required"
                        >
                          <vs-col
                            class="col-inputs-full mt-2"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="10"
                          >
                            <vs-select
                              v-model="newEmployee.area"
                              autocomplete
                              class="block col-inputs-full"
                              label="Área"
                              placeholder="Elija área en la que labora"
                              val-icon-danger="error"
                              :danger="!!errors[0]"
                              :danger-text="errors[0]"
                            >
                              <vs-select-item
                                v-for="(item, index) in areaCatalog"
                                :key="index"
                                :value="item.id"
                                :text="item.name"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col
                            class="col-inputs-full mt-3 pt-1"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                          >
                            <vs-icon
                              icon="add"
                              color="success"
                              class="cursor-pointer mt-3"
                              @click="newCatalog('area')"
                            ></vs-icon>
                          </vs-col>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-3 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider
                          name="Hora Entrada"
                          rules="required"
                        >
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <datetime
                                v-model="newEmployee.entryTime"
                                type="time"
                                use12-hour
                                :phrases="{
                                  ok: 'Confirmar',
                                  cancel: 'Cancelar',
                                }"
                                input-class="vs-inputx vs-input--input normal"
                                auto
                              ></datetime>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Hora Entrada
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-3 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="6"
                      >
                        <ValidationProvider name="Hora Salida" rules="required">
                          <div
                            class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                          >
                            <div
                              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                            >
                              <datetime
                                v-model="newEmployee.exitTime"
                                type="time"
                                use12-hour
                                :phrases="{
                                  ok: 'Confirmar',
                                  cancel: 'Cancelar',
                                }"
                                input-class="vs-inputx vs-input--input normal"
                                auto
                              ></datetime>
                              <span
                                class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                                style="margin-top: -28px;"
                              >
                                Hora Salida
                              </span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('assigment')"
                      >
                        Guardar asignación
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>

              <!-- Contacto -->
              <ValidationObserver ref="observerContacto" v-slot="{ invalid }">
                <vs-card
                  v-if="newEmployeeFormsProgress.assignment"
                  :ref="contactosData"
                  class="pt-3 px-1"
                >
                  <div slot="header">
                    <h2>Contactos</h2>
                  </div>
                  <div>
                    <h3 class="mt-3">Teléfonos de contacto</h3>
                    <vs-row
                      v-for="contact in newEmployee.contactsAdd"
                      :key="contact.idTemp"
                      class="new-candidate-container mb-4"
                    >
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Nombre"
                          rules="required"
                        >
                          <vs-input
                            v-model="contact.name"
                            label="Nombre"
                            label-placeholder="Nombre"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Teléfono de contacto"
                          rules="required|numeric|min:10|max:10"
                        >
                          <vs-input
                            v-model="contact.phoneNumber"
                            label="Teléfono"
                            maxlength="10"
                            label-placeholder="Teléfono de contacto"
                            :danger="!!errors[0]"
                            :danger-text="errors[0]"
                          />
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-2 px-1 col-inputs-full"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="parentesco"
                          rules="required"
                        >
                          <vs-col
                            class="col-inputs-full mt-2"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="10"
                          >
                            <vs-select
                              v-model="contact.relationship"
                              autocomplete
                              class="block col-inputs-full"
                              label="Parentesco"
                              placeholder="Elija parentesco"
                              val-icon-danger="error"
                              :danger="!!errors[0]"
                              :danger-text="errors[0]"
                            >
                              <vs-select-item
                                v-for="(item, index) in relationship"
                                :key="index"
                                :value="item.id"
                                :text="item.name"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col
                            class="col-inputs-full mt-3 pt-1"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                          >
                            <vs-icon
                              icon="add"
                              color="success"
                              class="cursor-pointer mt-3"
                              @click="newCatalog('relationship')"
                            ></vs-icon>
                          </vs-col>
                        </ValidationProvider>
                      </vs-col>
                      <vs-col
                        class="mt-4 px-1"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="2"
                      >
                        <vs-button
                          radius
                          color="success"
                          type="flat"
                          icon="add"
                          @click.prevent="addContact()"
                        />
                        <vs-button
                          v-if="newEmployee.contactsAdd.length > 1"
                          radius
                          color="danger"
                          type="flat"
                          icon="remove"
                          @click.prevent="removeContact(contact.idTemp)"
                        />
                      </vs-col>
                    </vs-row>
                  </div>
                  <div slot="footer">
                    <vs-row vs-justify="flex-end">
                      <vs-button
                        color="primary"
                        icon="turned_in_not"
                        :disabled="invalid"
                        @click="onClickSaveAndContinue('contact')"
                      >
                        Guardar contactos
                      </vs-button>
                    </vs-row>
                  </div>
                </vs-card>
              </ValidationObserver>
            </div>
          </div>
        </vs-col>
      </vs-tab>
      <vs-tab label="Documentos">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-xs="12"
        >
          <vs-card class="pt-3">
            <div slot="header">
              <h2>Documentos</h2>
            </div>
            <vs-row class="ml-1">
              <vs-col vs-type="flex" vs-align="center" vs-w="12">
                <vs-row>
                  <vs-col
                    v-for="(doc, key) in documents"
                    :key="key"
                    vs-w="3"
                    class="px-2"
                  >
                    <vs-col>
                      <p class="mt-1 mb-7 text-bold" vs-align="center">
                        {{ doc.name }}
                      </p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="center">
                      <vs-upload
                        :ref="doc.type"
                        accept="application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :limit="1"
                        :show-upload-button="false"
                        text="Adjuntar Documento"
                        @change="uploadDoc(doc, false)"
                      />
                    </vs-col>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
        <vs-col>
          <vs-card class="pt-3">
            <div slot="header">
              <vs-row>
                <h2>Cursos y Capacitaciones</h2>
                <vs-button
                  radius
                  color="success"
                  type="flat"
                  icon="add"
                  @click.prevent="addCourse()"
                />
              </vs-row>
            </div>
            <vs-row :ref="idCourse" class="ml-1">
              <vs-col vs-type="flex" vs-align="center" vs-w="12">
                <vs-row>
                  <vs-col
                    v-for="(course, key) in courses"
                    :key="key"
                    vs-w="3"
                    class="px-2"
                  >
                    <vs-col>
                      <vs-row>
                        <p class="mt-1 mb-7 text-bold" vs-align="center">
                          Curso o Capacitacion
                        </p>
                      </vs-row>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="center">
                      <vs-upload
                        :ref="'Course' + course.idTemp"
                        accept="application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :limit="1"
                        :show-upload-button="false"
                        text="Adjuntar Documento"
                        @change="uploadDoc(course, true)"
                      />
                    </vs-col>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row> </vs-card
        ></vs-col>
      </vs-tab>
    </vs-tabs>
    <vue-fab
      size="big"
      icon="save"
      main-btn-color="#388e3c"
      :scroll-auto-hide="false"
      @clickMainBtn="onClickNewEmployee"
    >
    </vue-fab>
    <vs-prompt
      color="primary"
      :active.sync="dialog"
      title="Recortar Imagen"
      accept-text="Aceptar"
      cancel-text="Cerrar"
      @accept="saveImageOne"
      @close="cancelImage"
      @cancel="cancelImage"
    >
      <div class="modal-venta">
        <vueCropper
          v-show="selectedFile"
          ref="cropperPhoto"
          :src="selectedFile"
          :crop-box-resizable="true"
          :aspect-ratio="1"
          alt="Source Image"
        ></vueCropper>
      </div>
    </vs-prompt>
    <ValidationObserver ref="observerNewCatalog" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="newCatalogPopUp"
        :title="title"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        @accept="saveNewCatalog"
      >
        <div>
          <vs-row>
            <vs-col class="mx-1 mt-1" vs-w="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
                tag="div"
                class="mt-2"
              >
                <vs-input
                  v-model="nameNewCatalog"
                  label-placeholder="Nombre"
                  val-icon-danger="error"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </ValidationProvider>
            </vs-col>
          </vs-row>
        </div>
      </vs-prompt>
    </ValidationObserver>
    <vs-prompt
      color="primary"
      :active.sync="dialogConvertDocxPDF"
      title="Convertir archivo DOCX a PDF"
      accept-text="Convertir"
      cancel-text="Cerrar"
      @accept="convertImage"
    >
      <div class="modal-venta"></div>
    </vs-prompt>
  </vs-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
const { DateTime } = require('luxon')
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import PictureInput from '@/components/PictureInput'
// import Compressor from 'compressorjs'
import VueCropper from 'vue-cropperjs'
import utils from '@/utils/utils'
import { extend } from 'vee-validate'
import 'cropperjs/dist/cropper.css'
const _ = require('lodash')
import { employeeDocuments } from '@/models/employeeDocuments'

extend('decimal', {
  validate(value) {
    let regexp = /^\d+(\.\d{1,2})?$/
    return regexp.test(value)
  },
  message: 'Debe ser un valor decimal',
})
extend('curp', {
  validate(value) {
    let regexp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
    return regexp.test(value)
  },
  message: 'Debe ser un curp valido',
})
extend('rfc', {
  validate(value) {
    let regexp = /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    return regexp.test(value)
  },
  message: 'Debe ser un rfc valido',
})
export default {
  name: 'NewEmployeeView',
  components: {
    'date-picker': DatePicker,
    'picture-input': PictureInput,
    vueCropper: VueCropper,
  },
  data: function () {
    return {
      contactosData: 0,
      employeeData: 0,
      candidateData: {},
      idCandidate: 0,
      isLoading: false,
      openDatepicker: false,
      payrollPosition: [],
      areaCatalog: [],
      relationship: [],
      perfilImgData: {
        id: null,
        photo: null,
        photoCompress: null,
      },
      newEmployeeFormsProgress: {
        employeeData: false,
        job: false,
        assignment: false,
        contact: false,
      },
      contactAddId: 0,
      newEmployee: {
        contactsAdd: [],
      },
      dialog: false,
      dialogConvertDocxPDF: false,
      selectedFile: '',
      image: null,
      idImage: null,
      documents: [],
      courses: [],
      idCourse: 0,
      cropedImage: null,
      showImage: false,
      keyImage: 0,
      newCatalogPopUp: false,
      title: '',
      typeNewCatalog: '',
      nameNewCatalog: '',
    }
  },
  computed: {
    ...mapState('company', { company: 'company' }),
  },
  watch: {
    isLoading: function () {
      if (this.isLoading) {
        this.$vs.loading({
          type: 'radius',
        })
      } else {
        this.$vs.loading.close()
      }
    },
  },
  created() {
    this.getAreaCatalog('is_active=True').then((response) => {
      this.areaCatalog = response
    })
    this.getPayrollPositionCatalog('is_active=True').then((response) => {
      this.payrollPosition = response
    })
    this.getRelationshipCatalog('is_active=True').then((response) => {
      this.relationship = response
    })
  },
  mounted() {
    if (
      document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0] != undefined
    ) {
      let element = document.getElementsByClassName(
        'vs-tooltip vs-tooltip-top vs-tooltip-null'
      )[0]
      element.parentNode.removeChild(element)
    }
    this.initializeDoc()
    this.idCandidate = this.$route.params.id
    this.getCandidateById(this.$route.params.id)
      .then((res) => {
        this.candidateData = res
        this.newEmployee.name = res.name
        this.newEmployee.lastName = res.lastName
        this.newEmployee.secondLastName = res.secondLastName
        this.employeeData = this.employeeData + 1
        this.candidateSuccess = true
        this.addContact()
      })
      .catch(() => {
        this.$vs.notify({
          title: 'Opps...',
          text: 'Candidato no encontrado',
        })
        this.$router.push({ name: 'candidatesList' })
      })
  },
  methods: {
    ...mapActions('catalogs', {
      getAreaCatalog: 'getAreaCatalog',
      getPayrollPositionCatalog: 'getPayrollPositionCatalog',
      getRelationshipCatalog: 'getRelationshipCatalog',
      newItemCatalog: 'newItemCatalog',
    }),
    ...mapActions('candidates', {
      getCandidateById: 'getCandidateById',
    }),
    ...mapActions('employee', {
      newEmployeeAction: 'newEmployeeAction',
      createEmployeePhotosAction: 'createEmployeePhotosAction',
      saveDocumentAction: 'saveDocumentAction',
    }),
    ...mapMutations('employee', {
      clearNewEmployeeData: 'clearNewEmployeeData',
      setNewCandidateErrors: 'setNewCandidateErrors',
    }),
    initializeDoc() {
      this.documents = _.cloneDeep(employeeDocuments)
    },
    refresh() {
      this.employeeData = this.employeeData + 1
    },
    /**
     * Despliega el modal para la conversión de los archivos DOCX a PDF
     */
    onClickOpenModalConvertDocxPDF() {
      this.dialogConvertDocxPDF = true
    },
    /**
     * Método que permite convertir un archivo docx a pdf.
     */
    convertImage() {},
    uploadDoc(doc, isCourse) {
      let docRef = ''
      if (isCourse) {
        docRef = 'Course' + doc.idTemp
      } else {
        docRef = doc.type
      }
      let formData = new FormData()
      let arrayLength = this.$refs[docRef][0].filesx.length - 1
      let file = this.$refs[docRef][0].filesx[arrayLength]
      formData.append('document', file, file.name)
      formData.append('documentType', doc.type)
      this.$vs.loading({
        text: 'Almacenando archivo ...',
      })
      this.saveDocumentAction(formData).then((res) => {
        this.$vs.loading.close()
        if (isCourse) {
          this.courses.forEach((item) => {
            if (item.id === doc.id) {
              item.id = res.id
              item.document = res.document
            }
          })
        } else {
          this.documents.forEach((document) => {
            if (document.type === doc.type) {
              document.id = res.id
              document.document = res.document
            }
          })
        }
      })
    },
    addCourse() {
      this.courses.push({
        type: 'CURSOS',
        document: null,
        idTemp: this.idCourse,
      })
      this.idCourse = this.idCourse + 1
    },
    goToCandidatesPage() {
      this.clearNewEmployeeData()
      this.$router.push({
        name: 'candidatesList',
      })
    },
    onClickNewEmployee() {
      this.isLoading = true
      this.newEmployee.endDate = null
      this.newEmployee.isActive = true
      this.newEmployee.photo = this.idImage
      this.newEmployee.candidate = this.idCandidate
      this.newEmployee.entryTime = DateTime.fromISO(
        this.newEmployee.entryTime
      ).toFormat('T')
      this.newEmployee.exitTime = DateTime.fromISO(
        this.newEmployee.exitTime
      ).toFormat('T')
      let contactsValid = true
      this.newEmployee.contactsAdd.forEach((contact) => {
        if (contact.name === '' || contact.name.length <= 2) {
          contactsValid = false
        }
        if (contact.phoneNumber === '' || contact.phoneNumber.length <= 9) {
          contactsValid = false
        }
        if (contact.relationship === '' || contact.relationship.length <= 0) {
          contactsValid = false
        }
      })
      if (!contactsValid) {
        this.$vs.notify({
          title:
            'Revise los campos señalados, guárdelos y reintente nuevamente.',
          text: `Revise los datos de contacto`,
          color: 'danger',
          fixed: true,
          position: 'top-right',
        })
        this.isLoading = false
        return
      }
      let documentsIds = []
      this.documents.forEach((doc) => {
        if (doc.id != null) {
          documentsIds.push(doc.id)
        }
      })
      this.courses.forEach((item) => {
        if (item.id != null) {
          documentsIds.push(item.id)
        }
      })
      this.newEmployee.documentsAdd = documentsIds
      this.$vs.loading({
        text: 'Creando empleado...',
      })
      this.newEmployeeAction(this.newEmployee)
        .then(() => {
          this.isLoading = false
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Empleado Creado Exitosamente',
          })
          this.goToCandidatesPage()
        })
        .catch((error) => {
          this.isLoading = false
          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title:
              'Revise los campos señalados, guárdelos y reintente nuevamente.',
            text: `${res}`,
            color: 'danger',
            fixed: true,
            position: 'top-right',
          })
        })
    },
    onClickSaveAndContinue(card) {
      switch (card) {
        case 'employeeData': {
          this.newEmployeeFormsProgress.employeeData = true
          break
        }
        case 'job': {
          this.newEmployeeFormsProgress.job = true
          break
        }
        case 'assigment': {
          this.newEmployeeFormsProgress.assignment = true
          break
        }
        case 'contact': {
          this.newEmployeeFormsProgress.contact = true
          break
        }
      }
    },
    addContact() {
      this.newEmployee.contactsAdd.push({
        idTemp: this.contactAddId,
        name: '',
        relationship: '',
        phone: '',
      })
      this.contactAddId = this.contactAddId + 1
      this.contactosData = this.contactosData + 1
    },
    removeContact(key) {
      this.newEmployee.contactsAdd = this.newEmployee.contactsAdd.filter(
        (x) => {
          return x.idTemp !== key
        }
      )
      this.contactosData = this.contactosData + 1
    },
    onChangeImgOne() {
      if (this.$refs.uploadMainPhoto.image) {
        const file = this.$refs.uploadMainPhoto.file
        this.mime_type = file.type
        this.dialog = true
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.selectedFile = event.target.result
            this.$refs.cropperPhoto.replace(this.selectedFile)
            const data = {
              width: 400,
              height: 400,
            }
            this.$refs.cropperPhoto.setCropBoxData(data)
          }
          reader.readAsDataURL(file)
        } else {
          alert('Sorry, FileReader API not supported')
        }
      }
    },
    cancelImage() {
      this.keyImage++
    },
    saveImageOne() {
      let formData = new FormData()
      let self = this
      this.cropedImage = this.$refs.cropperPhoto.getCroppedCanvas().toDataURL()
      this.$refs.cropperPhoto.getCroppedCanvas().toBlob(
        (blob) => {
          let file = new File([blob], 'photo.webp')
          formData.append('photo', file, 'photo.webp')
          self.image = new File([blob], 'temp.webp')
          self
            .createEmployeePhotosAction(formData)
            .then((res) => {
              self.idImage = res.id
              self.showImage = false
            })
            .catch((err) => {
              self.$vs.notify({
                title: 'Error al cargar la imagen',
                text: 'Asegurese de adjuntar una imagen válida.',
                position: 'top-right',
                color: 'danger',
              })
              throw new Error(err.message)
            })
        },
        'image/webp',
        0.8
      )
    },
    newCatalog(type) {
      if (type == 'payrollPosition') {
        this.title = 'Nuevo puesto'
      }
      if (type == 'area') {
        this.title = 'Nueva area'
      }
      if (type == 'relationship') {
        this.title = 'Nuevo parentesco'
      }
      this.nameNewCatalog = ''
      this.newCatalogPopUp = true
      this.typeNewCatalog = type
    },
    saveNewCatalog() {
      if (this.typeNewCatalog == 'payrollPosition') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'payroll-position',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getPayrollPositionCatalog().then((response) => {
              this.$vs.loading.close()
              this.payrollPosition = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'area') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'area',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getAreaCatalog().then((response) => {
              this.$vs.loading.close()
              this.areaCatalog = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
      if (this.typeNewCatalog == 'relationship') {
        this.$vs.loading({
          type: 'radius',
        })
        this.newItemCatalog({
          type: 'relationship',
          data: {
            name: this.nameNewCatalog,
            company: this.company.id,
          },
        })
          .then(() => {
            this.getRelationshipCatalog().then((response) => {
              this.$vs.loading.close()
              this.relationship = response
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Oops... Ocurrió un error:',
              text: err.response.data['errors'][0]['message'],
              color: 'warning',
              position: 'top-right',
              icon: 'warning',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
.mx-datepicker-main {
  z-index: 10002;
}
.new-candidate-container {
  margin: 0 auto;
}
.block {
  width: 100% !important;
}
.candidate-cards-container {
  overflow-y: auto;
}
.vs-input-number .vs-input-number--input {
  width: auto !important;
}
.vs-tabs--content {
  min-height: 700px;
}
.grid-view-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  border-radius: 5%;
  object-fit: contain;
}
.preview-images {
  max-height: 150px;
  width: 300px;
  background-size: contain;
}
</style>
