<template>
  <div class="pt-3 pb-3 px-3 full-height">
    <vs-row vs-justify="center" class="mt-1">
      <vs-col vs-justify="center" vs-align="center" vs-xs="12" vs-lg="3">
        <FilterSideBar />
      </vs-col>
      <vs-col
        class="pr-4 px-5"
        vs-justify="center"
        vs-align="center"
        vs-xs="12"
        vs-lg="9"
      >
        <vs-card class="pt-1 px-1 ml-3 mr-4">
          <h2>Asistencia</h2>
          <vs-table
            :max-items="25"
            :data="assistance"
            no-data-text="No hay resultados"
          >
            <template slot="thead">
              <vs-th sort-key="employee"> Empleado </vs-th>
              <vs-th sort-key="workingDate">Fecha</vs-th>
              <vs-th sort-key="entryTime"> Entrada </vs-th>
              <vs-th sort-key="exiTime"> Salida </vs-th>
              <vs-th sort-key="justification"> Justificación </vs-th>
              <vs-th sort-key="justification"> Archivo justificación </vs-th>
              <vs-th sort-key=""></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
                <vs-td :data="tr.employee">
                  {{ tr.employeeInfo.candidateInfo.name }}
                  {{ tr.employeeInfo.candidateInfo.lastName }}
                  {{ tr.employeeInfo.candidateInfo.secondLastName }}
                </vs-td>
                <vs-td :data="tr.workingDate">
                  {{ tr.workingDate }}
                </vs-td>
                <vs-td :data="tr.entryTime">
                  <vs-row>
                    <span style="vertical-align: middle;" class="my-auto">{{
                      tr.entryTime
                    }}</span>
                    <img
                      v-if="tr.entryPicture"
                      class="employeeImg mx-auto"
                      :src="tr.entryPicture"
                    />
                  </vs-row>
                </vs-td>
                <vs-td :data="tr.exitTime">
                  <vs-row>
                    <span style="vertical-align: middle;" class="my-auto">{{
                      tr.exitTime
                    }}</span>
                    <img
                      v-if="tr.exitPicture"
                      class="employeeImg mx-auto"
                      :src="tr.exitPicture"
                    />
                  </vs-row>
                </vs-td>
                <vs-td :data="tr.justification">
                  {{ tr.justification }}
                </vs-td>
                <vs-td :data="tr.file">
                  <a
                    v-if="!!tr.file"
                    class="link"
                    target="_blank"
                    :href="tr.file"
                    >Ver archivo</a
                  >
                </vs-td>
                <vs-td>
                  <vs-tooltip text="Editar Asistencia">
                    <vs-icon
                      icon="edit"
                      color="primary"
                      class="cursor-pointer mt-3"
                      @click="openEditAssistance(tr)"
                    ></vs-icon>
                  </vs-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-col vs-type="flex" vs-justify="center" vs-w="12">
                <vs-pagination
                  v-if="assistance.length > 0"
                  v-model="currentPage"
                  class="pagination-container mt-2 py-1 mb-5"
                  :total="totalPages"
                  :max="6"
                />
              </vs-col>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-popup :active.sync="showImage" :title="popupTitle">
      <img
        class="assistancePicture"
        :src="selectedImage"
        :alt="selectedImage"
      />
    </vs-popup>
    <ValidationObserver ref="observerRegistrarAsistencia" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="editAssistanceModal"
        accept-text="Aceptar"
        cancel-text="Cancelar"
        :is-valid="!invalid"
        title="Editar Asistencia"
        @accept="editAssistanceMethod"
      >
        <vs-row>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Fecha baja"
              rules="required"
            >
              <div
                class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                :danger="!!errors[0]"
                :danger-text="errors[0]"
              >
                <div
                  class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
                >
                  <date-picker
                    v-model="editAssistance.workingDate"
                    class="full-width"
                    placeholder="Fecha"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :open.sync="openDatepickerWorkingDate"
                    :disabled-date="_BeforeToday"
                  >
                    <template v-slot:footer>
                      <button
                        class="mx-btn mx-btn-text mx-2"
                        @click="openDatepickerWorkingDate = false"
                      >
                        Listo
                      </button>
                    </template>
                  </date-picker>
                  <span
                    class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                    style="margin-top: -28px;"
                  >
                    Fecha
                  </span>
                </div>
              </div>
            </ValidationProvider>
          </vs-col>
          <vs-col
            class="mt-0 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
          >
            <div
              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
            >
              <div
                class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
              >
                <datetime
                  v-model="editAssistance.entryTime"
                  type="time"
                  use12-hour
                  :phrases="{
                    ok: 'Confirmar',
                    cancel: 'Cancelar',
                  }"
                  input-class="vs-inputx vs-input--input normal"
                  auto
                ></datetime>
                <span
                  class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                  style="margin-top: -28px;"
                >
                  Hora Entrada
                </span>
              </div>
            </div>
          </vs-col>
          <vs-col
            class="mt-0 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
          >
            <div
              class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
            >
              <div
                class="vs-component vs-con-input-label vs-input vs-input-primary is-label-placeholder"
              >
                <datetime
                  v-model="editAssistance.exitTime"
                  type="time"
                  use12-hour
                  :phrases="{
                    ok: 'Confirmar',
                    cancel: 'Cancelar',
                  }"
                  input-class="vs-inputx vs-input--input normal"
                  auto
                ></datetime>
                <span
                  class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label"
                  style="margin-top: -28px;"
                >
                  Hora Salida
                </span>
              </div>
            </div>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <span class="ml-2" style="color: rgba(0, 0, 0, 0.4);">
              Justificación
            </span>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Justificación"
              rules="required"
            >
              <vs-textarea
                v-model="editAssistance.justification"
                label-placeholder="Justificación"
                val-icon-danger="error"
                maxlength="1000"
                rows="4"
                :class="{
                  'input-icon-validate-danger': errors.length > 0,
                }"
              >
              </vs-textarea>
              <small style="color: red;">
                {{ errors[0] }}
              </small>
            </ValidationProvider>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <span class="ml-2" style="color: rgba(0, 0, 0, 0.4);">
              Comprobante
            </span>
          </vs-col>
          <vs-col
            class="mt-2 px-1 col-inputs-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <div class="large-12 medium-12 small-12 cell cursor-pointer">
              <label for="fileUpload" class="custom-file-upload">
                <span>
                  <vs-icon icon="file_upload"></vs-icon>
                </span>
                <i class="fa fa-cloud-upload"></i> Cargar archivo
              </label>
              <small>
                {{ !!fileProof ? fileProof.name : 'No se eligió archivo' }}
              </small>

              <input
                id="fileUpload"
                ref="fileUpload"
                style="height: 0;"
                type="file"
                @change="handleFileUpload()"
              />
            </div>
          </vs-col>
        </vs-row>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import FilterSideBar from '@/modules/assistance/components/FilterSideBar'
const { DateTime } = require('luxon')
import utils from '@/utils/utils'
export default {
  components: { FilterSideBar },
  data: function () {
    return {
      openDatepickerWorkingDate: false,
      editAssistanceModal: false,
      selectedImage: '',
      showImage: false,
      popupTitle: '',
      assistance: [],
      totalPages: 1,
      currentPage: 1,
      editAssistance: {},
      fileProof: '',
    }
  },
  computed: { ...mapState('assistance', { searchParams: 'searchParams' }) },
  watch: {
    currentPage: function (newPage) {
      let query = `${this.searchParams}&page=${newPage}`
      this.loadData(query)
    },
    searchParams: function () {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        let query = `${this.searchParams}&page=${this.currentPage}`
        this.loadData(query)
      }
    },
  },
  created() {
    let diaS = this.getDay()
    const initialParams = `Idate=${diaS}&Fdate=${this.addOneDay(diaS)}&page=1`
    this.loadData(initialParams)
  },
  methods: {
    ...mapActions('assistance', {
      getAssistanceListAction: 'getAssistanceListAction',
      editAssistanceAction: 'editAssistanceAction',
    }),
    loadData(query) {
      this.getAssistanceListAction(query).then((res) => {
        this.assistance = res.results
        this.totalPages = Math.ceil(res['count'] / res['pageSize'])
      })
    },
    _BeforeToday(date) {
      return utils.BeforeToday(date)
    },
    openEditAssistance(assistance) {
      this.editAssistance = {
        id: assistance.id,
        employee: assistance.employee,
        workingDate: assistance.workingDate,
        entryTime: assistance.entryTime,
        exitTime: assistance.exitTime,
        justification: assistance.justification,
      }
      this.editAssistanceModal = true
    },
    handleFileUpload() {
      this.fileProof = this.$refs.fileUpload.files[0]
    },
    editAssistanceMethod() {
      let exit = DateTime.fromISO(this.editAssistance.exitTime).toFormat('T')
      if (exit === 'Invalid DateTime') {
        exit = null
      }
      let entry = DateTime.fromISO(this.editAssistance.entryTime).toFormat('T')
      if (entry === 'Invalid DateTime') {
        entry = null
      }
      let formData = new FormData()

      formData.append('id', this.editAssistance.id)
      formData.append('employee', this.editAssistance.employee)
      formData.append('workingDate', this.editAssistance.workingDate)
      formData.append('entryTime', entry)
      formData.append('exitTime', exit)
      formData.append('justification', this.editAssistance.justification)
      if (this.fileProof) {
        formData.append('file', this.fileProof)
      }
      this.editAssistanceAction(formData)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito.',
            text: 'Asistencia Actualizada',
          })
          let query = `${this.searchParams}&page=${this.currentPage}`
          this.loadData(query)
        })
        .catch((error) => {
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            title: 'Error al actualizar la asistencia',
            text: res,
            position: 'top-right',
            color: 'danger',
          })
        })
    },
    getDay() {
      return utils.getActualDate()
    },
    addOneDay(dayf) {
      let dia = new Date(dayf)
      dia.setDate(dia.getDate() + 2)
      let diaS = dia.getFullYear() + '-'
      if (dia.getMonth() > 8) {
        diaS = diaS + (dia.getMonth() + 1)
      } else {
        diaS = diaS + '0' + (dia.getMonth() + 1)
      }
      if (dia.getDate() > 9) {
        diaS = diaS + '-' + dia.getDate()
      } else {
        diaS = diaS + '-0' + dia.getDate()
      }
      return diaS
    },
    popupImage(data, option) {
      if (option === 'Enter') {
        this.selectedImage = data.entryPicture
        this.popupTitle =
          'Foto Entrada ' +
          data.employeeInfo.candidateInfo.name +
          ' ' +
          data.employeeInfo.candidateInfo.lastName +
          ' ' +
          data.employeeInfo.candidateInfo.secondLastName +
          ' ' +
          data.workingDate
      } else {
        this.selectedImage = data.exitPicture
        this.popupTitle =
          'Foto Salida ' +
          data.employeeInfo.candidateInfo.name +
          ' ' +
          data.employeeInfo.candidateInfo.lastName +
          ' ' +
          data.employeeInfo.candidateInfo.secondLastName +
          ' ' +
          data.workingDate
      }
      this.showImage = true
    },
  },
}
</script>
<style lang="scss">
.assistancePicture {
  width: 100%;
  max-width: 600px;
  object-fit: contain;
}
.employeeImg {
  width: 100%;
  max-width: 100px;
  object-fit: contain;
  border-radius: 26%;
}
</style>
