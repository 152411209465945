import LoginView from '../views/login/LoginView'
import RegisterView from './../views/register/RegisterView'
import PasswordForm from './../views/register/PasswordForm'
import RecoveryPassword from './../views/recover/RestorePasswordEmailView'
import ResetPassword from './../views/recover/RestorePasswordView'

export default [
  {
    path: '/auth/login',
    name: 'authLogin',
    meta: {
      title: 'Iniciar Sesión',
      rule: 'public',
    },
    component: LoginView,
  },
  {
    path: '/auth/signup',
    name: 'authRegister',
    meta: {
      title: 'Registro',
      rule: 'public',
    },
    component: RegisterView,
  },
  {
    path: '/auth/new-password',
    name: 'authPassword',
    meta: {
      title: 'Registro',
      rule: 'public',
    },
    component: PasswordForm,
  },
  {
    path: '/auth/recover-password',
    name: 'recoverPassword',
    meta: {
      title: 'Registro',
      rule: 'public',
    },
    component: RecoveryPassword,
  },
  {
    path: '/password_reset',
    name: 'passwordReset',
    meta: {
      title: 'Coloque sus nueva contraseña',
      rule: 'public',
    },
    component: ResetPassword,
  },
]
